
export default function CarrerasData(){


    var carrerasArray = [];
  
      carrerasArray[0]={
        name: 'Ingenieria-en-Innovacion-Agricola-Sustentable',
        card:'http://tecmm.edu.mx/imagesReact/images/ofertaAcademicaNueva/Cards-Carreras-Nuevos/Card-Ing-Innovacion-Agricola-Sustentable.jpg',
        portada: "https://tecmm.edu.mx/web-tsj-designs/portadasCarreras/Carreras-Banners-03.jpg",
        youtube:'HXcpQfI72Bo',
        videoInstitucional: "https://www.tecmm.edu.mx/videos/carreras/28_innovacion.mp4",
        campus: "COCULA, TALA, TAMAZULA, ZAPOTLANEJO, MASCOTA",
        campusNoAccent: "ARANDAS, COCULA, EL GRULLO, CUQUIO, MASCOTA, TALA, TAMAZULA, TOMATLAN, TENAMAXTLAN, ZAPOTLANEJO",
        objetivo: "Formar profesionistas analíticos y críticos, comprometidos socialmente y con sólida cultura científico tecnológica, que les permita la planeación del desarrollo regional en el contexto de la sustentabilidad, para realizar investigación, validación, transferencia, adaptación, producción e innovación agrícola.",
        perfilIngreso : [
                          {perfil:"Contar con preparación académica y cultural de nivel medio superior"},
                          {perfil:"Interés por las actividades agrícolas de campo y de agricultura protegida"},
                          {perfil:"Conocimientos en química, biología, física y matemáticas"},
                          {perfil:"Conocimientos básicos de inglés"},
                          {perfil:"Habilidad en el uso de las tecnologías de la información y comunicación (computadora, procesadores de texto básicos, internet, video, etc)"},
                          {perfil:"Respecto a los valores humanos, diversidad cultural e ideológica"},
                          {perfil:"Vocación de servicio para cuidado del medio ambiente y social"},
                          {perfil:"Uso del lenguaje oral y escrita de forma correcta"},
                          {perfil:"Disponibilidad para el trabajo individual y en equipo"},
        ],
        perfilEgreso : [
                        {perfil:"Diseñar, crear, instalar, operar y proporcionar mantenimiento a empresas agrícolas dentro de un marco de desarrollo regional, nacional e internacional."},
                        {perfil:"Desarrollar, coordinar, asesorar y dirigir empresas basadas en sistemas de producción de agricultura protegida, considerando las adecuaciones que implican las condiciones ecológicas y socioeconómicas de las distintas regiones del país."},
                        {perfil:"Aplicar conocimientos técnicos, administrativos, culturales y de extensión para el uso eficiente del agua en la agricultura mediante la implementación y manejo adecuado de los sistemas de riego, con base en las necesidades hídricas de los cultivos, condiciones climáticas, edáficas y sociales."},
                        {perfil:"Promover la organización de los productores y asesorar a éstos en los sistemas de agricultura protegida en cultivos estratégicos; así como garantizar una producción con calidad y proponer innovaciones tecnológicas."},
                        {perfil:"Participar con organizaciones, grupos de trabajo y productores interesados en el establecimiento de proyectos productivos sobre agricultura protegida e impulsar la aplicación de buenas prácticas agrícolas y de manufactura."},
                        {perfil:"Realizar labores profesionales de docencia, investigación y extensión de nuevos conocimientos, metodologías y tecnologías, en los ámbitos de la agricultura protegida, inocuidad alimentaria y bioseguridad, para el mejor aprovechamiento de los recursos del agro."},
                        {perfil:"Innovar los sistemas de producción agrícola a través de la aplicación de modelos de predicción mediante el uso de herramientas informáticas, para la toma de decisiones en las actividades de riego, nutrición, manejo integrado de plagas, enfermedades y riesgo climático."},
                        {perfil:"Solucionar problemas en la producción, manejo y comercialización de productos agrícolas, con el empleo racional e innovador de los recursos naturales y materiales en la producción agrícola."},
                        {perfil:"Participar en la solución de la problemática del sector agrícola y hacer sustentable el uso de los recursos naturales en su producción, transformación y comercialización en el ámbito nacional e internacional."},
                        {perfil:"Generar, validar y adoptar nuevas tecnologías compatibles con la situación socioeconómica del entorno regional para aprovechar el potencial productivo de las áreas agrícolas en función de sus condiciones ecológicas."},
                        {perfil:"Analizar la problemática agrícola en términos tecnológicos, ecológicos, económicos y sociales, para establecer las bases de un manejo sustentable basado en las formas de organización social predominantes en la región."},
                        {perfil:"Manejar y aplicar metodologías y técnicas que permitan obtener información pertinente para planear actividades de un sistema productivo sustentable."},
                        {perfil:"Desarrollar sistemas de producción agrícola sustentable, a través del uso racional y organizado de los recursos regionales disponibles y la innovación tecnológica con la finalidad de mejorar su productividad y lograr un equilibrio ambiental."},
                        {perfil:"Conocer y aplicar la legislación nacional e internacional, en la producción, transformación y comercialización de productos y servicios agrícolas con estándares de calidad."},
        ],
        reticula: 'http://tecmm.edu.mx/reticulasCarreras/Reticula-Ingenieria-en-Innovacion-Agricola-Sustentable.pdf',
        planEstudios: 'http://tecmm.edu.mx/planesEstudioCarreras/INGENIERA_EN_INNOVACIN_AGRCOLA_SUSTENTABLE_IIAS-2010-221.pdf',
        folleto: 'http://tecmm.edu.mx/folletosCarreras/newFolletos/Folleto-Ingenieria-en-Innovacion-Agricola-Sustentable.pdf',
        convocatoria: "",
      };
  
      carrerasArray[1]={
        name: 'Ingenieria-en-Administracion',
        card:'http://tecmm.edu.mx/imagesReact/images/ofertaAcademicaNueva/Cards-Carreras-Nuevos/Card-Ing-Administracion.jpg',
        portada: 'https://tecmm.edu.mx/web-tsj-designs/portadasCarreras/Carreras-Banners-10.jpg',
        youtube:'PQvsRYS3hZ0',
        videoInstitucional: "https://www.tecmm.edu.mx/videos/carreras/23_ing_administracion.mp4",
        campus: 'LA HUERTA, MASCOTA, TALA, TAMAZULA, TEPATITLÁN, ZAPOTLANEJO, IXTLAHUACÁN, CUQUÍO' ,
        objetivo: "Formar profesionales competentes en la creación, dirección e innovación de organizaciones que enfrenten desafíos en un entorno globalizado, con visión emprendedora, ética, humana y de responsabilidad social.",
        perfilIngreso : [
                        {perfil:"Pensamiento complejo, orientado a dar soluciones."},
                        {perfil:"Sinergia, capacidad de realizar trabajo en equipo."},
                        {perfil:"Inteligencia emocional, razonar objetivamente bajo presión."},
                        {perfil:"Liderazgo, él como factor de cohesión."},
                        {perfil:"Responsabilidad social, poseer un marco de referencia ética evidente."},
                        {perfil:"Habilidad en las ciencias económico-administrativas."},
                        {perfil:"Comunicación oral y escrita, evidentes."},
        ],
        perfilEgreso : [
                        {perfil:"Ser un profesional ético, sensible a la diversidad cultural, con la capacidad de gestionar, crear, desarrollar e innovar organizaciones competitivas con una visión de sustentabilidad y responsabilidad social."},
                        {perfil:"Ser un emprendedor que promueva la transformación económica y social a través de la creación de empresas, identificando las oportunidades de negocios en contextos locales, regionales, nacionales e internacionales."},
                        {perfil:"Proponer soluciones creativas que generen ventajas competitivas para resolver problemas de las áreas funcionales en las organizaciones, utilizando procedimientos, técnicas y herramientas de la ingeniería."},
                        {perfil:"Actuar como agente de cambio en las organizaciones, en respuesta a las necesidades del entorno."},
                        {perfil:"Utilizar las tecnologías de la información y comunicación en el ejercicio de su práctica profesional."},
                        {perfil:"Organizar, integrar y dirigir equipos de trabajo para favorecer el crecimiento de la organización."},
                        {perfil:"Aplicar habilidades directivas para el logro de los objetivos organizacionales en diferentes contextos."},
                        {perfil:"Utilizar procesos de comunicación efectivos en las organizaciones y su entorno."},
                        {perfil:"Interpretar y gestionar los sistemas de calidad y el proceso de mejora continua para las organizaciones."},
                        {perfil:"Interpretar y aplicar el marco legal vigente, acorde a las necesidades situacionales de la organización."},
                        {perfil:"Desarrollar y/o dirigir proyectos empresariales utilizando los medios y herramientas de forma multidisciplinaria."},
                        {perfil:"Diseñar e implementar estrategias para optimizar los procesos de las áreas funcionales de la organización."},
                        {perfil:"Tomar decisiones en las operaciones económicas, administrativas y financieras que se generen dentro de la organización, en un marco jurídico/fiscal."},
                        {perfil:"Prestar servicios de consultoría en su ámbito profesional."},
                        {perfil:"Generar, promover y dirigir investigación para propiciar el desarrollo del entorno."},
                        {perfil:"Participar en la administración de sistemas de logística."},
                        {perfil:"Identificar y administrar los riesgos financieros de las organizaciones."},
                        {perfil:"Diseñar, implementar y evaluar los sistemas y modelos administrativos, para la optimización de los recursos que intervienen en la organización, con un enfoque de calidad y competitividad."},
                        {perfil:"Promover y desarrollar el potencial del capital humano en los diferentes niveles de la organización."},
                        {perfil:"Diseñar y gestionar procesos productivos y de servicios que respondan a las necesidades del entorno."},
        ],
        reticula: 'http://tecmm.edu.mx/reticulasCarreras/Reticula-Ingenieria-en-Administracion.pdf',
        planEstudios: 'http://tecmm.edu.mx/planesEstudioCarreras/INGENIERA_EN_ADMINISTRACIN_IADM-2010-213.pdf',
        folleto: 'http://tecmm.edu.mx/folletosCarreras/newFolletos/Folleto-Ingenieria-en-Administracion.pdf',
        convocatoria: "",
      };
  
      carrerasArray[2]={
        name: 'Ingenieria-Ambiental',
        card:'http://tecmm.edu.mx/imagesReact/images/ofertaAcademicaNueva/Cards-Carreras-Nuevos/Card-Ing-Ambiental.jpg',
        portada: 'https://tecmm.edu.mx/web-tsj-designs/portadasCarreras/Carreras-Banners-18.jpg',
        youtube:'qISRetksfrA',
        videoInstitucional: "https://www.tecmm.edu.mx/videos/carreras/19_ambiental.mp4",
        campus: 'ARANDAS, TEPATITLÁN',
        objetivo: "Formar profesionistas en Ingeniería Ambiental éticos, analíticos, críticos y creativos con las competencias para identificar, proponer y resolver problemas ambientales de manera multidisciplinaria, asegurando la protección, conservación y mejoramiento del ambiente, bajo un marco legal, buscando el desarrollo sustentable en beneficio de la vida en el planeta.",
        perfilIngreso : [
                        {perfil:"Capacidad de observación, análisis y síntesis."},
                        {perfil:"Habilidad de comunicación oral y escrita."},
                        {perfil:"Habilidad para trabajar en equipo."},
                        {perfil:"Respeto y aceptación por la diversidad cultural en un entorno de inclusión y equidad."},
                        {perfil:"Conocimiento básico de Biología, Química, Física y Matemáticas."},
                        {perfil:"Habilidad para comprender problemas ambientales y sociales."},
                        {perfil:"Ética y respeto al entorno natural."},
                        {perfil:"Compromiso por mejorar el entorno natural y social."},
                        {perfil:"Disposición para solucionar problemas ambientales mediante la aplicación del método científico."},
                        {perfil:"Administración y optimización de recursos naturales."},
        ],
        perfilEgreso : [
                        {perfil:"Vincular el valor de los recursos naturales para promover su uso sustentable de acuerdo a las necesidades de la región, mediante instrumentos de concientización, sensibilización y comunicación."},
                        {perfil:"Participar en el desarrollo y ejecución del protocolo de investigación básica o aplicada para la resolución de problemas ambientales."},
                        {perfil:"Elaborar, implementar y mantener sistemas de gestión ambiental."},
                        {perfil:"Participar en la realización de auditorías ambientales en el sector público y privado."},
                        {perfil:"Realizar diagnósticos y evaluaciones de impacto y riesgo ambiental sustentados en métodos y procedimientos certificados conforme a los criterios Nacionales e Internacionales."},
                        {perfil:"Elaborar estudios de factibilidad económica y técnica de los procesos para la prevención y control ambiental."},
                        {perfil:"Proponer e innovar tecnologías para el manejo de los residuos cumpliendo la legislación ambiental vigente."},
                        {perfil:"Conocer y aplicar criterios de ingeniería básica y aplicada, así como de las ciencias biológicas para el dimensionamiento, adecuación, operación, mantenimiento y desarrollo de tecnologías de tratamiento, prevención, control y transformación de efluentes sólidos, líquidos y gaseosos contaminados."},
                        {perfil:"Conocer y aplicar las TIC, así como sistemas computacionales o software especializados en el área ambiental."},
                        {perfil:"Ser analítico, ético, crítico, y consciente de la constancia de su entorno para la vida y respetuoso de la misma, siendo promotor del desarrollo sustentable."},
                        {perfil:"Ser capaz de formar recursos humanos, realizar actividades de docencia, investigación y capacitación."},
                        {perfil:"Tener una actitud emprendedora y de liderazgo para interactuar con grupos multidisciplinarios e interdisciplinarios en la búsqueda de soluciones a los problemas del deterioro del medio ambiente."},
        ],
        reticula: 'http://tecmm.edu.mx/reticulasCarreras/Reticula-Ingenieria-Ambiental.pdf',
        planEstudios: 'http://tecmm.edu.mx/planesEstudioCarreras/INGENIERA_AMBIENTAL_IAMB-2010-206.pdf',
        folleto: 'http://tecmm.edu.mx/folletosCarreras/Folleto-Ingenieria-Ambiental.pdf',
        convocatoria: "",
      };
  
      carrerasArray[3]={
        name: 'Ingenieria-en-Animacion-Digital-y-Efectos-Visuales',
        card:'http://tecmm.edu.mx/imagesReact/images/ofertaAcademicaNueva/Cards-Carreras-Nuevos/Card-Ing-Animacion-Efectos-Audiovisuales.jpg',
        portada: 'https://tecmm.edu.mx/web-tsj-designs/portadasCarreras/Carreras-Banners-04.jpg',
        youtube:'4LMrqdyR8fQ',
        videoInstitucional: "https://www.tecmm.edu.mx/videos/carreras/24_animacion.mp4",
        campus: 'CHAPALA',
        objetivo: "Formar profesionistas capaces de adaptar, desarrollar y aplicar recursos tecnológicos en la producción de animación digital y efectos visuales, impulsando la tecnología para responder a los desafíos de las industrias de la animación digital y los efectos visuales, en un contexto global, multidisciplinario y sustentable.",
        perfilIngreso : [
                        {perfil:"Habilidades en el manejo de las Tecnologías de la Información."},
                        {perfil:"Interés por el desarrollo y la innovación tecnología."},
                        {perfil:"Espíritu emprendedor y creativo."},
                        {perfil:"Alto grado de compromiso y motivación orientados a su propio desarrollo."},
                        {perfil:"Creatividad, imaginación y sentido de inventiva."},
                        {perfil:"Interés por la tecnología y las artes visuales."},
        ],
        perfilEgreso : [
                        {perfil:"Crea gráficas computacionales de calidad utilizando software especializado."},
                        {perfil:"Implementa técnicas y procesos de producción de actualidad en las industrias de la animación digital y los efectos visuales."},
                        {perfil:"Interpreta la estructura narrativa de proyectos de animación digital y efectos visuales que sean aplicados en la industria."},
                        {perfil:"Crea efectos visuales utilizando software especializado de producción y post-producción."},
                        {perfil:"Aplica los lenguajes de programación utilizados en el desarrollo de software para las industrias de la animación digital y los efectos visuales."},
                        {perfil:"Desarrolla herramientas de software para facilitar y optimizar los procesos de producción y post-producción de la animación digital y los efectos visuales."},
                        {perfil:"Desarrolla modelos de simulación física en entornos gráficos computacionales aplicables a proyectos de animación digital y efectos visuales."},
                        {perfil:"Desarrolla modelos matemáticos para la representación de gráficas por computadora."},
                        {perfil:"Aplica modelos psicológicos para recrear el comportamiento de personajes en entornos de animación digital."},
                        {perfil:"Integra infraestructura computacional que permita el desarrollo de proyectos de animación digital."},
                        {perfil:"Desarrolla una visión emprendedora y creativa para detectar áreas de oportunidad que le permitan implementar proyectos innovadores y crear nuevas empresas."},
                        {perfil:"Desempeña sus actividades profesionales, considerando los aspectos legales, éticos, humanos y sociales, para sustentar el mejoramiento de su entorno."},
                        {perfil:"Participa activamente en proyectos de investigación y desarrollo tecnológico para fortalecer las industrias de la animación digital y los efectos visuales."},
                        {perfil:"Gestiona proyectos multidisciplinarios para optimizar el uso de recursos."},
        ],
        reticula: 'http://tecmm.edu.mx/reticulasCarreras/Reticula-Ingenieria-en-Animacion-Digital-y-Efectos-Visuales.pdf',
        planEstudios: 'http://tecmm.edu.mx/planesEstudioCarreras/INGENIERIA_EN_ANIMACION_DIGITAL_Y_EFECTOS_VISUALES_IAEV-2012-238.pdf',
        folleto: 'http://tecmm.edu.mx/folletosCarreras/Folleto-Ingenieria-en-Animacion-Digital-y-Efectos-Visuales.pdf',
        convocatoria: "",
      };
  
      carrerasArray[4]={
        name: 'Arquitectura',
        card:'http://tecmm.edu.mx/imagesReact/images/ofertaAcademicaNueva/Cards-Carreras-Nuevos/Card-Arquitectura.jpg',
        portada: 'https://tecmm.edu.mx/web-tsj-designs/portadasCarreras/Carreras-Banners-11.jpg',
        youtube:'8yMYKTH0wps',
        videoInstitucional: "https://www.tecmm.edu.mx/videos/carreras/16_arquitectura.mp4",
        campus: 'ARANDAS, EL GRULLO, LAGOS DE MORENO, PUERTO VALLARTA, TALA, ZAPOPAN',
        objetivo: "Formar en competencias, profesionistas líderes con excelencia académica y humanística, capaces de diseñar, gestionar y construir el hábitat humano con alto desempeño, sustentabilidad y vocación de servicio a la sociedad.",
        perfilIngreso : [
                        {perfil:"Aptitudes hacia la expresión gráfica"},
                        {perfil:"Aptitudes para el trabajo permanente, con disciplina y alegría"},
                        {perfil:"Actitudes coherentes con la inventiva y la creatividad"},
                        {perfil:"Pensamientos sobre historia y valoración del pasado arquitectónico"},
        ],
        perfilEgreso : [
                        {perfil:"Diseñar de manera integral proyectos urbano-arquitectónicos, respetando los marcos normativos y los criterios de diseño universal, estéticos y espaciales."},
                        {perfil:"Diseñar el interiorismo y paisajismo para crear ambientes confortables y funcionales."},
                        {perfil:"Seleccionar y aplicar, materiales y sistemas constructivos que respondan a una continua calidad e innovación."},
                        {perfil:"Gestionar desarrollos urbanos de manera estratégica y sustentable."},
                        {perfil:"Operar planes de desarrollo urbano con una visión de sustentabilidad y mejora de la calidad de vida."},
                        {perfil:"Seleccionar y diseñar estructuras, instalaciones y sistemas constructivos sustentables."},
                        {perfil:"Administrar el proceso constructivo de las obras urbano-arquitectónicas, con base en la legislación vigente."},
                        {perfil:"Asesorar a los sectores público y privado, en la valoración y conservación del patrimonio, re-arquitectura, proyectos de inversión inmobiliaria y legislación urbana."},
                        {perfil:"Liderar organismos y grupos inter y multidisciplinarios para la integración de proyectos urbano - arquitectónicos."},
                        {perfil:"Actuar de manera responsable y ética con la sociedad y su entorno."},
                        {perfil:"Desarrollar los valores de responsabilidad, orden y disciplina así como el entusiasmo por continuar su crecimiento personal y profesional."},
        ],
        reticula: 'http://tecmm.edu.mx/reticulasCarreras/Reticula-Arquitectura.pdf',
        planEstudios: 'http://tecmm.edu.mx/planesEstudioCarreras/ARQUITECTURA_ARQU-2010-204.pdf',
        folleto: 'http://tecmm.edu.mx/folletosCarreras/newFolletos/Folleto-Arquitectura.pdf',
        convocatoria: "",
      };
  
      carrerasArray[5]={
        name: 'Ingenieria-Civil',
        card:'http://tecmm.edu.mx/imagesReact/images/ofertaAcademicaNueva/Cards-Carreras-Nuevos/Card-Ing-Civil.jpg',
        portada: 'https://tecmm.edu.mx/web-tsj-designs/portadasCarreras/Carreras-Banners-19.jpg',
        youtube:'7RJfYbbp34c',
        videoInstitucional: "https://www.tecmm.edu.mx/videos/carreras/20_civil.mp4",
        campus: 'LAGOS DE MORENO, TEQUILA, TEPATITLÁN, ZAPOPAN',
        objetivo: "Formar ingenieros civiles de manera integral, con visión humana, analítica, creativa, y emprendedora, capaces de identificar y resolver problemas con eficiencia, eficacia y pertinencia, mediante la planeación, diseño, construcción, operación y conservación de obras de infraestructura, en el marco de la globalización, la sustentabilidad y la calidad, contribuyendo al desarrollo de la sociedad.",
        perfilIngreso : [
                        {perfil:"Los aspirantes a ingresar al programa de Ingeniería civil deberán haber cursado y aprobado su formación de bachillerato con inclinación a las Ciencias Físico Matemáticas, con el fin de garantizar previamente la asimilación de contenidos programáticos propios de la Ingeniería Civil."},
                        {perfil:"Aplica las matemáticas y física, pensamiento reflexivo y critico; resuelve modelos matemáticos considerando diferentes criterios y enfoques, interpreta los resultados a través de las matemáticas y los discute con los paradigmas establecidos, interpreta mapas, gráficas y diagramas con diferentes nomenclaturas."},
                        {perfil:"Cuenta con responsabilidad, honestidad, paciencia, ética, amistad, trato cordial, relaciones armónicas y comunicación. Respeto a las personas sin distingo de condición social y económica, compromiso de trabajo con orden y disciplina y trabajo colaborativo."},
        ],
        perfilEgreso : [
                        {perfil:"Planear, proyectar, diseñar, construir, operar y conservar obras hidráulicas y sanitarias, sistemas estructurales, vías terrestres, edificación y obras de infraestructura urbana e industrial."},
                        {perfil:"Dirigir y participar en estudios para determinar la factibilidad ambiental, económica, técnica y financiera de los proyectos de obras civiles."},
                        {perfil:"Formular y ejecutar proyectos de investigación y desarrollo tecnológico en el ámbito de la Ingeniería Civil."},
                        {perfil:"Innovar, crear, generar, adaptar y aplicar nuevas tecnologías en los estudios, proyectos y construcción de obras civiles, aplicando métodos científicos."},
                        {perfil:"Optimizar el uso de los recursos en los procesos constructivos de obras civiles."},
                        {perfil:"Emplear técnicas de control de calidad en los materiales y procesos constructivos."},
                        {perfil:"Utilizar Tecnologías de la Información y Comunicación (TIC’s), software y herramientas electrónicas para la Ingeniería Civil."},
                        {perfil:"Emprender proyectos productivos pertinentes."},
        ],
        reticula: 'http://tecmm.edu.mx/reticulasCarreras/Reticula-Ingenieria-Civil.pdf',
        planEstudios: 'http://tecmm.edu.mx/planesEstudioCarreras/INGENIERA_CIVIL_ICIV-2010-208.pdf',
        folleto: 'http://tecmm.edu.mx/folletosCarreras/newFolletos/Folleto-Ingenieria-Civil.pdf',
        convocatoria: "",
      };
  
      carrerasArray[6]={
        name: 'Contador-Publico',
        card:'http://tecmm.edu.mx/imagesReact/images/ofertaAcademicaNueva/Cards-Carreras-Nuevos/Card-Contador-Publico.jpg',
        portada: 'https://tecmm.edu.mx/web-tsj-designs/portadasCarreras/Carreras-Banners-05.jpg',
        youtube:'CziPXTipdvo',
        videoInstitucional: "https://www.tecmm.edu.mx/videos/carreras/17_contador_publico.mp4",
        campus: 'ZAPOTLANEJO',
        objetivo: "Formar profesionales competitivos, capaces de diseñar, establecer, aplicar, controlar y evaluar sistemas de información financiera, fiscal y administrativa, para la toma de decisiones de las entidades económicas nacionales e internacionales, con una actitud ética, crítica, emprendedora y de liderazgo, a través de la investigación y el uso de la tecnología de la información y la comunicación, fomentando el desarrollo sustentable.",
        perfilIngreso : [
                        {perfil:"Compromiso Social."},
                        {perfil:"Actitud Positiva hacia el cambio."},
                        {perfil:"Juicio y Sentido Común."},
                        {perfil:"Capacidad de Análisis y Síntesis."},
                        {perfil:"Interés por el Cálculo."},
                        {perfil:"Capacidad de Observación."},
                        {perfil:"Capacidad para expresarse en forma oral y escrita."},
                        {perfil:"Liderazgo."},
                        {perfil:"Trabajo en Equipo."},
                        {perfil:"Uso de las Tic´s."},
                        {perfil:"Sentido de la Gestión de Recursos."},
                        {perfil:"Facilidad en la Toma de Decisiones."},
        ],
        perfilEgreso : [
                        {perfil:"Diseñar, implantar, controlar, evaluar, asesorar e innovar sistemas de información financiera, administrativa, fiscal y de auditoría en entidades económicas."},
                        {perfil:"Analizar y generar información financiera con apego a las Normas de Información Financiera, nacionales e internacionales, para la toma de decisiones."},
                        {perfil:"Auditar sistemas financieros, fiscales y administrativos de las entidades económicas con apego a las Normas y Procedimientos de Auditoría y Normas para Atestiguar."},
                        {perfil:"Aplicar el marco legal pertinente a las características y necesidades de la entidad económica dentro del campo profesional."},
                        {perfil:"Administrar estratégicamente los recursos de las entidades económicas con visión emprendedora y competitiva."},
                        {perfil:"Conocer y cumplir el código de ética profesional."},
                        {perfil:"Utilizar las tecnologías de Información y comunicación para eficientar los procesos y la toma de decisiones."},
                        {perfil:"Desarrollar investigación asumiendo una actitud de liderazgo, compromiso y servicio con su entorno social."},
                        {perfil:"Elaborar y evaluar proyectos de inversión de acuerdo a las características y necesidades del entorno y propiciar la generación de empresas"},
                        {perfil:"Asumir actitud de compromiso y servicio con su entorno social y el medio ambiente."},
                        {perfil:"Formar y promover el desarrollo de grupos de trabajo interdisciplinarios para el logro de los resultados de las entidades con un sentido de responsabilidad social y visión integradora."},
                        {perfil:"Conocer y proponer estrategias de mercadotecnia que permitan alcanzar los objetivos de la empresa."},
                        {perfil:"Aplicar métodos de análisis de información financiera para determinar las mejores alternativas de inversión y financiamiento."},
        ],
        reticula: 'http://tecmm.edu.mx/reticulasCarreras/Reticula-Contador-Publico.pdf',
        planEstudios: 'http://tecmm.edu.mx/planesEstudioCarreras/CONTADOR_PBLICO_COPU-2010-205.pdf',
        folleto: 'http://tecmm.edu.mx/folletosCarreras/Folleto-Contador-Publico.pdf',
        convocatoria: "",
      };
  
      carrerasArray[7]={
        name: 'Ingenieria-Electromecanica',
        card:'http://tecmm.edu.mx/imagesReact/images/ofertaAcademicaNueva/Cards-Carreras-Nuevos/Card-Ing-Electromecanica.jpg',
        portada: 'https://tecmm.edu.mx/web-tsj-designs/portadasCarreras/Carreras-Banners-12.jpg',
        youtube:'gYBLRrY3Pfg',
        videoInstitucional: "https://www.tecmm.edu.mx/videos/carreras/21_electromecanica.mp4",
        campus: 'ARANDAS, PUERTO VALLARTA, COCULA, TAMAZULA, EL GRULLO, TEQUILA, ZAPOPAN',
        objetivo: "Formar profesionistas de excelencia en ingeniería electromecánica, con actitud emprendedora, con liderazgo y capacidad de: analizar, diagnosticar, diseñar, seleccionar, instalar, administrar, mantener e innovar sistemas electromecánicos, en forma eficiente, segura y económica. Considerando las normas y estándares nacionales e internacionales para fomentar el desarrollo sustentable con plena conciencia ética, humanística y social.",
        perfilIngreso : [
                        {perfil:"El aspirante a esta carrera ha de contar con una buena preparación en las áreas de física, matemáticas, diseño."},
                        {perfil:"Debe tener inclinación para los razonamientos analíticos, capacidad de abstracción."},
                        {perfil:"Creatividad, iniciativa e interés por aplicar la ciencia y la tecnología para satisfacción de las necesidades de la sociedad."},
        ],
        perfilEgreso : [
                        {perfil:"Formular, gestionar y evaluar proyectos de ingeniería relacionados con sistemas y dispositivos en el área electromecánica, proponiendo soluciones con tecnologías de vanguardia, en el marco del desarrollo sustentable."},
                        {perfil:"Diseñar e implementar sistemas y dispositivos electromecánicos, utilizando estrategias para el uso eficiente de la energía en los sectores productivo y de servicios apegado a normas y acuerdos nacionales e internacionales."},
                        {perfil:"Diseñar e implementar estrategias y programas para el control y/o automatización de los procesos productivos y los dispositivos en los sistemas electromecánicos."},
                        {perfil:"Proyectar, gestionar, implementar y controlar actividades de instalación y operación de los sistemas electromecánicos."},
                        {perfil:"Formular administrar y supervisar programas de mantenimiento para la continuidad y optimización de procesos productivos, considerando el cuidado del medio ambiente."},
                        {perfil:"Colaborar en proyectos de investigación para el desarrollo tecnológico, en el área de electromecánica."},
                        {perfil:"Ejercer actitudes de liderazgo y de trabajo en grupo para la toma de decisiones a partir de un sentido ético profesional."},
                        {perfil:"Desarrollar la actitud emprendedora mediante la creación e incubación de empresas, innovando en productos y servicios del sector electromecánico."},
                        {perfil:"Aplicar herramientas computacionales de acuerdo a las tecnologías de vanguardia, para el diseño, simulación y operación de sistemas electromecánicos acordes a la demanda del sector industrial."},
                        {perfil:"Interpretar comprender y comunicar ideas, textos y documentos de distinta índole en un segundo idioma."},
        ],
        reticula: 'http://tecmm.edu.mx/reticulasCarreras/Reticula-Ingenieria-Electromecanica.pdf',
        planEstudios: 'http://tecmm.edu.mx/planesEstudioCarreras/INGENIERA_ELECTROMECNICA__IEME-2010-210.pdf',
        folleto: 'http://tecmm.edu.mx/folletosCarreras/newFolletos/Folleto-Ingenieria-Electromecanica.pdf',
        convocatoria: "",
      };
  
      carrerasArray[8]={
        name: 'Ingenieria-Electronica',
        card:'http://tecmm.edu.mx/imagesReact/images/ofertaAcademicaNueva/Cards-Carreras-Nuevos/Card-Ing-Electronica.jpg',
        portada: 'https://tecmm.edu.mx/web-tsj-designs/portadasCarreras/Carreras-Banners-20.jpg',
        youtube:'3u5VE2SSBoU',
        videoInstitucional: "https://www.tecmm.edu.mx/videos/carreras/22_electronica.mp4",
        campus: 'ZAPOPAN',
        objetivo: "Formar profesionistas con competencias profesionales para diseñar, modelar, implementar, operar, integrar, mantener, instalar, administrar, innovar y transferir tecnología electrónica existente y emergente en proyectos interdisciplinarios, a nivel nacional e internacional, para resolver problemas y atender las necesidades de su entorno con ética, actitud emprendedora, creativa, analítica y comprometidos con el desarrollo sustentable.",
        perfilIngreso : [
                        {perfil:"Una preparación previa en electrónica o en alguna rama tecnológica afín."},
                        {perfil:"Una gran curiosidad por conocer el principio de funcionamiento del entorno que le rodea."},
                        {perfil:"Un espíritu creativo manifestado por la constante iniciativa de construir, proponer y cambiar así como resolver problemas."},
                        {perfil:"Vocación de servicio a la sociedad y cuidado del medio ambiente que le rodea."},
                        {perfil:"Capacidad de ejercer las habilidades propias del razonamiento lógico y la capacidad de análisis, síntesis, contrastación y aplicación del conocimiento."},
                        {perfil:"Habilidad para el manejo de instrumental científico y/o técnico."},
                        {perfil:"Una buena percepción del espacio y la capacidad para representar sus ideas, auxiliándose de dibujos y modelos tridimensionales."},
                        {perfil:"Hábitos, estrategias y comprensión de lectura fluida, poseer la habilidad para expresarse correcta y eficientemente, tanto en forma oral como escrita, así como interpretar los mensajes recibidos en ambas formas."},
        ],
        perfilEgreso : [
                        {perfil:"Diseñar, analizar y construir equipos y/o sistemas electrónicos para la solución de problemas en el entorno profesional, aplicando normas técnicas y estándares nacionales e internacionales."},
                        {perfil:"Crear, innovar y transferir tecnología aplicando métodos y procedimientos en proyectos de ingeniería electrónica, tomando en cuenta el desarrollo sustentable del entorno."},
                        {perfil:"Promover y participar en programas de mejora continua aplicando normas de calidad en toda empresa."},
                        {perfil:"Planear, organizar, dirigir y controlar actividades de instalación, actualización, operación y mantenimiento de equipos y/o sistemas electrónicos."},
                        {perfil:"Aplicar las nuevas tecnologías de la información y de la comunicación, para la adquisición y procesamiento de datos."},
                        {perfil:"Desarrollar y administrar proyectos de investigación y/o desarrollo tecnológico."},
                        {perfil:"Ejercer la profesión de manera responsable, ética y dentro del marco legal."},
                        {perfil:"Asumir las implicaciones de su desempeño profesional en el entorno político, social, económico y cultural."},
                        {perfil:"Comunicarse con efectividad en forma oral y escrita en el ámbito profesional tanto en su idioma como en un idioma extranjero."},
                        {perfil:"Ejercer actitudes emprendedoras, de liderazgo y desarrollar habilidades para la toma de decisiones en su ámbito profesional."},
                        {perfil:"Comprometer su formación integral permanente y de actualización profesional continua, de manera autónoma."},
                        {perfil:"Dirigir y participar en equipos de trabajo interdisciplinario y multidisciplinario en contextos nacionales e internacionales."},
                        {perfil:"Capacitar y actualizar en las diversas áreas de aplicación de ingeniería electrónica."},
                        {perfil:"Simular modelos que permitan predecir el comportamiento de sistemas electrónicos empleando plataformas computacionales."},
                        {perfil:"Seleccionar y operar equipo de medición y prueba."},
                        {perfil:"Utilizar lenguaje de descripción de hardware y programación de microcontroladores en el diseño de sistemas digitales para su aplicación en la resolución de problemas."},
                        {perfil:"Resolver problemas en el sector productivo mediante la automatización, instrumentación y control."},
                        {perfil:"Desarrollar aplicaciones en un lenguaje de programación de alto nivel para la solución de problemas relacionados con las diferentes disciplinas en el área."},
        ],
        reticula: 'http://tecmm.edu.mx/reticulasCarreras/Reticula-Ingenieria-Electronica.pdf',
        planEstudios: 'http://tecmm.edu.mx/planesEstudioCarreras/INGENIERA_ELECTRNICA_IELC-2010-211.pdf',
        folleto: 'http://tecmm.edu.mx/folletosCarreras/newFolletos/Folleto-Ingenieria-Electronica.pdf',
        convocatoria: "",
      };
  
      carrerasArray[9]={
        name: 'Ingenieria-en-Energias-Renovables',
        card:'http://tecmm.edu.mx/imagesReact/images/ofertaAcademicaNueva/Cards-Carreras-Nuevos/Card-Ing-Innovaion-Energias-Renovables.jpg',
        portada: 'https://tecmm.edu.mx/web-tsj-designs/portadasCarreras/Carreras-Banners-06.jpg',
        youtube:'FRMgk8ZD9JY',
        videoInstitucional: "FRMgk8ZD9JY",
        campus: 'TEQUILA',
        objetivo: "Formar recursos humanos competentes para intervenir profesionalmente en proyectos, estrategias y acciones de generación, transformación y almacenamiento de energía proveniente de fuentes renovables, así como de uso eficiente de la energía, en el marco de un desarrollo sustentable.",
        perfilIngreso : [
                        {perfil:"Capacidad de observación, análisis y síntesis."},
                        {perfil:"Habilidad de comunicación oral y escrita."},
                        {perfil:"Habilidad para trabajar en equipo."},
                        {perfil:"Respeto y aceptación por la diversidad cultural en un entorno de inclusión y equidad. "},
                        {perfil:"Conocimiento básico de Química, Física y Matemáticas. "},
                        {perfil:"Habilidad para comprender problemas energéticos  y sus impactos en la sociedad. "},
                        {perfil:"Ética y respeto al entorno natural."},
                        {perfil:"Compromiso por mejorar el entorno natural y social."},
                        {perfil:"Disposición para solucionar problemas ambientales mediante la aplicación del método científico. "},
                        {perfil:"Administración y optimización de los recursos energéticos."},
        ],
        perfilEgreso : [
                        {perfil:"Formular, evaluar y gestionar proyectos de desarrollo de ingeniería relacionados con gestionar las fuentes renovables de energía, en el marco del desarrollo sustentable."},
                        {perfil:"Diseñar e implementar estrategias para el uso eficiente de la energía de transformación y de servicios."},
                        {perfil:"Diseñar, implementar y gestionar actividades de instalación, operación y mantenimiento de sistemas ingenieriles utilizados para la transformación y almacenamiento de la energía proveniente de fuentes renovables."},
                        {perfil:"Colaborar en proyectos de investigación, desarrollo e innovación tecnológicos, relacionados con la energía proveniente de fuentes renovables."},
                        {perfil:"Identificar y evaluar el recurso energético renovable disponible en el entorno."},
                        {perfil:"Ejercer actitudes emprendedoras y de liderazgo en la toma de decisiones en su ámbito profesional."},
        ],
        reticula: 'http://tecmm.edu.mx/reticulasCarreras/Reticula-Ingenieria-en-Energias-Renovables.pdf',
        planEstudios: 'http://tecmm.edu.mx/planesEstudioCarreras/INGENIERA_EN_ENERGAS_RENOVABLES_IENR-2010-217.pdf',
        folleto: 'http://tecmm.edu.mx/folletosCarreras/Folleto-Ingenieria-en-Energias-Renovables.pdf',
        convocatoria: "",
      };
  
      carrerasArray[10]={
        name: 'Gastronomia',
        card:'http://tecmm.edu.mx/imagesReact/images/ofertaAcademicaNueva/Cards-Carreras-Nuevos/Card-Gastronomia.jpg',
        portada: 'https://tecmm.edu.mx/web-tsj-designs/portadasCarreras/Carreras-Banners-13.jpg',
        youtube:'NJzvlA8rfMw',
        videoInstitucional: "https://www.tecmm.edu.mx/videos/carreras/18_gastronomia.mp4",
        campus: 'CHAPALA, PUERTO VALLARTA, ZAPOPAN',
        objetivo: "Formar integralmente profesionales de gastronomía, líderes, creativos, con ética, responsabilidad social, vocación de servicio y visión estratégica; capaces de diseñar, desarrollar y aplicar el arte y técnicas culinarias; así como los conocimientos científicos en la innovación de la gastronomía y gestionar empresas del sector de alimentos y bebidas que contribuyan al desarrollo sustentable de la región.",
        perfilIngreso : [
                        {perfil:"Aplica los principios básicos de la administración y la contabilidad en las actividades propias de las organizaciones de alimentos y bebidas, para el mejor manejo de los ingresos y egresos."},
                        {perfil:"Realiza actividades específicas en el área de ventas y servicios al cliente tales como la creación de programas de comercialización de  servicios de alimentos y bebidas."},
                        {perfil:"Aplica los conocimientos mercadológicos para una mejor comercialización de los diferentes productos y servicios que ofrece una empresa de alimentos y bebidas."},
                        {perfil:"Aplica los conocimientos administrativos básicos en empresas que prestan servicios, compran, transforman y venden alimentos y bebidas."},
                        {perfil:"Comprenden el comportamiento físico-químico de los alimentos para la elaboración, conservación, innovación y presentación de los mismos."},
                        {perfil:"Diseñar estructuras administrativas del ámbito gastronómico acorde a los requisitos, aspectos legales, económicos, financieros y comerciales."},
                        {perfil:"Solucionar problemas y tomar decisiones para el óptimo cumplimiento de las actividades gastronómicas."},
                        {perfil:"Elaborar planes de negocios y establecer controles de calidad de alimentos y bebidas."},
                        {perfil:"Organizar y administrar recursos humanos y técnicos en los establecimientos de alimentos y bebidas."},
                        {perfil:"Integrar, organizar, planear y administrar su propio negocio."},
                        {perfil:"Establecer sistemas de información y control en empresas gastronómicas"},
        ],
        perfilEgreso : [
                        {perfil:"Aplicar los principios básicos de la administración y la contabilidad en las actividades propias de las organizaciones de alimentos y bebidas, para el mejor manejo de los ingresos y egresos."},
                        {perfil:"Realizar actividades específicas en el área de ventas y servicio al cliente tales como la creación de programas de comercialización de servicios de alimentos y bebidas."},
                        {perfil:"Aplicar los conocimientos mercadológicos para una mejor comercialización de los diferentes productos y servicios que ofrece una empresa de alimentos y bebidas."},
                        {perfil:"Aplicar los conocimientos administrativos básicos en empresas que prestan servicios, compran, transforman y venden alimentos y bebidas"},
                        {perfil:"Comprender el comportamiento físico-químico de los alimentos para mejorar la elaboración, conservación, innovación y presentación de los mismos."},
                        {perfil:"Diseñar estructuras administrativas del ámbito gastronómico acorde a los requisitos, aspectos legales, económicos, financieros y comerciales."},
                        {perfil:"Solucionar problemas y tomar decisiones para el óptimo cumplimiento de las actividades gastronómicas."},
                        {perfil:"Elaborar planes de negocios y establecer controles de calidad de alimentos y bebidas."},
                        {perfil:"Organizar y administrar recursos humanos y técnicos en los establecimientos de alimentos y bebidas."},
                        {perfil:"Integrar, organizar, planear y administrar su propio negocio."},
                        {perfil:"Establecer sistemas de información y control en empresas gastronómicas."},
                        {perfil:"Generar estrategias para el logro de los objetivos organizacionales."},
                        {perfil:"Identificar el origen y evolución de la gastronomía mediante el estudio de su desarrollo histórico."},
                        {perfil:"Implementar las diferentes normas nacionales e internacionales de seguridad e higiene, operando programas para el manejo higiénico de los alimentos."},
                        {perfil:"Diseñar, implementar y evaluar los procedimientos de recepción, almacenaje y elaboración de alimentos y bebidas."},
                        {perfil:"Diseñar e implementar cartas y menús."},
                        {perfil:"Diferenciar el origen y procedimientos de elaboración de las principales bebidas alcohólicas y no alcohólicas."},
                        {perfil:"Propiciar la creación y desarrollo de platillos de vanguardia analizando la composición de los alimentos con diversas técnicas y métodos de preparación de la cocina nutricional."},
        ],
        reticula: 'http://tecmm.edu.mx/reticulasCarreras/Reticula-Gastronomia.pdf',
        planEstudios: 'http://tecmm.edu.mx/planesEstudioCarreras/GASTRONOMA_GAST-2010-215.pdf',
        folleto: 'http://tecmm.edu.mx/folletosCarreras/newFolletos/Folleto-Gastronomia.pdf',
        convocatoria: "",
      };
  
      carrerasArray[11]={
        name: 'Ingenieria-en-Gestion-Empresarial',
        card:'http://tecmm.edu.mx/imagesReact/images/ofertaAcademicaNueva/Cards-Carreras-Nuevos/Card-Ing-Gestion-Empresarial.jpg',
        portada: 'https://tecmm.edu.mx/web-tsj-designs/portadasCarreras/Carreras-Banners-24.jpg',
        youtube:'vLiViO4OsFk',
        videoInstitucional: "https://www.tecmm.edu.mx/videos/carreras/26_gestion.mp4",
        campus: 'ARANDAS, PUERTO VALLARTA, CHAPALA, COCULA, EL GRULLO, TEQUILA, LA HUERTA, LAGOS DE MORENO, ZAPOPAN',
        objetivo: "Formar integralmente profesionales que contribuyan a la gestión de empresas e innovación de procesos; así como al diseño, implementación y desarrollo de sistemas estratégicos de negocios, optimizando recursos en un entorno global, con ética y responsabilidad social.",
        perfilIngreso : [
                        {perfil:"Capacidad de observación, análisis y síntesis."},
                        {perfil:"Habilidad verbal, facilidad de expresión, sentido de organización."},
                        {perfil:"Habilidad para comprender problemas sociales."},
                        {perfil:"Objetividad y don de mando."},
                        {perfil:"Habilidad de persuasión."},
                        {perfil:"Habilidad numérica."},
                        {perfil:"Habilidad para relacionarse con las personas, sociabilidad."},
        ],
        perfilEgreso : [
                        {perfil:"Desarrollar y aplicar habilidades directivas y la ingeniería en el diseño, creación, gestión, desarrollo, fortalecimiento e innovación de las organizaciones, con una orientación sistémica y sustentable para la toma de decisiones en forma efectiva."},
                        {perfil:"Diseñar e innovar estructuras administrativas y procesos, con base en las necesidades de las organizaciones para competir eficientemente en mercados globales."},
                        {perfil:"Gestionar eficientemente los recursos de la organización con visión compartida, con el fin de suministrar bienes y servicios de calidad"},
                        {perfil:"Aplicar métodos cuantitativos y cualitativos para el análisis e interpretación de datos y modelado de sistemas, en los procesos organizacionales para la mejora continua, atendiendo estándares de calidad mundial."},
                        {perfil:"Diseñar, evaluar y emprender nuevos negocios y proyectos empresariales, que promuevan el desarrollo sustentable y la responsabilidad social, en un mercado competitivo."},
                        {perfil:"Diseñar e implementar estrategias de mercadotecnia basadas en información recopilada de fuentes primarias y secundarias del consumidor o usuario de algún producto, de acuerdo a oportunidades y amenazas del mercado."},
                        {perfil:"Establecer programas para el fortalecimiento de la seguridad e higiene en las organizaciones."},
                        {perfil:"Gestionar sistemas integrales de calidad, ejerciendo un liderazgo efectivo y un compromiso ético, aplicando las herramientas básicas de la ingeniería."},
                        {perfil:"Interpretar y aplicar normas legales que incidan en la creación y desarrollo de las organizaciones."},
                        {perfil:"Integrar, dirigir y desarrollar equipos de trabajo para la mejora continua y el crecimiento integral de las organizaciones."},
                        {perfil:"Analizar e interpretar la información financiera para detectar oportunidades de mejora e inversión en un mundo global, que incidan en la rentabilidad del negocio."},
                        {perfil:"Utilizar las nuevas tecnologías de información en la organización, para optimizar los procesos de comunicación y eficientar la toma de decisiones."},
                        {perfil:"Propiciar el desarrollo del capital humano, para la realización de los objetivos organizacionales, dentro de un marco ético y un contexto multicultural."},
                        {perfil:"Aplicar métodos de investigación para desarrollar e innovar sistemas, procesos y productos en las diferentes dimensiones de la organización."},
                        {perfil:"Gestionar la cadena de suministros de las organizaciones con un enfoque orientado a procesos."},
                        {perfil:"Analizar e interpretar la economía global para facilitar la toma de decisiones en la organización."},
        ],
        reticula: 'http://tecmm.edu.mx/reticulasCarreras/Reticula-Ingenieria-en-Gestion-Empresarial.pdf',
        planEstudios: 'http://tecmm.edu.mx/planesEstudioCarreras/INGENIERA__EN_GESTIN_EMPRESARIAL_IGEM-2009-201.pdf',
        folleto: 'http://tecmm.edu.mx/folletosCarreras/newFolletos/Folleto-Ingenieria-en-Gestion-Empresarial.pdf',
        convocatoria: "",
      };
  
      carrerasArray[12]={
        name: 'Ingenieria-Industrial',
        card:'http://tecmm.edu.mx/imagesReact/images/ofertaAcademicaNueva/Cards-Carreras-Nuevos/Card-Ing-Industrial.jpg',
        portada: 'https://tecmm.edu.mx/web-tsj-designs/portadasCarreras/Carreras-Banners-07.jpg',
        youtube:'rcG4DQh9X2c',
        videoInstitucional: "https://www.tecmm.edu.mx/videos/carreras/32_industrial.mp4",
        campus: 'ARANDAS, CHAPALA, COCULA, EL GRULLO, LAGOS DE MORENO, MASCOTA, SAN JUAN DE LOS LAGOS, TEQUILA, TALA, TOMATLÁN, TEPATITLÁN, ZAPOPAN, ZAPOTLANEJO',
        objetivo: "Formar profesionistas, en el campo de la ingeniería industrial, líderes, creativos y emprendedores con visión sistémica, capacidad analítica y competitiva que les permita diseñar, implementar, mejorar, innovar, optimizar y administrar sistemas de producción de bienes y servicios en un entorno global, con enfoque sustentable, ético y comprometido con la sociedad.",
        perfilIngreso : [
                        {perfil:"Tener una amplia visión de la organización en la que desempeña su actividad profesional."},
                        {perfil:"Ser capaz de aplicar una amplia gama de herramientas para determinar las mejores combinaciones de recursos humanos, naturales, materiales, tecnológicos, financieros, etc."},
                        {perfil:"una persona curiosa y observadora, crítica, con capacidad de análisis y abstracción, con inquietud por la mejora continua y un gusto por tecnologías de diferentes tipos."},
                        {perfil:"alto sentido de la responsabilidad, motivación para realizar trabajo innovador y creativo, con un serio impacto profesional y social e interés en la superación y el desarrollo personal y profesional."},
                        {perfil:"hacia el trabajo orientado al logro de metas claras y realizables e interés en la aplicación de conocimiento científico, técnico, matemático y organizacional para la resolución de problemas."},
        ],
        perfilEgreso : [
                        {perfil:"Analizar, diseñar y gestionar sistemas productivos desde la provisión de insumos hasta la entrega de bienes y servicios, integrándolos con eficacia y eficiencia."},
                        {perfil:"Conocer, seleccionar y aplicar tecnologías para optimizar procesos productivos."},
                        {perfil:"Diseñar, implementar y administrar sistemas de mantenimiento para eficientar la operación de las instalaciones y equipos."},
                        {perfil:"Implementar sistemas de gestión de calidad para satisfacer los requerimientos del cliente y partes interesadas."},
                        {perfil:"Utilizar los instrumentos de medición requeridos en la certificación y/o acreditación con las normas vigentes."},
                        {perfil:"Interpretar e implementar estrategias y métodos estadísticos en los procesos organizacionales para la mejora continua."},
                        {perfil:"Seleccionar y adecuar modelos de calidad y diseño de experimentos en procesos organizacionales para su optimización."},
                        {perfil:"Gestionar sistemas de seguridad, salud ocupacional y protección al medio ambiente, en industrias de producción y de servicios."},
                        {perfil:"Identificar necesidades de su entorno y desarrollar investigación aplicada para crear e innovar bienes y servicios."},
                        {perfil:"Crear y mejorar productos de alto valor agregado bajo los principios de productividad y competitividad."},
                        {perfil:"Seleccionar e implementar tecnologías de información y comunicación dentro de la empresa."},
                        {perfil:"Participar en proyectos de transferencia, desarrollo y adaptación de tecnologías en los sistemas productivos."},
                        {perfil:"Diseñar, implementar y mejorar sistemas y estaciones de trabajo considerando factores ergonómicos para optimizar la producción."},
                        {perfil:"Participar en la estandarización de operaciones para la transferencia y adaptación de los sistemas productivos."},
                        {perfil:"Manejar y aplicar las normas y estándares en el análisis de operaciones de los sistemas de producción."},
                        {perfil:"Emprender e incubar empresas con base tecnológica, que promueva el desarrollo socioeconómico de una región, así como su constitución legal."},
                        {perfil:"Formular, evaluar y gestionar proyectos de inversión, que le permita emprender la creación de unidades productivas de bienes y servicios bajo criterios de competitividad y sustentabilidad."},
                        {perfil:"Tomar decisiones para la mejora de sistemas productivos y de servicios, fundamentadas en planteamientos y modelos analíticos."},
        ],
        reticula: 'http://tecmm.edu.mx/reticulasCarreras/Reticula-Ingenieria-Industrial.pdf',
        planEstudios: 'http://tecmm.edu.mx/planesEstudioCarreras/INGENIERA_INDUSTRIAL_IIND-2010-227.pdf',
        folleto: 'http://tecmm.edu.mx/folletosCarreras/Folleto-Ingenieria-Industrial.pdf',
        convocatoria: "",
      };
  
      carrerasArray[13]={
        name: 'Ingenieria-en-Industrias-Alimentarias',
        card:'http://tecmm.edu.mx/imagesReact/images/ofertaAcademicaNueva/Cards-Carreras-Nuevos/Card-Ing-Industrias-Alimentarias.jpg',
        portada: 'https://tecmm.edu.mx/web-tsj-designs/portadasCarreras/Carreras-Banners-14.jpg',
        youtube:'tbmEoE6wSzo',
        videoInstitucional: "https://www.tecmm.edu.mx/videos/carreras/27_alimentarias.mp4",
        campus: 'ARANDAS, MASCOTA, LAGOS DE MORENO, LA HUERTA, TAMAZULA',
        objetivo: "Formar profesionistas con bases sólidas científicas, tecnológicas, con actitudes éticas y aptitudes; capaces de diseñar, administrar, desarrollar, controlar e innovar sistemas de producción en la industria alimentaria, orientados de manera sustentable e integral en el ámbito regional, nacional e internacional.",
        perfilIngreso : [
                      {perfil:"Interés por la gestión de proyectos orientados a la producción y transformación de alimentos "},
                      {perfil:"Interés por el uso y transformación de materias primas provenientes del sector agropecuario"},
                      {perfil:"Uso de tecnologías de la información y la comunicación "},
                      {perfil:"Habilidad de observación"},
                      {perfil:"Adaptabilidad "},
                      {perfil:"Comunicarse correctamente de forma oral y escrita"},
                      {perfil:"Analizar, sintetizar y evaluar información "},
                      {perfil:"Integrar, aplicar y transferir los conocimientos adquiridos"},
                      {perfil:"Trabajar de manera individual y en equipo "},
                      {perfil:"Hábito de estudio"},
                      {perfil:"Pensamiento lógico matemático"},
                      {perfil:"Habilidad manual para el uso de equipo de laboratorio "},
                      {perfil:"Actitud crítica, autocrítica y de investigación hacia la naturaleza y el entorno social de su futura práctica profesional "},
                      {perfil:"Curiosidad intelectual orientada a la búsqueda de soluciones"},
                      {perfil:"Interés por conocer y participar en la solución de la problemática nacional"},
                      {perfil:"Actitud creadora para transformar la realidad mediante programas y proyectos de innovación tecnológica"},
                      {perfil:"Tener espíritu de servicio"},
                      {perfil:"Interés por el mejoramiento social, cultural y económico"},
        ],
        perfilEgreso : [
                        {perfil:"Diseñar, crear, aplicar, optimizar, analizar y evaluar, los sistemas de producción industrial de alimentos."},
                        {perfil:"Crear y utilizar tecnología sustentable en la industria alimentaria, reduciendo el impacto ambiental."},
                        {perfil:"Planear y operar industrias alimentarias en un enfoque de sustentabilidad."},
                        {perfil:"Desarrollar y aplicar las técnicas tradicionales, emergentes y procedimientos microbiológicos para la conservación de los alimentos, de acuerdo a sus propiedades funcionales."},
                        {perfil:"Valorar los materiales para el diseño del empaque y embalaje de acuerdo a las condiciones de procesamiento y características físicas y químicas de los alimentos."},
                        {perfil:"Inspeccionar, evaluar y controlar la inocuidad en alimentos, equipo e instalaciones de proceso conservando la calidad, seguridad y trazabilidad de los mismos."},
                        {perfil:"Usar conceptos básicos de la probabilidad y control estadístico para la resolución inherentes a la calidad del producto."},
                        {perfil:"Diseñar, desarrollar, gestionar y emprender sistemas de calidad, clase mundial en el sector alimentario."},
                        {perfil:"Inspeccionar, evaluar y controlar un proceso de producción o producto terminado mediante la aplicación de técnicas de evaluación fisicoquímicas y análisis instrumental."},
                        {perfil:"Adoptar, adaptar, transferir e innovar tecnologías para la transformación de alimentos."},
                        {perfil:"Analizar, desarrollar, emprender y evaluar productos innovadores con atributos nutritivos, funcionales y nutracéuticos."},
                        {perfil:"Desarrollar tecnologías para la generación de energías alternativas a partir de productos y subproductos alimenticios."},
                        {perfil:"Emprender, formular, ejecutar y evaluar planes de negocios; consultoría, y peritajes orientados a la creación de empresas."},
                        {perfil:"Diseñar e impartir cursos de capacitación referentes a tópicos de la industria alimentaria en general."},
                        {perfil:"Analizar y aplicar los principales conceptos y herramientas de la administración y economía, que permitan la distribución y utilización óptima de recursos."},
                        {perfil:"Desarrollar habilidades de liderazgo y comunicación oral y escrita para el desempeño profesional."},
                        {perfil:"Desempeñarse profesionalmente, con un espíritu emprendedor y compromiso ético, consigo mismo, colaboradores, sociedad y su entorno."},
                        {perfil:"Organizar y realizar trabajo inter y multidisciplinario en situaciones cambiantes y ambientes multiculturales."},
                        {perfil:"Dominar un segundo idioma, que le permita integrar y reforzar sus competencias profesionales."},
        ],
        reticula: 'http://tecmm.edu.mx/reticulasCarreras/Reticula-Ingenieria-en-Industrias-Alimentarias.pdf',
        planEstudios: 'http://tecmm.edu.mx/planesEstudioCarreras/INGENIERA_EN_INDUSTRIAS_ALIMENTARIAS_IIAL-2010-219.pdf',
        folleto: 'http://tecmm.edu.mx/folletosCarreras/newFolletos/Folleto-Ingenieria-en-Industrias-Alimentarias.pdf',
        convocatoria: "",
      };
  
      carrerasArray[14]={
        name: 'Ingenieria-Informatica',
        card:'http://tecmm.edu.mx/imagesReact/images/ofertaAcademicaNueva/Cards-Carreras-Nuevos/Card-Ing-Informatica.jpg',
        portada: 'https://tecmm.edu.mx/web-tsj-designs/portadasCarreras/Carreras-Banners-22.jpg',
        youtube:'a4aMltEoY-o',
        videoInstitucional: "https://www.tecmm.edu.mx/videos/carreras/33_informatica.mp4",
        campus: 'EL GRULLO, TEPATITLÁN, TEQUILA, ZAPOTLANEJO',
        objetivo: "Formar profesionales competentes en el diseño, desarrollo, implementación y administración de servicios informáticos y gestión de proyectos con una visión sistémica, tecnológica y estratégica, ofreciendo soluciones innovadoras e integrales a las organizaciones, de acuerdo con las necesidades globales, actuales y emergentes, comprometidos con su entorno, desempeñándose con actitud ética, emprendedora y de liderazgo.",
        perfilIngreso : [
                        {perfil:"El aspirante a titularse como Ingeniero Informático debe contar con la capacidad de plantear problemas y su resolución, basando ésta en el razonamiento lógico y matemático. También debe contar con las aptitudes de adaptación y curiosidad por las situaciones en constante cambio, además del interés por la tecnología y sus avances."},
                        {perfil:"Interés general por el manejo y uso de la computadora, dispositivos móviles y software básico."},
                        {perfil:"Conocer los fundamentos de las ciencias físico-matemáticas en nivel medio superior."},
                        {perfil:"Capacidad de abstracción, análisis, síntesis y razonamiento lógico que le permita la aplicación de conocimientos relacionados con sistemas de información."},
                        {perfil:"Tener curiosidad, imaginación, creatividad e innovación."},
                        {perfil:"Disponer de sentido de la organización y práctico."},
        ],
        perfilEgreso : [
                        {perfil:"Aplicar conocimientos científicos y tecnológicos en la solución de problemas en el área informática con un enfoque interdisciplinario."},
                        {perfil:"Administrar las tecnologías de la información, para estructurar proyectos estratégicos."},
                        {perfil:"Formular, gestionar y evaluar el desarrollo de proyectos informáticos en las organizaciones."},
                        {perfil:"Analizar, modelar, desarrollar, implementar y administrar sistemas de información para aumentar la productividad y competitividad de las organizaciones."},
                        {perfil:"Aplicar normas, marcos de referencia, estándares de calidad y seguridad vigentes en el ámbito del desarrollo y gestión de tecnologías y sistemas de información."},
                        {perfil:"Integrar las soluciones de tecnologías de información a los procesos organizacionales para fortalecer objetivos estratégicos."},
                        {perfil:"Seleccionar y utilizar de manera óptima técnicas y herramientas computacionales actuales y emergentes."},
                        {perfil:"Realizar actividades de auditoría y consultoría relacionadas con la función informática."},
                        {perfil:"Identificar, diseñar, desarrollar los mecanismos de almacenamiento, distribución, visualización y manipulación de la información."},
                        {perfil:"Identificar y aplicar modelos pertinentes en el diseño e implementación de base de datos para la gestión de la información en las organizaciones."},
                        {perfil:"Comunicarse de manera efectiva, en su propio idioma y al menos en un idioma extranjero, para integrarse a un contexto globalizado, en su desarrollo personal y profesional."},
                        {perfil:"Crear y administrar redes de comunicación, que contemplen el diseño, selección, instalación y mantenimiento para la operación de equipos de cómputo, aprovechando los avances tecnológicos a su alcance."},
                        {perfil:"Desempeñarse profesionalmente con ética en un contexto multicultural, comprometidos con la sociedad y conservación del medioambiente."},
                        {perfil:"Observar y fomentar el cumplimiento de las disposiciones de carácter legal, relacionadas con la función informática."},
                        {perfil:"Analizar, desarrollar y programar modelos matemáticos, estadísticos y de simulación."},
                        {perfil:"Liderar y participar en grupos de trabajo profesional multi e interdisciplinario, para el desarrollo de proyectos que requieran soluciones basadas en tecnologías y sistemas de información."},
                        {perfil:"Lograr un nivel de competencia internacional, con espíritu innovador, creativo y emprendedor, para generar nuevas oportunidades y desarrollar proyectos aplicando las tecnologías de información."},
        ],
        reticula: 'http://tecmm.edu.mx/reticulasCarreras/Reticula-Ingenieria-Informatica.pdf',
        planEstudios: 'http://tecmm.edu.mx/planesEstudioCarreras/INGENIERA_INFORMTICA_IINF-2010-220.pdf',
        folleto: 'http://tecmm.edu.mx/folletosCarreras/Folleto-Ingenieria-Informatica.pdf',
        convocatoria: "",
      };
      // no hay link
      carrerasArray[15]={
        name: 'Ingenieria-Mecatronica',
        card:'http://tecmm.edu.mx/imagesReact/images/ofertaAcademicaNueva/Cards-Carreras-Nuevos/Card-Ing-Mecatronica.jpg',
        portada: 'https://tecmm.edu.mx/web-tsj-designs/portadasCarreras/Carreras-Banners-08.jpg',
        youtube:'ATPLCRld3FA',
        videoInstitucional: "https://www.tecmm.edu.mx/videos/carreras/34_mecatronica.mp4",
        campus: 'CHAPALA',
        objetivo: "Formar profesionistas en la ingeniería mecatrónica con capacidad analítica, crítica y creativa que le permita diseñar, proyectar, construir, innovar y administrar equipos y sistemas mecatrónicos en el sector social y productivo; así como integrar, operar y mantenerlos, con un compromiso ético y de calidad en un marco de desarrollo sustentable.",
        perfilIngreso : [
                        {perfil:"Conocimientos en Informática, Ciencias básicas y exactas ."},
                        {perfil:"Temas de actualidad en ciencia y tecnología."},
                        {perfil:"Inglés básico."},
                        {perfil:"Razonamiento lógico matemático."},
                        {perfil:"Uso de tecnología y herramientas."},
                        {perfil:"Resolución de problemas desde los principios del método científico."},
                        {perfil:"Destreza manual para resolver problemas."},
                        {perfil:"Capacidad creativa."},
                        {perfil:"Organización de materiales."},
        ],
        perfilEgreso : [
                        {perfil:"Ejercer su profesión, dentro de un marco legal, teniendo un sentido de responsabilidad social, con apego a las normas nacionales e internacionales."},
                        {perfil:"Analizar, sintetizar, diseñar, simular, construir e innovar productos, procesos, equipos y sistemas mecatrónicos, con una actitud investigadora, de acuerdo a las necesidades tecnológicas y sociales actuales y emergentes, impactando positivamente en el entorno global."},
                        {perfil:"Integrar, instalar, construir, optimizar, operar, controlar, mantener, administrar y/o automatizar sistemas mecánicos utilizando tecnologías eléctricas, electrónicas y herramientas computacionales."},
                        {perfil:"Evaluar y generar proyectos industriales y de carácter social."},
                        {perfil:"Coordinar y dirigir grupos multidisciplinarios fomentando el trabajo en equipo para la implementación de proyectos mecatrónicos, asegurando su calidad, eficiencia, productividad y rentabilidad con sentido de responsabilidad de su entorno social y cultural para un desarrollo sustentable."},
                        {perfil:"Desarrollar capacidades de liderazgo, comunicación e interrelaciones personales para transmitir ideas, facilitar conocimientos, trabajar en equipos multidisciplinarios y multiculturales con responsabilidad colectiva para la solución de problemas y desarrollo de proyectos con un sentido crítico y autocrítico."},
                        {perfil:"Ser creativo, emprendedor y comprometido con su actualización profesional continua y autónoma, para estar a la vanguardia en los cambios científicos y tecnológicos que se dan en el ejercicio de su profesión."},
                        {perfil:"Interpretar información técnica de las áreas que componen la Ingeniería Mecatrónica para la transferencia, adaptación, asimilación e innovación de tecnologías de vanguardia."},
                        {perfilf:"Diseñar, configurar y administrar redes computacionales aplicando las normas y estándares vigentes."}
  
        ],
        reticula: 'http://tecmm.edu.mx/reticulasCarreras/Reticula-Ingenieria-Mecatronica.pdf',
        planEstudios: 'http://tecmm.edu.mx/planesEstudioCarreras/INGENIERA_MECATRNICA_IMCT-2010-229.pdf',
        folleto: 'http://tecmm.edu.mx/folletosCarreras/Folleto-Ingenieria-Mecatronica.pdf',
        convocatoria: "",
      };
  
      carrerasArray[16]={
        name: 'Ingenieria-en-Sistemas-Computacionales',
        card:'http://tecmm.edu.mx/imagesReact/images/ofertaAcademicaNueva/Cards-Carreras-Nuevos/Card-Ing-Sistemas-Computacionales.jpg',
        portada: 'https://tecmm.edu.mx/web-tsj-designs/portadasCarreras/Carreras-Banners-15.jpg',
        youtube:'ATPLCRld3FA',
        videoInstitucional: "https://www.tecmm.edu.mx/videos/carreras/30_sist_computacionales.mp4",
        campus: 'ARANDAS, CHAPALA, COCULA, LA HUERTA, ZAPOPAN, LAGOS DE MORENO, MASCOTA, PUERTO VALLARTA',
        objetivo: "Formar profesionistas líderes, analíticos, críticos y creativos, con visión estratégica y amplio sentido ético, capaces de diseñar, implementar y administrar infraestructura computacional para aportar soluciones innovadoras en beneficio de la sociedad, en un contexto global, multidisciplinario y sustentable.",
        perfilIngreso : [
                        {perfil:"Capacidad para analizar y sintetizar conceptos."},
                        {perfil:"Capacidad para analizar sistemas empresariales y organizacionales."},
                        {perfil:"Capacidad de interpretar las indicaciones de manuales, instructivos y diagramas."},
                        {perfil:"Capacidad de retentiva para elaborar juicios a nivel técnico-deductivos y diagramas."},
                        {perfil:"Sentido común, intuición y creatividad."},
                        {perfil:"Capacidad de razonamiento matemático."},
                        {perfil:"Amplio sentido de organización."},
        ],
        perfilEgreso : [
                        {perfil:"Diseñar, configurar y administrar redes computacionales aplicando las normas y estándares vigentes."},
                        {perfil:"Desarrollar, implementar y administrar software de sistemas o de aplicación que cumpla con los estándares de calidad con el fin de apoyar la productividad y competitividad de las organizaciones."},
                        {perfil:"Coordinar y participar en proyectos interdisciplinarios."},
                        {perfil:"Diseñar e implementar interfaces hombre-máquina y máquina-máquina para la automatización de sistemas."},
                        {perfil:"Identificar y comprender las tecnologías de hardware para proponer, desarrollar y mantener aplicaciones eficientes."},
                        {perfil:"Diseñar, desarrollar y administrar bases de datos conforme a requerimientos definidos, normas organizacionales de manejo y seguridad de la información, utilizando tecnologías emergentes."},
                        {perfil:"Integrar soluciones computacionales con diferentes tecnologías, plataformas o dispositivos."},
                        {perfil:"Desarrollar una visión empresarial para detectar áreas de oportunidad que le permitan emprender y desarrollar proyectos aplicando las tecnologías de la información y comunicación."},
                        {perfil:"Desempeñar sus actividades profesionales considerando los aspectos legales, éticos, sociales y de desarrollo sustentable."},
                        {perfil:"Poseer habilidades metodológicas de investigación que fortalezcan el desarrollo cultural, científico y tecnológico en el ámbito de sistemas computacionales y disciplinas afines."},
                        {perfil:"Seleccionar y aplicar herramientas matemáticas para el modelado, diseño y desarrollo de tecnología computacional."},
        ],
        reticula: 'http://tecmm.edu.mx/reticulasCarreras/Reticula-Ingenieria-en-Sistemas-Computacionales.pdf',
        planEstudios: 'http://tecmm.edu.mx/planesEstudioCarreras/INGENIERA_EN_SISTEMAS_COMPUTACIONALES_ISIC-2010-224.pdf',
        folleto: 'http://tecmm.edu.mx/folletosCarreras/newFolletos/Folleto-Ingenieria-en-Sistemas-Computacionales.pdf',
        convocatoria: "",
      };
  
      carrerasArray[17]={
        name: 'Ingenieria-en-Sistemas-Automotrices',
        card:'http://tecmm.edu.mx/imagesReact/images/ofertaAcademicaNueva/Cards-Carreras-Nuevos/Card-Ing-Sistemas-Automotrices.jpg',
        portada: 'https://tecmm.edu.mx/web-tsj-designs/portadasCarreras/Carreras-Banners-23.jpg',
        youtube:'KkH60AN6dg8',
        videoInstitucional: "https://www.tecmm.edu.mx/videos/carreras/29_sist_automotrices.mp4",
        campus: 'LAGOS DE MORENO',
        objetivo: "Formar ingenieros que se desempeñen en el diseño, planeación, desarrollo y automatización de sistemas automotrices, dentro del marco legal y sustentable, mediante competencias científicas, tecnológicas y administrativas, con el fin de atender las necesidades del sector automotriz, con una actitud ética, de liderazgo y responsabilidad social.",
        perfilIngreso : [
                        {perfil:"Conocimientos En el área de ciencias exactas del nivel medio superior, elementos básicos de redacción para la elaboración de textos, elementos básicos de computación."},
                        {perfil:"Estar acreditado en un nivel A2 del idioma inglés de acuerdo al Marco Común Europeo de Referencia para las Lenguas."},
                        {perfil:"Habilidades para el autoaprendizaje, la comprensión de un idioma extranjero, el manejo pacífico de conflictos, la utilización de las tecnologías de información, la capacidad de análisis y síntesis de textos científicos, el trabajo individual y en equipo."},
                        {perfil:"Interés por el conocimiento"},
                        {perfil:"Apertura al cambio y a los nuevos conocimientos"},
                        {perfil:"Búsqueda permanente de la superación y mejora personal a partir de la identificación de sus propias fortalezas y debilidades"},
                        {perfil:"Apertura al diálogo"},
                        {perfil:"Comprensión y tolerancia hacia la diversidad étnica, de clase, género, religión, preferencias políticas y/o sexuales,"},
                        {perfil:"Respeto y aprecio por la diversidad biológica y su integración ecológica"},
                        {perfil:"Participación en las transformaciones de su contexto"},
                        {perfil:"Aprecio por la diversidad cultural del planeta y nuestro país"},
                        {perfil:"Pensamiento proactivo y asertivo"},
                        {perfil:"Pensamiento crítico e innovador"},
                        {perfil:"Disposición hacia el autoaprendizaje"},
                        {perfil:"Mostrar una actitud solidaria, de respeto, con compromiso humanista"},
                        {perfil:"Mostrar una actitud ética, profesional con responsabilidad social"},
        ],
        perfilEgreso : [
                        {perfil:"Analiza y resuelve problemas de las diferentes disciplinas de ingeniería relacionadas con los sistemas automotrices, mediante el desarrollo e implementación de las nuevas tecnologías enfocadas a las necesidades del sector automotriz, de forma responsable y cooperativa."},
                        {perfil:"Fomenta el desarrollo sustentable para contribuir al equilibrio ambiental."},
                        {perfil:"Aplica conocimientos y habilidades generales de ingeniería en las áreas de diseño, manufactura, producción, calidad y conservación de la infraestructura, para fomentar la competitividad del sector automotriz."},
                        {perfil:"Desarrolla sistemas automotrices, aplicando los procesos de manufactura desde la planeación y diseño de instalaciones hasta las operaciones."},
                        {perfil:"Identifica, diagnostica y mide las áreas de oportunidad en los sistemas automotrices, para proponer alternativas de mejora utilizando técnicas y controles estadísticos mediante el trabajo en equipo."},
                        {perfil:"Utilizar normas nacionales e internacionales pertinentes, para asegurar la calidad, productividad, seguridad y sustentabilidad del sector automotriz."},
                        {perfil:"Aplica herramientas computacionales de acuerdo a las tecnologías de vanguardia, para el diseño, simulación, operación y optimización de sistemas automotrices acordes a la demanda del sector industrial."},
                        {perfil:"Diseña e integra sistemas de redes industriales para el control, comunicación y automatización de las líneas de producción en la industria automotriz."},
                        {perfil:"Propone alternativas de mejora continua en los sistemas de producción para optimizar los recursos materiales, humanos y financieros."},
                        {perfil:"Aplica la capacidad de dirección, liderazgo y comunicación de relaciones interpersonales, para transmitir ideas, facilitar conocimientos y trabajo en equipo con responsabilidad colectiva para la solución de problemas y desarrollo de proyectos en ingeniería en sistemas automotrices."},
        ],
        reticula: 'http://tecmm.edu.mx/reticulasCarreras/Reticula-Ingenieria-en-Sistemas-Automotrices.pdf',
        planEstudios: 'http://tecmm.edu.mx/planesEstudioCarreras/INGENIERIA_EN_SISTEMAS_AUTOMOTRICES_ISAU-2013-240.pdf',
        folleto: 'http://tecmm.edu.mx/folletosCarreras/newFolletos/Folleto-Ingenieria-en-Sistemas-Automotrices.pdf',
        convocatoria: "",
      };
  
      carrerasArray[19]={
        name: 'Licenciatura-en-Turismo',
        card:'http://tecmm.edu.mx/imagesReact/images/ofertaAcademicaNueva/Cards-Carreras-Nuevos/Card-Turismo.jpg',
        portada: 'https://tecmm.edu.mx/web-tsj-designs/portadasCarreras/Carreras-Banners-16.jpg',
        youtube:'h6n0vM-3bdI',
        videoInstitucional: "https://www.tecmm.edu.mx/videos/carreras/36_turismo.mp4",
        campus: 'PUERTO VALLARTA',
        objetivo: "Formar profesionistas capaces de emprender, gestionar e innovar proyectos turísticos sustentables con calidad, acordes con las necesidades de su entorno y las tendencias globales, con sentido ético y respeto a la integridad del patrimonio turístico.",
        perfilIngreso : [
                        {perfil:"Capacidad de abstracción, análisis y síntesis."},
                        {perfil:"Responsabilidad social y compromiso ciudadano"},
                        {perfil:"Capacidad de comunicarse en un segundo idioma "},
                        {perfil:"Capacidad crítica y autocrítica "},
                        {perfil:"Capacidad creativa "},
                        {perfil:"Capacidad para identificar, plantear y resolver problemas"},
                        {perfil:"Compromiso con la preservación del medio ambiente"},
                        {perfil:"Compromiso con su medio socio-cultural "},
                        {perfil:"Valoración y respeto por la diversidad y multiculturalidad "},
                        {perfil:"Respecto y conservación del patrimonio cultural y natural "},
                        {perfil:"Interés en el ámbito turístico"},
        ],
        perfilEgreso : [
                        {perfil:"Promueve el desarrollo sustentable en el turismo fomentando el respeto a la cultura y la naturaleza con un compromiso ético."},
                        {perfil:"Elabora y evalúa planes, programas y proyectos turísticos integrando a las comunidades para lograr un desarrollo sustentable."},
                        {perfil:"Reconoce el fenómeno turístico identificando los indicadores de orden social, para coadyuvar al aumento de la calidad de la oferta turística."},
                        {perfil:"Preserva la cultura nacional e internacional respetando la identidad de cada región."},
                        {perfil:"Emprende un plan de negocios para la creación de su propia empresa fundamentada en el turismo sustentable."},
                        {perfil:"Reconoce y valora la riqueza ecológica de la región para la prestación de servicios turísticos de naturaleza sustentables de forma segura."},
                        {perfil:"Administra recursos humanos, materiales, técnicos y financieros de empresas turísticas."},
                        {perfil:"Aplica el marco legal, para garantizar el funcionamiento del sistema turístico fortaleciendo la seguridad en su entorno."},
                        {perfil:"Aplica el uso de las tecnologías de la información y comunicación (TIC ́S) para lograr la eficiencia en el desarrollo del sistema turístico."},
                        {perfil:"Valora las nuevas tendencias para el desarrollo del sector turístico a nivel global."},
        ],
        reticula: 'http://tecmm.edu.mx/reticulasCarreras/Reticula-Licenciatura-en-Turismo.pdf',
        planEstudios: 'http://tecmm.edu.mx/planesEstudioCarreras/LICENCIATURA_EN_TURISMO_LTUR-2012-237.pdf',
        folleto: 'http://tecmm.edu.mx/folletosCarreras/newFolletos/Folleto-Licenciatura-en-Turismo.pdf',
        convocatoria: "",
      };
  
      carrerasArray[20]={
        name: 'Licenciatura-en-Administracion',
        card:'http://tecmm.edu.mx/imagesReact/images/ofertaAcademicaNueva/Cards-Carreras-Nuevos/Card-Administracion.jpg',
        portada: 'https://tecmm.edu.mx/web-tsj-designs/portadasCarreras/Carreras-Banners-21.jpg',
        youtube:'Hgv_R9b-TGs',
        videoInstitucional: "https://www.tecmm.edu.mx/videos/carreras/35_lic_administracion.mp4",
        campus: 'LA HUERTA, TEQUILA',
        objetivo: "Formar profesionales de la administración capaces de actuar como agentes de cambio, a través del diseño, innovación y dirección en organizaciones, sensibles a las demandas sociales y oportunidades del entorno, con capacidad de intervención en ámbitos globales y con un firme propósito de observar las normas y los valores universales.",
        perfilIngreso : [
                        {perfil:"Pensamiento complejo, orientado a dar soluciones"},
                        {perfil:"Sinergia, capacidad de realizar trabajo en equipo"},
                        {perfil:"Inteligencia emocional, razonar objetivamente bajo presión"},
                        {perfil:"Liderazgo, él como factor de cohesión"},
                        {perfil:"Responsabilidad social, poseer un marco de referencia ética evidente"},
                        {perfil:"Habilidad en las ciencias económico-administrativas."},
                        {perfil:"Comunicación oral y escrita, evidentes"},
        ],
        perfilEgreso : [
                        {perfil:"Integrar los procesos gerenciales, de administración, de innovación y las estrategias de dirección para la competitividad y productividad de las organizaciones."},
                        {perfil:"Aplicar los conocimientos modernos de la gestión de negocios a las fases del proceso administrativo para la optimización de recursos y el manejo de los cambios organizacionales."},
                        {perfil:"Desarrollar las habilidades directivas y de vinculación basadas en la ética y la responsabilidad social, que le permitan integrar y coordinar equipos interdisciplinarios, para favorecer el crecimiento de la organización y su entorno global."},
                        {perfil:"Crear y desarrollar proyectos sustentables aplicando herramientas administrativas y métodos de investigación de vanguardia, con un enfoque estratégico, multicultural y humanista."},
                        {perfil:"Conducir la organización hacia la consecución de sus objetivos mediante un esfuerzo coordinado y espíritu emprendedor."},
                        {perfil:"Crear organizaciones que contribuyan a la transformación económica y social, identificando las oportunidades de negocios en un contexto global."},
                        {perfil:"Conocer y aplicar el marco legal vigente nacional e internacional de las organizaciones."},
                        {perfil:"Analizar e interpretar información financiera y económica para la toma de decisiones en las organizaciones."},
                        {perfil:"Ser un agente de cambio con la habilidad de potenciar el capital humano para la solución de los problemas y la toma de decisiones que las organizaciones afrontan."},
                        {perfil:"Implementar y administrar sistemas de gestión de calidad para orientarlos a la mejora continua, con la finalidad de lograr la productividad de la organización, desarrollando una cultura de calidad total."},
                        {perfil:"Aplicar las tecnologías de la información y comunicación para optimizar el trabajo y desarrollo de la organización."},
                        {perfil:"Actualizar conocimientos permanentemente para responder a los cambios globales."},
                        {perfil:"Diseñar sistemas de organización considerando alternativas estratégicas que generen cadenas productivas en beneficio de la sociedad."},
                        {perfil:"Tener visión multidisciplinaria para generar propuestas y desarrollar acciones de manera inmediata ante escenarios de contingencia."},
  
        ],
        reticula: 'http://tecmm.edu.mx/reticulasCarreras/Reticula-Licenciatura-en-Administracion.pdf',
        planEstudios: 'http://tecmm.edu.mx/planesEstudioCarreras/LICENCIATURA_EN_ADMINISTRACIN_LADM-2010-234.pdf',
        folleto: 'http://tecmm.edu.mx/folletosCarreras/newFolletos/Folleto-Licenciatura-en-Administracion.pdf',
        convocatoria: "",
      };
  
      carrerasArray[21]={
        name: 'Ingenieria-en-Gestion-Empresarial-en-Linea',
        card:"http://tecmm.edu.mx/imagesReact/images/ofertaAcademicaNueva/Cards-Carreras-Nuevos/Card-Ing-Gestion-Empresarial-Linea.jpg",
        portada: 'http://tecmm.edu.mx/imagesReact/images/ofertaAcademicaNueva/Ing-Gestion-Empresarial-Linea.jpg',
        youtube:'PoT9UkO8TcI',
        videoInstitucional: "J6izdocayPA",
        campus: 'Arandas, Chapala, Cocula, El Grullo, La Huerta, Lagos de Moreno, Mascota, Tala, Tamazula, Tequila, Puerto Vallarta, Zapopan, Zapotlanejo',
        objetivo: 'Formar íntegramente profesionales que contribuyan a lagestión de empresas e innovación de procesos; así como al diseño, implementación y desarrollo de sistemas estratégicos de negocios, optimizando recursos en un entorno global, con ética y responsabilidad social.',
        perfilIngreso : [
                        {perfil:"Capacidad de observación, análisis y síntesis."},
                        {perfil:"Habilidad verbal, facilidad de expresión, sentido de organización."},
                        {perfil:"Habilidad para comprender problemas sociales."},
                        {perfil:"Objetividad y don de mando."},
                        {perfil:"Habilidad de persuasión."},
                        {perfil:"Habilidad numérica."},
                        {perfil:"Habilidad para relacionarse con las personas, sociabilidad."},
        ],
        perfilEgreso: [
                        {perfil:"Desarrollar y aplicar habilidades directivas y la ingeniería en el diseño, creación, gestión, desarrollo, fortalecimiento e innovación de las organizaciones, con una orientación sistémica y sustentable para la toma de decisiones en forma efectiva."},
                        {perfil:"Diseñar e innovar estructuras administrativas y procesos, con base en las necesidades de las organizaciones para competir eficientemente en mercados globales."},
                        {perfil:"Gestionar eficientemente los recursos de la organización con visión compartida, con el fin de suministrar bienes y servicios de calida.d"},
                        {perfil:"Aplicar métodos cuantitativos y cualitativos para el análisis e interpretación de datos y modelado de sistemas, en los procesos organizacionales para la mejora continua, atendiendo estándares de calidad nunciial."},
                        {perfil:"Diseñar, evaluar y emprender nuevos negocios y proyectos empresariales, que promuevan el desarrollo sustentable y la responsabilidad social, en un mercado competitivo "},
                        {perfil:"Diseñar e implementar estrategias de mercadotecnia basadas en información recopilada de fuentes primarias y secundarias del consumidor o usuario de algún producto, de acuerdo a oportunidades y amenazas del mercado."},
                        {perfil:"Establecer prograrnas para el fortalecimiento de la seguridad e higiene en las organizaciones."},
                        {perfil:"Gestionar sistemas integrales de calidad, ejerciendo un liderazgo efectivo y un compromiso ético, aplicando las herramientas básicas de la ingeniería."},
                        {perfil:"Interpretar y aplicar normas legales que incidan en la creación y desarrollo de las organizaciones."},
                        {perfil:"Integrar, dirigir y desarrollar equipos de trabajo para la mejora continua y el crecimiento integral de las organizaciones."},
                        {perfil:"Analizar e interpretar la información financiera para detectar oportunidades de mejora e inversión en un mundo global, que incidan en la rentabilidad del negocio."},
                        {perfil:"Utilizar las nuevas tecnologías de información en la organización, para optimizar los procesos de comunicación y eficientar la toma de decisiones. "},
                        {perfil:"Propiciar el desarrollo del capital humano, para la realización de los objetivos organizacionales, dentro de un marco ético y un contexto multicultural."},
                        {perfil:"Aplicar métodos de investigación para desarrollar e innovar sisternas, procesos y productos en las diferentes dimensiones de la organización."},
                        {perfil:"Analizar e interpretar la economía global para facilitar la toma de decisiones en la organización."},
        ],
        reticula: 'http://tecmm.edu.mx/reticulasCarreras/Reticula-Ingenieria-en-Gestion-Empresarial.pdf',
        planEstudios: 'http://tecmm.edu.mx/planesEstudioCarreras/INGENIERA__EN_GESTIN_EMPRESARIAL_IGEM-2009-201.pdf',
        folleto:'http://tecmm.edu.mx/folletosCarreras/newFolletos/Folleto-Ingenieria-en-Gestion-Empresarial.pdf',
        convocatoria: "",
      };
  
      carrerasArray[22]={
        name: 'Maestria-en-Administracion',
        card:'http://tecmm.edu.mx/imagesReact/images/ofertaAcademicaNueva/Cards-Carreras-Nuevos/Card-Maestria-Administracion.jpg',
        portada: 'https://tecmm.edu.mx/web-tsj-designs/portadasCarreras/Carreras-Banners-17.jpg',
        youtube:'U7SZz2s2qLY',
        videoInstitucional: "p2zos8KuCxY",
        campus: 'PUERTO VALLARTA',
        objetivo: "La Maestría en Administración, tiene como objetivo formar recursos humanos con una alta capacidad creativa e innovadora, con la destreza para el manejo de conocimientos, técnicas y habilidades que les permitan hacer frente a la problemática empresarial de forma racional, eficaz y eficientemente, aplicando los recursos que les permitan incrementar la innovación, la productividad y la tecnología, generando soluciones que mejoran la competitividad en las organizaciones y generar cambios en el entorno social, industrial y económico de Puerto Vallarta y del país.",

        perfilEgreso : [
                        {perfil:"Al término de sus estudios, el maestrante habrá fortalecido sus conocimientos, actitudes y habilidades directivas, que contribuyan al desarrollo económico y social de su ámbito de influencia, capaz de promover oportunidades de negocios, diseñar y operar nuevos modelos administrativos, establecer estrategias para impulsar la competitividad de las organizaciones y fortalecerlas de forma creativa e innovadora."},
                        {perfil:"El egresado de la maestría en administración será capaz de:"},
                        {perfil:"Analizar, crear y desarrollar proyectos, diseñar estrategias y prepararse para la toma de decisiones en las organizaciones."},
                        {perfil:"Ser directivos que tomen decisiones y/o que resuelvan problemas relacionados con los aspectos de la alta dirección y los negocios en un ámbito nacional e internacional."},
                        {perfil:"Generar nuevos conocimientos y soluciones para elevar la competitividad institucional a través de la innovación, productividad y desarrollo tecnológico desde una perspectiva nacional e internacional."},
                        {perfil:"Desarrollar nuevas alternativas de crecimiento y permanencia en el sector empresarial económicamente productivo desde una perspectiva de alta dirección."},
                        {perfil:"Crear y desarrollar planes de acción estratégicos para fortalecer la actividad económica- institucional en un micro y macro entorno."},
        ],
        reticula: null,
        folleto: 'http://tecmm.edu.mx/folletosCarreras/Folleto-Maestria-Administracion.pdf',
        convocatoria: "http://tecmm.edu.mx/recursos/convocatoriaMaestrias/CONVOCATORIA-MTRIA-EN-ADMINISTRACION.pdf",
  
      };
  
      carrerasArray[23]={
        name: 'Maestria-en-Sistemas-Computacionales',
        card:'http://tecmm.edu.mx/imagesReact/images/ofertaAcademicaNueva/Cards-Carreras-Nuevos/Card-Maestria-Sistemas-Computacionales.jpg',
        portada: 'https://tecmm.edu.mx/web-tsj-designs/portadasCarreras/Carreras-Banners-01.jpg',
        youtube:'rdWPqiy3RRc',
        videoInstitucional: "https://www.tecmm.edu.mx/videos/carreras/30_sist_computacionales.mp4",
        campus: 'ZAPOPAN',
        objetivo: "Formar líderes de excelencia con sólidos conocimientos en sistemas computacionales de alto nivel competitivo que analicen, diseñen y desarrollen software, en las áreas de multimedia y cómputo ubicuo.",
        perfilEgreso : [
                        {perfil:"El egresado de la maestría será un profesionista competente capaz de proponer, diseñar, implementar y desarrollar software de cómputo ubicuo o multimedia, teniendo la habilidad para participar en equipos multidisciplinarios y en la identificación de problemas o áreas de oportunidad, aportando propuestas de solución aplicando las metodologías y tecnologías de información emergentes que cumplan con estándares internacionales. Tendrá la habilidad para mejorar la toma de decisiones, que contribuya a resolver eficazmente los problemas de las organizaciones privadas y públicas, en el uso de tecnologías de vanguardia."},
                        {perfil:"Analizar, desarrollar y programar modelos matemáticos, estadísticos y de simulación."},
                        {perfil:"Reconocer y guiarse por los aspectos sociales, profesionales y éticos en su entorno."},
                        {perfil:"Dirigir y coordinar equipos de trabajo multi e interdisciplinarios."},
                        {perfil:"Coordinar y realizar investigaciones que fortalezcan el desarrollo cultural, científico y tecnológico."},
                        {perfil:"Desarrollar y administrar sistemas de información, redes de computadoras y aplicaciones distribuidas."},
                        {perfil:"Poseer una visión empresarial y detectar áreas de oportunidad para emprender y desarrollar proyectos aplicando las tecnologías de información y de las comunicaciones."},
                        {perfil:"Seleccionar y administrar los recursos humanos y computacionales para unidades de servicios de cómputo."},
                        {perfil:"Estar comprometido con el desarrollo sustentable, respetando el entorno social y cultural donde se desarrollan las organizaciones."},
                        {perfil:"Desarrollar y administrar software de aplicación y de base."},
                        {perfil:"Aplicar nuevas tecnologías a la solución de problemas de su entorno laboral."},
        ],
        reticula: null,
        folleto: 'http://tecmm.edu.mx/folletosCarreras/Folleto-Maestria-Sistemas-Computacionales.pdf',
        convocatoria: "http://tecmm.edu.mx/recursos/convocatoriaMaestrias/CONVOCATORIA-MTRIA-EN-SISTEMAS.pdf",
      };
  
      carrerasArray[24]={
        name: 'Ingenieria-en-Gestion-Empresarial-en-Linea',
        card:'http://tecmm.edu.mx/imagesReact/images/ofertaAcademicaNueva/Cards-Carreras-Nuevos/Card-Ing-Gestion-Empresarial-Linea.jpg',
        portada: 'https://tecmm.edu.mx/web-tsj-designs/portadasCarreras/Carreras-Banners-02.jpg',
        youtube:'PoT9UkO8TcI',
        videoInstitucional: "J6izdocayPA",
        campus: 'Arandas, Chapala, Cocula, El Grullo, La Huerta, Lagos de Moreno, Mascota, Tala, Tamazula, Tequila, Puerto Vallarta, Zapopan, Zapotlanejo',
        objetivo: 'Formar íntegramente profesionales que contribuyan a la gestión de empresas e innovación de procesos; así como al diseño, implementación y desarrollo de sistemas estratégicos de negocios, optimizando recursos en un entorno global, con ética y responsabilidad social.',
        perfilEgreso: [
                        {perfil:"Desarrollar y aplicar habilidades directivas y la ingeniería en el diseño, creación, gestión, desarrollo, fortalecimiento e innovación de las organizaciones, con una orientación sistémica y sustentable para la toma de decisiones en forma efectiva."},
                        {perfil:"Diseñar e innovar estructuras administrativas y procesos, con base en las necesidades de las organizaciones para competir eficientemente en mercados globales."},
                        {perfil:"Gestionar eficientemente los recursos de la organización con visión compartida, con el fin de suministrar bienes y servicios de calidad."},
                        {perfil:"Aplicar métodos cuantitativos y cualitativos para el análisis e interpretación de datos y modelado de sistemas, en los procesos organizacionales para la mejora continua, atendiendo estándares de calidad mundial."},
                        {perfil:"Diseñar, evaluar y emprender nuevos negocios y proyectos empresariales, que promuevan el desarrollo sustentable y la responsabilidad social, en un mercado competitivo "},
                        {perfil:"Diseñar e implementar estrategias de mercadotecnia basadas en información recopilada de fuentes primarias y secundarias del consumidor o usuario de algún producto, de acuerdo a oportunidades y amenazas del mercado."},
                        {perfil:"Establecer programas para el fortalecimiento de la seguridad e higiene en las organizaciones."},
                        {perfil:"Gestionar sistemas integrales de calidad, ejerciendo un liderazgo efectivo y un compromiso ético, aplicando las herramientas básicas de la ingeniería."},
                        {perfil:"Interpretar y aplicar normas legales que incidan en la creación y desarrollo de las organizaciones."},
                        {perfil:"Integrar, dirigir y desarrollar equipos de trabajo para la mejora continua y el crecimiento integral de las organizaciones."},
                        {perfil:"Analizar e interpretar la información financiera para detectar oportunidades de mejora e inversión en un mundo global, que incidan en la rentabilidad del negocio."},
                        {perfil:"Utilizar las nuevas tecnologías de información en la organización, para optimizar los procesos de comunicación y eficientar la toma de decisiones. "},
                        {perfil:"Propiciar el desarrollo del capital humano, para la realización de los objetivos organizacionales, dentro de un marco ético y un contexto multicultural."},
                        {perfil:"Aplicar métodos de investigación para desarrollar e innovar sistemas, procesos y productos en las diferentes dimensiones de la organización."},
                        {perfil:"Analizar e interpretar la economía global para facilitar la toma de decisiones en la organización."},
        ],
        reticula: 'http://tecmm.edu.mx/reticulasCarreras/Reticula-Ingenieria-en-Gestion-Empresarial.pdf',
        folleto:'http://tecmm.edu.mx/folletosCarreras/newFolletos/Folleto-Ingenieria-en-Gestion-Empresarial.pdf',
        convocatoria: "",
      };

      carrerasArray[25]={
        name: 'prueba',
        card:"http://tecmm.edu.mx/imagesReact/images/ofertaAcademicaNueva/Cards-Carreras-Nuevos/Card-Ing-Gestion-Empresarial-Linea.jpg",
        portada: 'http://tecmm.edu.mx/imagesReact/images/ofertaAcademicaNueva/Ing-Gestion-Empresarial-Linea.jpg',
        youtube:'PoT9UkO8TcI',
        videoInstitucional: "J6izdocayPA",
        campus: 'Arandas, Chapala, Cocula, El Grullo, La Huerta, Lagos de Moreno, Mascota, Tala, Tamazula, Tequila, Puerto Vallarta, Zapopan, Zapotlanejo',
        objetivo: 'Formar íntegramente profesionales que contribuyan a lagestión de empresas e innovación de procesos; así como al diseño, implementación y desarrollo de sistemas estratégicos de negocios, optimizando recursos en un entorno global, con ética y responsabilidad social.',
        perfilIngreso : [
                        {perfil:"Capacidad de observación, análisis y síntesis."},
                        {perfil:"Habilidad verbal, facilidad de expresión, sentido de organización."},
                        {perfil:"Habilidad para comprender problemas sociales."},
                        {perfil:"Objetividad y don de mando."},
                        {perfil:"Habilidad de persuasión."},
                        {perfil:"Habilidad numérica."},
                        {perfil:"Habilidad para relacionarse con las personas, sociabilidad."},
        ],
        perfilEgreso: [
                        {perfil:"Desarrollar y aplicar habilidades directivas y la ingeniería en el diseño, creación, gestión, desarrollo, fortalecimiento e innovación de las organizaciones, con una orientación sistémica y sustentable para la toma de decisiones en forma efectiva."},
                        {perfil:"Diseñar e innovar estructuras administrativas y procesos, con base en las necesidades de las organizaciones para competir eficientemente en mercados globales."},
                        {perfil:"Gestionar eficientemente los recursos de la organización con visión compartida, con el fin de suministrar bienes y servicios de calida.d"},
                        {perfil:"Aplicar métodos cuantitativos y cualitativos para el análisis e interpretación de datos y modelado de sistemas, en los procesos organizacionales para la mejora continua, atendiendo estándares de calidad nunciial."},
                        {perfil:"Diseñar, evaluar y emprender nuevos negocios y proyectos empresariales, que promuevan el desarrollo sustentable y la responsabilidad social, en un mercado competitivo "},
                        {perfil:"Diseñar e implementar estrategias de mercadotecnia basadas en información recopilada de fuentes primarias y secundarias del consumidor o usuario de algún producto, de acuerdo a oportunidades y amenazas del mercado."},
                        {perfil:"Establecer prograrnas para el fortalecimiento de la seguridad e higiene en las organizaciones."},
                        {perfil:"Gestionar sistemas integrales de calidad, ejerciendo un liderazgo efectivo y un compromiso ético, aplicando las herramientas básicas de la ingeniería."},
                        {perfil:"Interpretar y aplicar normas legales que incidan en la creación y desarrollo de las organizaciones."},
                        {perfil:"Integrar, dirigir y desarrollar equipos de trabajo para la mejora continua y el crecimiento integral de las organizaciones."},
                        {perfil:"Analizar e interpretar la información financiera para detectar oportunidades de mejora e inversión en un mundo global, que incidan en la rentabilidad del negocio."},
                        {perfil:"Utilizar las nuevas tecnologías de información en la organización, para optimizar los procesos de comunicación y eficientar la toma de decisiones. "},
                        {perfil:"Propiciar el desarrollo del capital humano, para la realización de los objetivos organizacionales, dentro de un marco ético y un contexto multicultural."},
                        {perfil:"Aplicar métodos de investigación para desarrollar e innovar sisternas, procesos y productos en las diferentes dimensiones de la organización."},
                        {perfil:"Analizar e interpretar la economía global para facilitar la toma de decisiones en la organización."},
        ],
        reticula: 'http://tecmm.edu.mx/reticulasCarreras/Reticula-Ingenieria-en-Gestion-Empresarial.pdf',
        planEstudios: 'http://tecmm.edu.mx/planesEstudioCarreras/INGENIERA__EN_GESTIN_EMPRESARIAL_IGEM-2009-201.pdf',
        folleto:'http://tecmm.edu.mx/folletosCarreras/newFolletos/Folleto-Ingenieria-en-Gestion-Empresarial.pdf',
        convocatoria: "",
      };
  
      return carrerasArray;
  
   }



export function CarrerasDataBetter(){


  var carrerasArray = [];

    carrerasArray[0]={
      name: 'Ingenieria-en-Innovacion-Agricola-Sustentable',
      card:'https://tecmm.edu.mx/web-tsj-designs/ofertaAcademica/Carreras-04.jpg',
      portada: "https://tecmm.edu.mx/web-tsj-designs/portadasCarreras/Carreras-Banners-03.jpg",
      youtube:'HXcpQfI72Bo',
      videoInstitucional: "https://www.tecmm.edu.mx/videos/carreras/28_innovacion.mp4",
      campus: "ARANDAS, COCULA, EL GRULLO, CUQUÍO, MASCOTA, TALA, TAMAZULA, TOMATLÁN, TENAMAXTLÁN, ZAPOTLANEJO",
      campusNoAccent: "ARANDAS, COCULA, EL GRULLO, CUQUIO, MASCOTA, TALA, TAMAZULA, TOMATLAN, TENAMAXTLAN, ZAPOTLANEJO",
      objetivo: "Formar profesionistas analíticos y críticos, comprometidos socialmente y con sólida cultura científico tecnológica, que les permita la planeación del desarrollo regional en el contexto de la sustentabilidad, para realizar investigación, validación, transferencia, adaptación, producción e innovación agrícola.",
      perfilIngreso : [
                        {perfil:"Contar con preparación académica y cultural de nivel medio superior"},
                        {perfil:"Interés por las actividades agrícolas de campo y de agricultura protegida"},
                        {perfil:"Conocimientos en química, biología, física y matemáticas"},
                        {perfil:"Conocimientos básicos de inglés"},
                        {perfil:"Habilidad en el uso de las tecnologías de la información y comunicación (computadora, procesadores de texto básicos, internet, video, etc)"},
                        {perfil:"Respecto a los valores humanos, diversidad cultural e ideológica"},
                        {perfil:"Vocación de servicio para cuidado del medio ambiente y social"},
                        {perfil:"Uso del lenguaje oral y escrita de forma correcta"},
                        {perfil:"Disponibilidad para el trabajo individual y en equipo"},
      ],
      perfilEgreso : [
                      {perfil:"Diseñar, crear, instalar, operar y proporcionar mantenimiento a empresas agrícolas dentro de un marco de desarrollo regional, nacional e internacional."},
                      {perfil:"Desarrollar, coordinar, asesorar y dirigir empresas basadas en sistemas de producción de agricultura protegida, considerando las adecuaciones que implican las condiciones ecológicas y socioeconómicas de las distintas regiones del país."},
                      {perfil:"Aplicar conocimientos técnicos, administrativos, culturales y de extensión para el uso eficiente del agua en la agricultura mediante la implementación y manejo adecuado de los sistemas de riego, con base en las necesidades hídricas de los cultivos, condiciones climáticas, edáficas y sociales."},
                      {perfil:"Promover la organización de los productores y asesorar a éstos en los sistemas de agricultura protegida en cultivos estratégicos; así como garantizar una producción con calidad y proponer innovaciones tecnológicas."},
                      {perfil:"Participar con organizaciones, grupos de trabajo y productores interesados en el establecimiento de proyectos productivos sobre agricultura protegida e impulsar la aplicación de buenas prácticas agrícolas y de manufactura."},
                      {perfil:"Realizar labores profesionales de docencia, investigación y extensión de nuevos conocimientos, metodologías y tecnologías, en los ámbitos de la agricultura protegida, inocuidad alimentaria y bioseguridad, para el mejor aprovechamiento de los recursos del agro."},
                      {perfil:"Innovar los sistemas de producción agrícola a través de la aplicación de modelos de predicción mediante el uso de herramientas informáticas, para la toma de decisiones en las actividades de riego, nutrición, manejo integrado de plagas, enfermedades y riesgo climático."},
                      {perfil:"Solucionar problemas en la producción, manejo y comercialización de productos agrícolas, con el empleo racional e innovador de los recursos naturales y materiales en la producción agrícola."},
                      {perfil:"Participar en la solución de la problemática del sector agrícola y hacer sustentable el uso de los recursos naturales en su producción, transformación y comercialización en el ámbito nacional e internacional."},
                      {perfil:"Generar, validar y adoptar nuevas tecnologías compatibles con la situación socioeconómica del entorno regional para aprovechar el potencial productivo de las áreas agrícolas en función de sus condiciones ecológicas."},
                      {perfil:"Analizar la problemática agrícola en términos tecnológicos, ecológicos, económicos y sociales, para establecer las bases de un manejo sustentable basado en las formas de organización social predominantes en la región."},
                      {perfil:"Manejar y aplicar metodologías y técnicas que permitan obtener información pertinente para planear actividades de un sistema productivo sustentable."},
                      {perfil:"Desarrollar sistemas de producción agrícola sustentable, a través del uso racional y organizado de los recursos regionales disponibles y la innovación tecnológica con la finalidad de mejorar su productividad y lograr un equilibrio ambiental."},
                      {perfil:"Conocer y aplicar la legislación nacional e internacional, en la producción, transformación y comercialización de productos y servicios agrícolas con estándares de calidad."},
      ],
      reticula: 'http://tecmm.edu.mx/reticulasCarreras/Reticula-Ingenieria-en-Innovacion-Agricola-Sustentable.pdf',
      planEstudios: 'http://tecmm.edu.mx/planesEstudioCarreras/INGENIERA_EN_INNOVACIN_AGRCOLA_SUSTENTABLE_IIAS-2010-221.pdf',
      folleto: 'http://tecmm.edu.mx/folletosCarreras/newFolletos/Folleto-Ingenieria-en-Innovacion-Agricola-Sustentable.pdf',
      convocatoria: "",
    };

    carrerasArray[1]={
      name: 'Ingenieria-en-Administracion',
      card:'https://tecmm.edu.mx/web-tsj-designs/ofertaAcademica/Carreras-05.jpg',
      portada: 'https://tecmm.edu.mx/web-tsj-designs/portadasCarreras/Carreras-Banners-10.jpg',
      youtube:'PQvsRYS3hZ0',
      videoInstitucional: "https://www.tecmm.edu.mx/videos/carreras/23_ing_administracion.mp4",
      campus: 'LA HUERTA, MASCOTA, TALA, TAMAZULA, TEPATITLÁN, ZAPOTLANEJO, IXTLAHUACÁN, CUQUÍO',
      campusNoAccent: 'LA HUERTA, MASCOTA, TALA, TAMAZULA, TEPATITLAN, ZAPOTLANEJO, IXTLAHUACAN, CUQUIO', 
      objetivo: "Formar profesionales competentes en la creación, dirección e innovación de organizaciones que enfrenten desafíos en un entorno globalizado, con visión emprendedora, ética, humana y de responsabilidad social.",
      perfilIngreso : [
                      {perfil:"Pensamiento complejo, orientado a dar soluciones."},
                      {perfil:"Sinergia, capacidad de realizar trabajo en equipo."},
                      {perfil:"Inteligencia emocional, razonar objetivamente bajo presión."},
                      {perfil:"Liderazgo, él como factor de cohesión."},
                      {perfil:"Responsabilidad social, poseer un marco de referencia ética evidente."},
                      {perfil:"Habilidad en las ciencias económico-administrativas."},
                      {perfil:"Comunicación oral y escrita, evidentes."},
      ],
      perfilEgreso : [
                      {perfil:"Ser un profesional ético, sensible a la diversidad cultural, con la capacidad de gestionar, crear, desarrollar e innovar organizaciones competitivas con una visión de sustentabilidad y responsabilidad social."},
                      {perfil:"Ser un emprendedor que promueva la transformación económica y social a través de la creación de empresas, identificando las oportunidades de negocios en contextos locales, regionales, nacionales e internacionales."},
                      {perfil:"Proponer soluciones creativas que generen ventajas competitivas para resolver problemas de las áreas funcionales en las organizaciones, utilizando procedimientos, técnicas y herramientas de la ingeniería."},
                      {perfil:"Actuar como agente de cambio en las organizaciones, en respuesta a las necesidades del entorno."},
                      {perfil:"Utilizar las tecnologías de la información y comunicación en el ejercicio de su práctica profesional."},
                      {perfil:"Organizar, integrar y dirigir equipos de trabajo para favorecer el crecimiento de la organización."},
                      {perfil:"Aplicar habilidades directivas para el logro de los objetivos organizacionales en diferentes contextos."},
                      {perfil:"Utilizar procesos de comunicación efectivos en las organizaciones y su entorno."},
                      {perfil:"Interpretar y gestionar los sistemas de calidad y el proceso de mejora continua para las organizaciones."},
                      {perfil:"Interpretar y aplicar el marco legal vigente, acorde a las necesidades situacionales de la organización."},
                      {perfil:"Desarrollar y/o dirigir proyectos empresariales utilizando los medios y herramientas de forma multidisciplinaria."},
                      {perfil:"Diseñar e implementar estrategias para optimizar los procesos de las áreas funcionales de la organización."},
                      {perfil:"Tomar decisiones en las operaciones económicas, administrativas y financieras que se generen dentro de la organización, en un marco jurídico/fiscal."},
                      {perfil:"Prestar servicios de consultoría en su ámbito profesional."},
                      {perfil:"Generar, promover y dirigir investigación para propiciar el desarrollo del entorno."},
                      {perfil:"Participar en la administración de sistemas de logística."},
                      {perfil:"Identificar y administrar los riesgos financieros de las organizaciones."},
                      {perfil:"Diseñar, implementar y evaluar los sistemas y modelos administrativos, para la optimización de los recursos que intervienen en la organización, con un enfoque de calidad y competitividad."},
                      {perfil:"Promover y desarrollar el potencial del capital humano en los diferentes niveles de la organización."},
                      {perfil:"Diseñar y gestionar procesos productivos y de servicios que respondan a las necesidades del entorno."},
      ],
      reticula: 'http://tecmm.edu.mx/reticulasCarreras/Reticula-Ingenieria-en-Administracion.pdf',
      planEstudios: 'http://tecmm.edu.mx/planesEstudioCarreras/INGENIERA_EN_ADMINISTRACIN_IADM-2010-213.pdf',
      folleto: 'http://tecmm.edu.mx/folletosCarreras/newFolletos/Folleto-Ingenieria-en-Administracion.pdf',
      convocatoria: "",
    };

    carrerasArray[2]={
      name: 'Ingenieria-Ambiental',
      card:'https://tecmm.edu.mx/web-tsj-designs/ofertaAcademica/Carreras-06.jpg',
      portada: 'https://tecmm.edu.mx/web-tsj-designs/portadasCarreras/Carreras-Banners-18.jpg',
      youtube:'qISRetksfrA',
      videoInstitucional: "https://www.tecmm.edu.mx/videos/carreras/19_ambiental.mp4",
      campus: 'ARANDAS, TEPATITLÁN',
      campusNoAccent: 'ARANDAS, TEPATITLAN',
      objetivo: "Formar profesionistas en Ingeniería Ambiental éticos, analíticos, críticos y creativos con las competencias para identificar, proponer y resolver problemas ambientales de manera multidisciplinaria, asegurando la protección, conservación y mejoramiento del ambiente, bajo un marco legal, buscando el desarrollo sustentable en beneficio de la vida en el planeta.",
      perfilIngreso : [
                      {perfil:"Capacidad de observación, análisis y síntesis."},
                      {perfil:"Habilidad de comunicación oral y escrita."},
                      {perfil:"Habilidad para trabajar en equipo."},
                      {perfil:"Respeto y aceptación por la diversidad cultural en un entorno de inclusión y equidad."},
                      {perfil:"Conocimiento básico de Biología, Química, Física y Matemáticas."},
                      {perfil:"Habilidad para comprender problemas ambientales y sociales."},
                      {perfil:"Ética y respeto al entorno natural."},
                      {perfil:"Compromiso por mejorar el entorno natural y social."},
                      {perfil:"Disposición para solucionar problemas ambientales mediante la aplicación del método científico."},
                      {perfil:"Administración y optimización de recursos naturales."},
      ],
      perfilEgreso : [
                      {perfil:"Vincular el valor de los recursos naturales para promover su uso sustentable de acuerdo a las necesidades de la región, mediante instrumentos de concientización, sensibilización y comunicación."},
                      {perfil:"Participar en el desarrollo y ejecución del protocolo de investigación básica o aplicada para la resolución de problemas ambientales."},
                      {perfil:"Elaborar, implementar y mantener sistemas de gestión ambiental."},
                      {perfil:"Participar en la realización de auditorías ambientales en el sector público y privado."},
                      {perfil:"Realizar diagnósticos y evaluaciones de impacto y riesgo ambiental sustentados en métodos y procedimientos certificados conforme a los criterios Nacionales e Internacionales."},
                      {perfil:"Elaborar estudios de factibilidad económica y técnica de los procesos para la prevención y control ambiental."},
                      {perfil:"Proponer e innovar tecnologías para el manejo de los residuos cumpliendo la legislación ambiental vigente."},
                      {perfil:"Conocer y aplicar criterios de ingeniería básica y aplicada, así como de las ciencias biológicas para el dimensionamiento, adecuación, operación, mantenimiento y desarrollo de tecnologías de tratamiento, prevención, control y transformación de efluentes sólidos, líquidos y gaseosos contaminados."},
                      {perfil:"Conocer y aplicar las TIC, así como sistemas computacionales o software especializados en el área ambiental."},
                      {perfil:"Ser analítico, ético, crítico, y consciente de la constancia de su entorno para la vida y respetuoso de la misma, siendo promotor del desarrollo sustentable."},
                      {perfil:"Ser capaz de formar recursos humanos, realizar actividades de docencia, investigación y capacitación."},
                      {perfil:"Tener una actitud emprendedora y de liderazgo para interactuar con grupos multidisciplinarios e interdisciplinarios en la búsqueda de soluciones a los problemas del deterioro del medio ambiente."},
      ],
      reticula: 'http://tecmm.edu.mx/reticulasCarreras/Reticula-Ingenieria-Ambiental.pdf',
      planEstudios: 'http://tecmm.edu.mx/planesEstudioCarreras/INGENIERA_AMBIENTAL_IAMB-2010-206.pdf',
      folleto: 'http://tecmm.edu.mx/folletosCarreras/Folleto-Ingenieria-Ambiental.pdf',
      convocatoria: "",
    };

    carrerasArray[3]={
      name: 'Ingenieria-en-Animacion-Digital-y-Efectos-Visuales',
      card:'https://tecmm.edu.mx/web-tsj-designs/ofertaAcademica/Carreras-07.jpg',
      portada: 'https://tecmm.edu.mx/web-tsj-designs/portadasCarreras/Carreras-Banners-04.jpg',
      youtube:'4LMrqdyR8fQ',
      videoInstitucional: "https://www.tecmm.edu.mx/videos/carreras/24_animacion.mp4",
      campus: 'CHAPALA',
      campusNoAccent: 'CHAPALA',
      objetivo: "Formar profesionistas capaces de adaptar, desarrollar y aplicar recursos tecnológicos en la producción de animación digital y efectos visuales, impulsando la tecnología para responder a los desafíos de las industrias de la animación digital y los efectos visuales, en un contexto global, multidisciplinario y sustentable.",
      perfilIngreso : [
                      {perfil:"Habilidades en el manejo de las Tecnologías de la Información."},
                      {perfil:"Interés por el desarrollo y la innovación tecnología."},
                      {perfil:"Espíritu emprendedor y creativo."},
                      {perfil:"Alto grado de compromiso y motivación orientados a su propio desarrollo."},
                      {perfil:"Creatividad, imaginación y sentido de inventiva."},
                      {perfil:"Interés por la tecnología y las artes visuales."},
      ],
      perfilEgreso : [
                      {perfil:"Crea gráficas computacionales de calidad utilizando software especializado."},
                      {perfil:"Implementa técnicas y procesos de producción de actualidad en las industrias de la animación digital y los efectos visuales."},
                      {perfil:"Interpreta la estructura narrativa de proyectos de animación digital y efectos visuales que sean aplicados en la industria."},
                      {perfil:"Crea efectos visuales utilizando software especializado de producción y post-producción."},
                      {perfil:"Aplica los lenguajes de programación utilizados en el desarrollo de software para las industrias de la animación digital y los efectos visuales."},
                      {perfil:"Desarrolla herramientas de software para facilitar y optimizar los procesos de producción y post-producción de la animación digital y los efectos visuales."},
                      {perfil:"Desarrolla modelos de simulación física en entornos gráficos computacionales aplicables a proyectos de animación digital y efectos visuales."},
                      {perfil:"Desarrolla modelos matemáticos para la representación de gráficas por computadora."},
                      {perfil:"Aplica modelos psicológicos para recrear el comportamiento de personajes en entornos de animación digital."},
                      {perfil:"Integra infraestructura computacional que permita el desarrollo de proyectos de animación digital."},
                      {perfil:"Desarrolla una visión emprendedora y creativa para detectar áreas de oportunidad que le permitan implementar proyectos innovadores y crear nuevas empresas."},
                      {perfil:"Desempeña sus actividades profesionales, considerando los aspectos legales, éticos, humanos y sociales, para sustentar el mejoramiento de su entorno."},
                      {perfil:"Participa activamente en proyectos de investigación y desarrollo tecnológico para fortalecer las industrias de la animación digital y los efectos visuales."},
                      {perfil:"Gestiona proyectos multidisciplinarios para optimizar el uso de recursos."},
      ],
      reticula: 'http://tecmm.edu.mx/reticulasCarreras/Reticula-Ingenieria-en-Animacion-Digital-y-Efectos-Visuales.pdf',
      planEstudios: 'http://tecmm.edu.mx/planesEstudioCarreras/INGENIERIA_EN_ANIMACION_DIGITAL_Y_EFECTOS_VISUALES_IAEV-2012-238.pdf',
      folleto: 'http://tecmm.edu.mx/folletosCarreras/Folleto-Ingenieria-en-Animacion-Digital-y-Efectos-Visuales.pdf',
      convocatoria: "",
    };

    carrerasArray[4]={
      name: 'Arquitectura',
      card:'https://tecmm.edu.mx/web-tsj-designs/ofertaAcademica/Carreras-08.jpg',
      portada: 'https://tecmm.edu.mx/web-tsj-designs/portadasCarreras/Carreras-Banners-11.jpg',
      youtube:'8yMYKTH0wps',
      videoInstitucional: "https://www.tecmm.edu.mx/videos/carreras/16_arquitectura.mp4",
      campus: 'ARANDAS, EL GRULLO, LAGOS DE MORENO, PUERTO VALLARTA, TALA, ZAPOPAN',
      campusNoAccent: 'ARANDAS, EL GRULLO, LAGOS DE MORENO, PUERTO VALLARTA, TALA, ZAPOPAN',
      objetivo: "Formar en competencias, profesionistas líderes con excelencia académica y humanística, capaces de diseñar, gestionar y construir el hábitat humano con alto desempeño, sustentabilidad y vocación de servicio a la sociedad.",
      perfilIngreso : [
                      {perfil:"Aptitudes hacia la expresión gráfica"},
                      {perfil:"Aptitudes para el trabajo permanente, con disciplina y alegría"},
                      {perfil:"Actitudes coherentes con la inventiva y la creatividad"},
                      {perfil:"Pensamientos sobre historia y valoración del pasado arquitectónico"},
      ],
      perfilEgreso : [
                      {perfil:"Diseñar de manera integral proyectos urbano-arquitectónicos, respetando los marcos normativos y los criterios de diseño universal, estéticos y espaciales."},
                      {perfil:"Diseñar el interiorismo y paisajismo para crear ambientes confortables y funcionales."},
                      {perfil:"Seleccionar y aplicar, materiales y sistemas constructivos que respondan a una continua calidad e innovación."},
                      {perfil:"Gestionar desarrollos urbanos de manera estratégica y sustentable."},
                      {perfil:"Operar planes de desarrollo urbano con una visión de sustentabilidad y mejora de la calidad de vida."},
                      {perfil:"Seleccionar y diseñar estructuras, instalaciones y sistemas constructivos sustentables."},
                      {perfil:"Administrar el proceso constructivo de las obras urbano-arquitectónicas, con base en la legislación vigente."},
                      {perfil:"Asesorar a los sectores público y privado, en la valoración y conservación del patrimonio, re-arquitectura, proyectos de inversión inmobiliaria y legislación urbana."},
                      {perfil:"Liderar organismos y grupos inter y multidisciplinarios para la integración de proyectos urbano - arquitectónicos."},
                      {perfil:"Actuar de manera responsable y ética con la sociedad y su entorno."},
                      {perfil:"Desarrollar los valores de responsabilidad, orden y disciplina así como el entusiasmo por continuar su crecimiento personal y profesional."},
      ],
      reticula: 'http://tecmm.edu.mx/reticulasCarreras/Reticula-Arquitectura.pdf',
      planEstudios: 'http://tecmm.edu.mx/planesEstudioCarreras/ARQUITECTURA_ARQU-2010-204.pdf',
      folleto: 'http://tecmm.edu.mx/folletosCarreras/newFolletos/Folleto-Arquitectura.pdf',
      convocatoria: "",
    };

    carrerasArray[5]={
      name: 'Ingenieria-Civil',
      card:'https://tecmm.edu.mx/web-tsj-designs/ofertaAcademica/Carreras-09.jpg',
      portada: 'https://tecmm.edu.mx/web-tsj-designs/portadasCarreras/Carreras-Banners-19.jpg',
      youtube:'7RJfYbbp34c',
      videoInstitucional: "https://www.tecmm.edu.mx/videos/carreras/20_civil.mp4",
      campus: 'LAGOS DE MORENO, TEQUILA, TEPATITLÁN, ZAPOPAN',
      campusNoAccent: 'LAGOS DE MORENO, TEQUILA, TEPATITLAN, ZAPOPAN',
      objetivo: "Formar ingenieros civiles de manera integral, con visión humana, analítica, creativa, y emprendedora, capaces de identificar y resolver problemas con eficiencia, eficacia y pertinencia, mediante la planeación, diseño, construcción, operación y conservación de obras de infraestructura, en el marco de la globalización, la sustentabilidad y la calidad, contribuyendo al desarrollo de la sociedad.",
      perfilIngreso : [
                      {perfil:"Los aspirantes a ingresar al programa de Ingeniería civil deberán haber cursado y aprobado su formación de bachillerato con inclinación a las Ciencias Físico Matemáticas, con el fin de garantizar previamente la asimilación de contenidos programáticos propios de la Ingeniería Civil."},
                      {perfil:"Aplica las matemáticas y física, pensamiento reflexivo y critico; resuelve modelos matemáticos considerando diferentes criterios y enfoques, interpreta los resultados a través de las matemáticas y los discute con los paradigmas establecidos, interpreta mapas, gráficas y diagramas con diferentes nomenclaturas."},
                      {perfil:"Cuenta con responsabilidad, honestidad, paciencia, ética, amistad, trato cordial, relaciones armónicas y comunicación. Respeto a las personas sin distingo de condición social y económica, compromiso de trabajo con orden y disciplina y trabajo colaborativo."},
      ],
      perfilEgreso : [
                      {perfil:"Planear, proyectar, diseñar, construir, operar y conservar obras hidráulicas y sanitarias, sistemas estructurales, vías terrestres, edificación y obras de infraestructura urbana e industrial."},
                      {perfil:"Dirigir y participar en estudios para determinar la factibilidad ambiental, económica, técnica y financiera de los proyectos de obras civiles."},
                      {perfil:"Formular y ejecutar proyectos de investigación y desarrollo tecnológico en el ámbito de la Ingeniería Civil."},
                      {perfil:"Innovar, crear, generar, adaptar y aplicar nuevas tecnologías en los estudios, proyectos y construcción de obras civiles, aplicando métodos científicos."},
                      {perfil:"Optimizar el uso de los recursos en los procesos constructivos de obras civiles."},
                      {perfil:"Emplear técnicas de control de calidad en los materiales y procesos constructivos."},
                      {perfil:"Utilizar Tecnologías de la Información y Comunicación (TIC’s), software y herramientas electrónicas para la Ingeniería Civil."},
                      {perfil:"Emprender proyectos productivos pertinentes."},
      ],
      reticula: 'http://tecmm.edu.mx/reticulasCarreras/Reticula-Ingenieria-Civil.pdf',
      planEstudios: 'http://tecmm.edu.mx/planesEstudioCarreras/INGENIERA_CIVIL_ICIV-2010-208.pdf',
      folleto: 'http://tecmm.edu.mx/folletosCarreras/newFolletos/Folleto-Ingenieria-Civil.pdf',
      convocatoria: "",
    };

    carrerasArray[6]={
      name: 'Contador-Publico',
      card:'https://tecmm.edu.mx/web-tsj-designs/ofertaAcademica/Carreras-10.jpg',
      portada: 'https://tecmm.edu.mx/web-tsj-designs/portadasCarreras/Carreras-Banners-05.jpg',
      youtube:'CziPXTipdvo',
      videoInstitucional: "https://www.tecmm.edu.mx/videos/carreras/17_contador_publico.mp4",
      campus: 'ZAPOTLANEJO, LA HUERTA',
      campusNoAccent: 'ZAPOTLANEJO, LA HUERTA',
      objetivo: "Formar profesionales competitivos, capaces de diseñar, establecer, aplicar, controlar y evaluar sistemas de información financiera, fiscal y administrativa, para la toma de decisiones de las entidades económicas nacionales e internacionales, con una actitud ética, crítica, emprendedora y de liderazgo, a través de la investigación y el uso de la tecnología de la información y la comunicación, fomentando el desarrollo sustentable.",
      perfilIngreso : [
                      {perfil:"Compromiso Social."},
                      {perfil:"Actitud Positiva hacia el cambio."},
                      {perfil:"Juicio y Sentido Común."},
                      {perfil:"Capacidad de Análisis y Síntesis."},
                      {perfil:"Interés por el Cálculo."},
                      {perfil:"Capacidad de Observación."},
                      {perfil:"Capacidad para expresarse en forma oral y escrita."},
                      {perfil:"Liderazgo."},
                      {perfil:"Trabajo en Equipo."},
                      {perfil:"Uso de las Tic´s."},
                      {perfil:"Sentido de la Gestión de Recursos."},
                      {perfil:"Facilidad en la Toma de Decisiones."},
      ],
      perfilEgreso : [
                      {perfil:"Diseñar, implantar, controlar, evaluar, asesorar e innovar sistemas de información financiera, administrativa, fiscal y de auditoría en entidades económicas."},
                      {perfil:"Analizar y generar información financiera con apego a las Normas de Información Financiera, nacionales e internacionales, para la toma de decisiones."},
                      {perfil:"Auditar sistemas financieros, fiscales y administrativos de las entidades económicas con apego a las Normas y Procedimientos de Auditoría y Normas para Atestiguar."},
                      {perfil:"Aplicar el marco legal pertinente a las características y necesidades de la entidad económica dentro del campo profesional."},
                      {perfil:"Administrar estratégicamente los recursos de las entidades económicas con visión emprendedora y competitiva."},
                      {perfil:"Conocer y cumplir el código de ética profesional."},
                      {perfil:"Utilizar las tecnologías de Información y comunicación para eficientar los procesos y la toma de decisiones."},
                      {perfil:"Desarrollar investigación asumiendo una actitud de liderazgo, compromiso y servicio con su entorno social."},
                      {perfil:"Elaborar y evaluar proyectos de inversión de acuerdo a las características y necesidades del entorno y propiciar la generación de empresas"},
                      {perfil:"Asumir actitud de compromiso y servicio con su entorno social y el medio ambiente."},
                      {perfil:"Formar y promover el desarrollo de grupos de trabajo interdisciplinarios para el logro de los resultados de las entidades con un sentido de responsabilidad social y visión integradora."},
                      {perfil:"Conocer y proponer estrategias de mercadotecnia que permitan alcanzar los objetivos de la empresa."},
                      {perfil:"Aplicar métodos de análisis de información financiera para determinar las mejores alternativas de inversión y financiamiento."},
      ],
      reticula: 'http://tecmm.edu.mx/reticulasCarreras/Reticula-Contador-Publico.pdf',
      planEstudios: 'http://tecmm.edu.mx/planesEstudioCarreras/CONTADOR_PBLICO_COPU-2010-205.pdf',
      folleto: 'http://tecmm.edu.mx/folletosCarreras/Folleto-Contador-Publico.pdf',
      convocatoria: "",
    };

    carrerasArray[7]={
      name: 'Ingenieria-Electromecanica',
      card:'https://tecmm.edu.mx/web-tsj-designs/ofertaAcademica/Carreras-11.jpg',
      portada: 'https://tecmm.edu.mx/web-tsj-designs/portadasCarreras/Carreras-Banners-12.jpg',
      youtube:'gYBLRrY3Pfg',
      videoInstitucional: "https://www.tecmm.edu.mx/videos/carreras/21_electromecanica.mp4",
      campus: 'ARANDAS, COCULA, LAGOS DE MORENO, PUERTO VALLARTA, SAN JUAN DE LOS LAGOS, TAMAZULA, TEQUILA, ZAPOPAN',
      campusNoAccent: 'ARANDAS, COCULA, LAGOS DE MORENO, PUERTO VALLARTA, SAN JUAN DE LOS LAGOS, TAMAZULA, TEQUILA, ZAPOPAN',
      objetivo: "Formar profesionistas de excelencia en ingeniería electromecánica, con actitud emprendedora, con liderazgo y capacidad de: analizar, diagnosticar, diseñar, seleccionar, instalar, administrar, mantener e innovar sistemas electromecánicos, en forma eficiente, segura y económica. Considerando las normas y estándares nacionales e internacionales para fomentar el desarrollo sustentable con plena conciencia ética, humanística y social.",
      perfilIngreso : [
                      {perfil:"El aspirante a esta carrera ha de contar con una buena preparación en las áreas de física, matemáticas, diseño."},
                      {perfil:"Debe tener inclinación para los razonamientos analíticos, capacidad de abstracción."},
                      {perfil:"Creatividad, iniciativa e interés por aplicar la ciencia y la tecnología para satisfacción de las necesidades de la sociedad."},
      ],
      perfilEgreso : [
                      {perfil:"Formular, gestionar y evaluar proyectos de ingeniería relacionados con sistemas y dispositivos en el área electromecánica, proponiendo soluciones con tecnologías de vanguardia, en el marco del desarrollo sustentable."},
                      {perfil:"Diseñar e implementar sistemas y dispositivos electromecánicos, utilizando estrategias para el uso eficiente de la energía en los sectores productivo y de servicios apegado a normas y acuerdos nacionales e internacionales."},
                      {perfil:"Diseñar e implementar estrategias y programas para el control y/o automatización de los procesos productivos y los dispositivos en los sistemas electromecánicos."},
                      {perfil:"Proyectar, gestionar, implementar y controlar actividades de instalación y operación de los sistemas electromecánicos."},
                      {perfil:"Formular administrar y supervisar programas de mantenimiento para la continuidad y optimización de procesos productivos, considerando el cuidado del medio ambiente."},
                      {perfil:"Colaborar en proyectos de investigación para el desarrollo tecnológico, en el área de electromecánica."},
                      {perfil:"Ejercer actitudes de liderazgo y de trabajo en grupo para la toma de decisiones a partir de un sentido ético profesional."},
                      {perfil:"Desarrollar la actitud emprendedora mediante la creación e incubación de empresas, innovando en productos y servicios del sector electromecánico."},
                      {perfil:"Aplicar herramientas computacionales de acuerdo a las tecnologías de vanguardia, para el diseño, simulación y operación de sistemas electromecánicos acordes a la demanda del sector industrial."},
                      {perfil:"Interpretar comprender y comunicar ideas, textos y documentos de distinta índole en un segundo idioma."},
      ],
      reticula: 'http://tecmm.edu.mx/reticulasCarreras/Reticula-Ingenieria-Electromecanica.pdf',
      planEstudios: 'http://tecmm.edu.mx/planesEstudioCarreras/INGENIERA_ELECTROMECNICA__IEME-2010-210.pdf',
      folleto: 'http://tecmm.edu.mx/folletosCarreras/newFolletos/Folleto-Ingenieria-Electromecanica.pdf',
      convocatoria: "",
    };

    carrerasArray[8]={
      name: 'Ingenieria-Electronica',
      card:'https://tecmm.edu.mx/web-tsj-designs/ofertaAcademica/Carreras-12.jpg',
      portada: 'https://tecmm.edu.mx/web-tsj-designs/portadasCarreras/Carreras-Banners-20.jpg',
      youtube:'3u5VE2SSBoU',
      videoInstitucional: "https://www.tecmm.edu.mx/videos/carreras/22_electronica.mp4",
      campus: 'ZAPOPAN',
      campusNoAccent: 'ZAPOPAN',
      objetivo: "Formar profesionistas con competencias profesionales para diseñar, modelar, implementar, operar, integrar, mantener, instalar, administrar, innovar y transferir tecnología electrónica existente y emergente en proyectos interdisciplinarios, a nivel nacional e internacional, para resolver problemas y atender las necesidades de su entorno con ética, actitud emprendedora, creativa, analítica y comprometidos con el desarrollo sustentable.",
      perfilIngreso : [
                      {perfil:"Una preparación previa en electrónica o en alguna rama tecnológica afín."},
                      {perfil:"Una gran curiosidad por conocer el principio de funcionamiento del entorno que le rodea."},
                      {perfil:"Un espíritu creativo manifestado por la constante iniciativa de construir, proponer y cambiar así como resolver problemas."},
                      {perfil:"Vocación de servicio a la sociedad y cuidado del medio ambiente que le rodea."},
                      {perfil:"Capacidad de ejercer las habilidades propias del razonamiento lógico y la capacidad de análisis, síntesis, contrastación y aplicación del conocimiento."},
                      {perfil:"Habilidad para el manejo de instrumental científico y/o técnico."},
                      {perfil:"Una buena percepción del espacio y la capacidad para representar sus ideas, auxiliándose de dibujos y modelos tridimensionales."},
                      {perfil:"Hábitos, estrategias y comprensión de lectura fluida, poseer la habilidad para expresarse correcta y eficientemente, tanto en forma oral como escrita, así como interpretar los mensajes recibidos en ambas formas."},
      ],
      perfilEgreso : [
                      {perfil:"Diseñar, analizar y construir equipos y/o sistemas electrónicos para la solución de problemas en el entorno profesional, aplicando normas técnicas y estándares nacionales e internacionales."},
                      {perfil:"Crear, innovar y transferir tecnología aplicando métodos y procedimientos en proyectos de ingeniería electrónica, tomando en cuenta el desarrollo sustentable del entorno."},
                      {perfil:"Promover y participar en programas de mejora continua aplicando normas de calidad en toda empresa."},
                      {perfil:"Planear, organizar, dirigir y controlar actividades de instalación, actualización, operación y mantenimiento de equipos y/o sistemas electrónicos."},
                      {perfil:"Aplicar las nuevas tecnologías de la información y de la comunicación, para la adquisición y procesamiento de datos."},
                      {perfil:"Desarrollar y administrar proyectos de investigación y/o desarrollo tecnológico."},
                      {perfil:"Ejercer la profesión de manera responsable, ética y dentro del marco legal."},
                      {perfil:"Asumir las implicaciones de su desempeño profesional en el entorno político, social, económico y cultural."},
                      {perfil:"Comunicarse con efectividad en forma oral y escrita en el ámbito profesional tanto en su idioma como en un idioma extranjero."},
                      {perfil:"Ejercer actitudes emprendedoras, de liderazgo y desarrollar habilidades para la toma de decisiones en su ámbito profesional."},
                      {perfil:"Comprometer su formación integral permanente y de actualización profesional continua, de manera autónoma."},
                      {perfil:"Dirigir y participar en equipos de trabajo interdisciplinario y multidisciplinario en contextos nacionales e internacionales."},
                      {perfil:"Capacitar y actualizar en las diversas áreas de aplicación de ingeniería electrónica."},
                      {perfil:"Simular modelos que permitan predecir el comportamiento de sistemas electrónicos empleando plataformas computacionales."},
                      {perfil:"Seleccionar y operar equipo de medición y prueba."},
                      {perfil:"Utilizar lenguaje de descripción de hardware y programación de microcontroladores en el diseño de sistemas digitales para su aplicación en la resolución de problemas."},
                      {perfil:"Resolver problemas en el sector productivo mediante la automatización, instrumentación y control."},
                      {perfil:"Desarrollar aplicaciones en un lenguaje de programación de alto nivel para la solución de problemas relacionados con las diferentes disciplinas en el área."},
      ],
      reticula: 'http://tecmm.edu.mx/reticulasCarreras/Reticula-Ingenieria-Electronica.pdf',
      planEstudios: 'http://tecmm.edu.mx/planesEstudioCarreras/INGENIERA_ELECTRNICA_IELC-2010-211.pdf',
      folleto: 'http://tecmm.edu.mx/folletosCarreras/newFolletos/Folleto-Ingenieria-Electronica.pdf',
      convocatoria: "",
    };

    carrerasArray[9]={
      name: 'Ingenieria-en-Energias-Renovables',
      card:'https://tecmm.edu.mx/web-tsj-designs/ofertaAcademica/Carreras-15.jpg',
      portada: 'https://tecmm.edu.mx/web-tsj-designs/portadasCarreras/Carreras-Banners-06.jpg',
      youtube:'FRMgk8ZD9JY',
      videoInstitucional: "FRMgk8ZD9JY",
      campus: 'TEQUILA',
      campusNoAccent: 'TEQUILA',
      objetivo: "Formar recursos humanos competentes para intervenir profesionalmente en proyectos, estrategias y acciones de generación, transformación y almacenamiento de energía proveniente de fuentes renovables, así como de uso eficiente de la energía, en el marco de un desarrollo sustentable.",
      perfilIngreso : [
                      {perfil:"Capacidad de observación, análisis y síntesis."},
                      {perfil:"Habilidad de comunicación oral y escrita."},
                      {perfil:"Habilidad para trabajar en equipo."},
                      {perfil:"Respeto y aceptación por la diversidad cultural en un entorno de inclusión y equidad. "},
                      {perfil:"Conocimiento básico de Química, Física y Matemáticas. "},
                      {perfil:"Habilidad para comprender problemas energéticos  y sus impactos en la sociedad. "},
                      {perfil:"Ética y respeto al entorno natural."},
                      {perfil:"Compromiso por mejorar el entorno natural y social."},
                      {perfil:"Disposición para solucionar problemas ambientales mediante la aplicación del método científico. "},
                      {perfil:"Administración y optimización de los recursos energéticos."},
      ],
      perfilEgreso : [
                      {perfil:"Formular, evaluar y gestionar proyectos de desarrollo de ingeniería relacionados con gestionar las fuentes renovables de energía, en el marco del desarrollo sustentable."},
                      {perfil:"Diseñar e implementar estrategias para el uso eficiente de la energía de transformación y de servicios."},
                      {perfil:"Diseñar, implementar y gestionar actividades de instalación, operación y mantenimiento de sistemas ingenieriles utilizados para la transformación y almacenamiento de la energía proveniente de fuentes renovables."},
                      {perfil:"Colaborar en proyectos de investigación, desarrollo e innovación tecnológicos, relacionados con la energía proveniente de fuentes renovables."},
                      {perfil:"Identificar y evaluar el recurso energético renovable disponible en el entorno."},
                      {perfil:"Ejercer actitudes emprendedoras y de liderazgo en la toma de decisiones en su ámbito profesional."},
      ],
      reticula: 'http://tecmm.edu.mx/reticulasCarreras/Reticula-Ingenieria-en-Energias-Renovables.pdf',
      planEstudios: 'http://tecmm.edu.mx/planesEstudioCarreras/INGENIERA_EN_ENERGAS_RENOVABLES_IENR-2010-217.pdf',
      folleto: 'http://tecmm.edu.mx/folletosCarreras/Folleto-Ingenieria-en-Energias-Renovables.pdf',
      convocatoria: "",
    };

    carrerasArray[10]={
      name: 'Gastronomia',
      card:'https://tecmm.edu.mx/web-tsj-designs/ofertaAcademica/Carreras-14.jpg',
      portada: 'https://tecmm.edu.mx/web-tsj-designs/portadasCarreras/Carreras-Banners-13.jpg',
      youtube:'NJzvlA8rfMw',
      videoInstitucional: "https://www.tecmm.edu.mx/videos/carreras/18_gastronomia.mp4",
      campus: 'CHAPALA, PUERTO VALLARTA, ZAPOPAN',
      campusNoAccent: 'CHAPALA, PUERTO VALLARTA, ZAPOPAN',
      objetivo: "Formar integralmente profesionales de gastronomía, líderes, creativos, con ética, responsabilidad social, vocación de servicio y visión estratégica; capaces de diseñar, desarrollar y aplicar el arte y técnicas culinarias; así como los conocimientos científicos en la innovación de la gastronomía y gestionar empresas del sector de alimentos y bebidas que contribuyan al desarrollo sustentable de la región.",
      perfilIngreso : [
                      {perfil:"Aplica los principios básicos de la administración y la contabilidad en las actividades propias de las organizaciones de alimentos y bebidas, para el mejor manejo de los ingresos y egresos."},
                      {perfil:"Realiza actividades específicas en el área de ventas y servicios al cliente tales como la creación de programas de comercialización de  servicios de alimentos y bebidas."},
                      {perfil:"Aplica los conocimientos mercadológicos para una mejor comercialización de los diferentes productos y servicios que ofrece una empresa de alimentos y bebidas."},
                      {perfil:"Aplica los conocimientos administrativos básicos en empresas que prestan servicios, compran, transforman y venden alimentos y bebidas."},
                      {perfil:"Comprenden el comportamiento físico-químico de los alimentos para la elaboración, conservación, innovación y presentación de los mismos."},
                      {perfil:"Diseñar estructuras administrativas del ámbito gastronómico acorde a los requisitos, aspectos legales, económicos, financieros y comerciales."},
                      {perfil:"Solucionar problemas y tomar decisiones para el óptimo cumplimiento de las actividades gastronómicas."},
                      {perfil:"Elaborar planes de negocios y establecer controles de calidad de alimentos y bebidas."},
                      {perfil:"Organizar y administrar recursos humanos y técnicos en los establecimientos de alimentos y bebidas."},
                      {perfil:"Integrar, organizar, planear y administrar su propio negocio."},
                      {perfil:"Establecer sistemas de información y control en empresas gastronómicas"},
      ],
      perfilEgreso : [
                      {perfil:"Aplicar los principios básicos de la administración y la contabilidad en las actividades propias de las organizaciones de alimentos y bebidas, para el mejor manejo de los ingresos y egresos."},
                      {perfil:"Realizar actividades específicas en el área de ventas y servicio al cliente tales como la creación de programas de comercialización de servicios de alimentos y bebidas."},
                      {perfil:"Aplicar los conocimientos mercadológicos para una mejor comercialización de los diferentes productos y servicios que ofrece una empresa de alimentos y bebidas."},
                      {perfil:"Aplicar los conocimientos administrativos básicos en empresas que prestan servicios, compran, transforman y venden alimentos y bebidas"},
                      {perfil:"Comprender el comportamiento físico-químico de los alimentos para mejorar la elaboración, conservación, innovación y presentación de los mismos."},
                      {perfil:"Diseñar estructuras administrativas del ámbito gastronómico acorde a los requisitos, aspectos legales, económicos, financieros y comerciales."},
                      {perfil:"Solucionar problemas y tomar decisiones para el óptimo cumplimiento de las actividades gastronómicas."},
                      {perfil:"Elaborar planes de negocios y establecer controles de calidad de alimentos y bebidas."},
                      {perfil:"Organizar y administrar recursos humanos y técnicos en los establecimientos de alimentos y bebidas."},
                      {perfil:"Integrar, organizar, planear y administrar su propio negocio."},
                      {perfil:"Establecer sistemas de información y control en empresas gastronómicas."},
                      {perfil:"Generar estrategias para el logro de los objetivos organizacionales."},
                      {perfil:"Identificar el origen y evolución de la gastronomía mediante el estudio de su desarrollo histórico."},
                      {perfil:"Implementar las diferentes normas nacionales e internacionales de seguridad e higiene, operando programas para el manejo higiénico de los alimentos."},
                      {perfil:"Diseñar, implementar y evaluar los procedimientos de recepción, almacenaje y elaboración de alimentos y bebidas."},
                      {perfil:"Diseñar e implementar cartas y menús."},
                      {perfil:"Diferenciar el origen y procedimientos de elaboración de las principales bebidas alcohólicas y no alcohólicas."},
                      {perfil:"Propiciar la creación y desarrollo de platillos de vanguardia analizando la composición de los alimentos con diversas técnicas y métodos de preparación de la cocina nutricional."},
      ],
      reticula: 'http://tecmm.edu.mx/reticulasCarreras/Reticula-Gastronomia.pdf',
      planEstudios: 'http://tecmm.edu.mx/planesEstudioCarreras/GASTRONOMA_GAST-2010-215.pdf',
      folleto: 'http://tecmm.edu.mx/folletosCarreras/newFolletos/Folleto-Gastronomia.pdf',
      convocatoria: "",
    };

    carrerasArray[11]={
      name: 'Ingenieria-en-Gestion-Empresarial',
      card:'https://tecmm.edu.mx/web-tsj-designs/ofertaAcademica/Carreras-13.jpg',
      portada: 'https://tecmm.edu.mx/web-tsj-designs/portadasCarreras/Carreras-Banners-24.jpg',
      youtube:'vLiViO4OsFk',
      videoInstitucional: "https://www.tecmm.edu.mx/videos/carreras/26_gestion.mp4",
      campus: 'ARANDAS, CHAPALA, COCULA, EL GRULLO, LAGOS DE MORENO, TENAMAXTLÁN, PUERTO VALLARTA, SAN JUAN DE LOS LAGOS, TEQUILA, TOMATLÁN, ZAPOPAN',
      campusNoAccent: 'ARANDAS, CHAPALA, COCULA, EL GRULLO, LAGOS DE MORENO, TENAMAXTLAN, PUERTO VALLARTA, SAN JUAN DE LOS LAGOS, TEQUILA, TOMATLAN, ZAPOPAN',
      objetivo: "Formar integralmente profesionales que contribuyan a la gestión de empresas e innovación de procesos; así como al diseño, implementación y desarrollo de sistemas estratégicos de negocios, optimizando recursos en un entorno global, con ética y responsabilidad social.",
      perfilIngreso : [
                      {perfil:"Capacidad de observación, análisis y síntesis."},
                      {perfil:"Habilidad verbal, facilidad de expresión, sentido de organización."},
                      {perfil:"Habilidad para comprender problemas sociales."},
                      {perfil:"Objetividad y don de mando."},
                      {perfil:"Habilidad de persuasión."},
                      {perfil:"Habilidad numérica."},
                      {perfil:"Habilidad para relacionarse con las personas, sociabilidad."},
      ],
      perfilEgreso : [
                      {perfil:"Desarrollar y aplicar habilidades directivas y la ingeniería en el diseño, creación, gestión, desarrollo, fortalecimiento e innovación de las organizaciones, con una orientación sistémica y sustentable para la toma de decisiones en forma efectiva."},
                      {perfil:"Diseñar e innovar estructuras administrativas y procesos, con base en las necesidades de las organizaciones para competir eficientemente en mercados globales."},
                      {perfil:"Gestionar eficientemente los recursos de la organización con visión compartida, con el fin de suministrar bienes y servicios de calidad"},
                      {perfil:"Aplicar métodos cuantitativos y cualitativos para el análisis e interpretación de datos y modelado de sistemas, en los procesos organizacionales para la mejora continua, atendiendo estándares de calidad mundial."},
                      {perfil:"Diseñar, evaluar y emprender nuevos negocios y proyectos empresariales, que promuevan el desarrollo sustentable y la responsabilidad social, en un mercado competitivo."},
                      {perfil:"Diseñar e implementar estrategias de mercadotecnia basadas en información recopilada de fuentes primarias y secundarias del consumidor o usuario de algún producto, de acuerdo a oportunidades y amenazas del mercado."},
                      {perfil:"Establecer programas para el fortalecimiento de la seguridad e higiene en las organizaciones."},
                      {perfil:"Gestionar sistemas integrales de calidad, ejerciendo un liderazgo efectivo y un compromiso ético, aplicando las herramientas básicas de la ingeniería."},
                      {perfil:"Interpretar y aplicar normas legales que incidan en la creación y desarrollo de las organizaciones."},
                      {perfil:"Integrar, dirigir y desarrollar equipos de trabajo para la mejora continua y el crecimiento integral de las organizaciones."},
                      {perfil:"Analizar e interpretar la información financiera para detectar oportunidades de mejora e inversión en un mundo global, que incidan en la rentabilidad del negocio."},
                      {perfil:"Utilizar las nuevas tecnologías de información en la organización, para optimizar los procesos de comunicación y eficientar la toma de decisiones."},
                      {perfil:"Propiciar el desarrollo del capital humano, para la realización de los objetivos organizacionales, dentro de un marco ético y un contexto multicultural."},
                      {perfil:"Aplicar métodos de investigación para desarrollar e innovar sistemas, procesos y productos en las diferentes dimensiones de la organización."},
                      {perfil:"Gestionar la cadena de suministros de las organizaciones con un enfoque orientado a procesos."},
                      {perfil:"Analizar e interpretar la economía global para facilitar la toma de decisiones en la organización."},
      ],
      reticula: 'http://tecmm.edu.mx/reticulasCarreras/Reticula-Ingenieria-en-Gestion-Empresarial.pdf',
      planEstudios: 'http://tecmm.edu.mx/planesEstudioCarreras/INGENIERA__EN_GESTIN_EMPRESARIAL_IGEM-2009-201.pdf',
      folleto: 'http://tecmm.edu.mx/folletosCarreras/newFolletos/Folleto-Ingenieria-en-Gestion-Empresarial.pdf',
      convocatoria: "",
    };

    carrerasArray[12]={
      name: 'Ingenieria-Industrial',
      card:'https://tecmm.edu.mx/web-tsj-designs/ofertaAcademica/Carreras-16.jpg',
      portada: 'https://tecmm.edu.mx/web-tsj-designs/portadasCarreras/Carreras-Banners-07.jpg',
      youtube:'rcG4DQh9X2c',
      videoInstitucional: "https://www.tecmm.edu.mx/videos/carreras/32_industrial.mp4",
      campus: 'ARANDAS, CHAPALA, COCULA, EL GRULLO, LAGOS DE MORENO, MASCOTA, SAN JUAN DE LOS LAGOS, TEQUILA, TALA, TOMATLÁN, TEPATITLÁN, ZAPOPAN, ZAPOTLANEJO',
      campusNoAccent: 'ARANDAS, CHAPALA, COCULA, EL GRULLO, LAGOS DE MORENO, MASCOTA, TEQUILA, TALA, TOMATLAN, TEPATITLAN, ZAPOPAN, ZAPOTLANEJO, SAN JUAN DE LOS LAGOS',
      objetivo: "Formar profesionistas, en el campo de la ingeniería industrial, líderes, creativos y emprendedores con visión sistémica, capacidad analítica y competitiva que les permita diseñar, implementar, mejorar, innovar, optimizar y administrar sistemas de producción de bienes y servicios en un entorno global, con enfoque sustentable, ético y comprometido con la sociedad.",
      perfilIngreso : [
                      {perfil:"Tener una amplia visión de la organización en la que desempeña su actividad profesional."},
                      {perfil:"Ser capaz de aplicar una amplia gama de herramientas para determinar las mejores combinaciones de recursos humanos, naturales, materiales, tecnológicos, financieros, etc."},
                      {perfil:"una persona curiosa y observadora, crítica, con capacidad de análisis y abstracción, con inquietud por la mejora continua y un gusto por tecnologías de diferentes tipos."},
                      {perfil:"alto sentido de la responsabilidad, motivación para realizar trabajo innovador y creativo, con un serio impacto profesional y social e interés en la superación y el desarrollo personal y profesional."},
                      {perfil:"hacia el trabajo orientado al logro de metas claras y realizables e interés en la aplicación de conocimiento científico, técnico, matemático y organizacional para la resolución de problemas."},
      ],
      perfilEgreso : [
                      {perfil:"Analizar, diseñar y gestionar sistemas productivos desde la provisión de insumos hasta la entrega de bienes y servicios, integrándolos con eficacia y eficiencia."},
                      {perfil:"Conocer, seleccionar y aplicar tecnologías para optimizar procesos productivos."},
                      {perfil:"Diseñar, implementar y administrar sistemas de mantenimiento para eficientar la operación de las instalaciones y equipos."},
                      {perfil:"Implementar sistemas de gestión de calidad para satisfacer los requerimientos del cliente y partes interesadas."},
                      {perfil:"Utilizar los instrumentos de medición requeridos en la certificación y/o acreditación con las normas vigentes."},
                      {perfil:"Interpretar e implementar estrategias y métodos estadísticos en los procesos organizacionales para la mejora continua."},
                      {perfil:"Seleccionar y adecuar modelos de calidad y diseño de experimentos en procesos organizacionales para su optimización."},
                      {perfil:"Gestionar sistemas de seguridad, salud ocupacional y protección al medio ambiente, en industrias de producción y de servicios."},
                      {perfil:"Identificar necesidades de su entorno y desarrollar investigación aplicada para crear e innovar bienes y servicios."},
                      {perfil:"Crear y mejorar productos de alto valor agregado bajo los principios de productividad y competitividad."},
                      {perfil:"Seleccionar e implementar tecnologías de información y comunicación dentro de la empresa."},
                      {perfil:"Participar en proyectos de transferencia, desarrollo y adaptación de tecnologías en los sistemas productivos."},
                      {perfil:"Diseñar, implementar y mejorar sistemas y estaciones de trabajo considerando factores ergonómicos para optimizar la producción."},
                      {perfil:"Participar en la estandarización de operaciones para la transferencia y adaptación de los sistemas productivos."},
                      {perfil:"Manejar y aplicar las normas y estándares en el análisis de operaciones de los sistemas de producción."},
                      {perfil:"Emprender e incubar empresas con base tecnológica, que promueva el desarrollo socioeconómico de una región, así como su constitución legal."},
                      {perfil:"Formular, evaluar y gestionar proyectos de inversión, que le permita emprender la creación de unidades productivas de bienes y servicios bajo criterios de competitividad y sustentabilidad."},
                      {perfil:"Tomar decisiones para la mejora de sistemas productivos y de servicios, fundamentadas en planteamientos y modelos analíticos."},
      ],
      reticula: 'http://tecmm.edu.mx/reticulasCarreras/Reticula-Ingenieria-Industrial.pdf',
      planEstudios: 'http://tecmm.edu.mx/planesEstudioCarreras/INGENIERA_INDUSTRIAL_IIND-2010-227.pdf',
      folleto: 'http://tecmm.edu.mx/folletosCarreras/Folleto-Ingenieria-Industrial.pdf',
      convocatoria: "",
    };

    carrerasArray[13]={
      name: 'Ingenieria-en-Industrias-Alimentarias',
      card:'https://tecmm.edu.mx/web-tsj-designs/ofertaAcademica/Carreras-17.jpg',
      portada: 'https://tecmm.edu.mx/web-tsj-designs/portadasCarreras/Carreras-Banners-14.jpg',
      youtube:'tbmEoE6wSzo',
      videoInstitucional: "https://www.tecmm.edu.mx/videos/carreras/27_alimentarias.mp4",
      campus: 'ARANDAS, LA HUERTA, LAGOS DE MORENO, TAMAZULA',
      campusNoAccent: 'ARANDAS, LA HUERTA, LAGOS DE MORENO, TAMAZULA',
      objetivo: "Formar profesionistas con bases sólidas científicas, tecnológicas, con actitudes éticas y aptitudes; capaces de diseñar, administrar, desarrollar, controlar e innovar sistemas de producción en la industria alimentaria, orientados de manera sustentable e integral en el ámbito regional, nacional e internacional.",
      perfilIngreso : [
                    {perfil:"Interés por la gestión de proyectos orientados a la producción y transformación de alimentos "},
                    {perfil:"Interés por el uso y transformación de materias primas provenientes del sector agropecuario"},
                    {perfil:"Uso de tecnologías de la información y la comunicación "},
                    {perfil:"Habilidad de observación"},
                    {perfil:"Adaptabilidad "},
                    {perfil:"Comunicarse correctamente de forma oral y escrita"},
                    {perfil:"Analizar, sintetizar y evaluar información "},
                    {perfil:"Integrar, aplicar y transferir los conocimientos adquiridos"},
                    {perfil:"Trabajar de manera individual y en equipo "},
                    {perfil:"Hábito de estudio"},
                    {perfil:"Pensamiento lógico matemático"},
                    {perfil:"Habilidad manual para el uso de equipo de laboratorio "},
                    {perfil:"Actitud crítica, autocrítica y de investigación hacia la naturaleza y el entorno social de su futura práctica profesional "},
                    {perfil:"Curiosidad intelectual orientada a la búsqueda de soluciones"},
                    {perfil:"Interés por conocer y participar en la solución de la problemática nacional"},
                    {perfil:"Actitud creadora para transformar la realidad mediante programas y proyectos de innovación tecnológica"},
                    {perfil:"Tener espíritu de servicio"},
                    {perfil:"Interés por el mejoramiento social, cultural y económico"},
      ],
      perfilEgreso : [
                      {perfil:"Diseñar, crear, aplicar, optimizar, analizar y evaluar, los sistemas de producción industrial de alimentos."},
                      {perfil:"Crear y utilizar tecnología sustentable en la industria alimentaria, reduciendo el impacto ambiental."},
                      {perfil:"Planear y operar industrias alimentarias en un enfoque de sustentabilidad."},
                      {perfil:"Desarrollar y aplicar las técnicas tradicionales, emergentes y procedimientos microbiológicos para la conservación de los alimentos, de acuerdo a sus propiedades funcionales."},
                      {perfil:"Valorar los materiales para el diseño del empaque y embalaje de acuerdo a las condiciones de procesamiento y características físicas y químicas de los alimentos."},
                      {perfil:"Inspeccionar, evaluar y controlar la inocuidad en alimentos, equipo e instalaciones de proceso conservando la calidad, seguridad y trazabilidad de los mismos."},
                      {perfil:"Usar conceptos básicos de la probabilidad y control estadístico para la resolución inherentes a la calidad del producto."},
                      {perfil:"Diseñar, desarrollar, gestionar y emprender sistemas de calidad, clase mundial en el sector alimentario."},
                      {perfil:"Inspeccionar, evaluar y controlar un proceso de producción o producto terminado mediante la aplicación de técnicas de evaluación fisicoquímicas y análisis instrumental."},
                      {perfil:"Adoptar, adaptar, transferir e innovar tecnologías para la transformación de alimentos."},
                      {perfil:"Analizar, desarrollar, emprender y evaluar productos innovadores con atributos nutritivos, funcionales y nutracéuticos."},
                      {perfil:"Desarrollar tecnologías para la generación de energías alternativas a partir de productos y subproductos alimenticios."},
                      {perfil:"Emprender, formular, ejecutar y evaluar planes de negocios; consultoría, y peritajes orientados a la creación de empresas."},
                      {perfil:"Diseñar e impartir cursos de capacitación referentes a tópicos de la industria alimentaria en general."},
                      {perfil:"Analizar y aplicar los principales conceptos y herramientas de la administración y economía, que permitan la distribución y utilización óptima de recursos."},
                      {perfil:"Desarrollar habilidades de liderazgo y comunicación oral y escrita para el desempeño profesional."},
                      {perfil:"Desempeñarse profesionalmente, con un espíritu emprendedor y compromiso ético, consigo mismo, colaboradores, sociedad y su entorno."},
                      {perfil:"Organizar y realizar trabajo inter y multidisciplinario en situaciones cambiantes y ambientes multiculturales."},
                      {perfil:"Dominar un segundo idioma, que le permita integrar y reforzar sus competencias profesionales."},
      ],
      reticula: 'http://tecmm.edu.mx/reticulasCarreras/Reticula-Ingenieria-en-Industrias-Alimentarias.pdf',
      planEstudios: 'http://tecmm.edu.mx/planesEstudioCarreras/INGENIERA_EN_INDUSTRIAS_ALIMENTARIAS_IIAL-2010-219.pdf',
      folleto: 'http://tecmm.edu.mx/folletosCarreras/newFolletos/Folleto-Ingenieria-en-Industrias-Alimentarias.pdf',
      convocatoria: "",
    };

    carrerasArray[14]={
      name: 'Ingenieria-Informatica',
      card:'https://tecmm.edu.mx/web-tsj-designs/ofertaAcademica/Carreras-18.jpg',
      portada: 'https://tecmm.edu.mx/web-tsj-designs/portadasCarreras/Carreras-Banners-22.jpg',
      youtube:'a4aMltEoY-o',
      videoInstitucional: "https://www.tecmm.edu.mx/videos/carreras/33_informatica.mp4",
      campus: 'EL GRULLO, TEPATITLÁN, TEQUILA, ZAPOTLANEJO',
      campusNoAccent: 'EL GRULLO, TEPATITLAN, TEQUILA, ZAPOTLANEJO',
      objetivo: "Formar profesionales competentes en el diseño, desarrollo, implementación y administración de servicios informáticos y gestión de proyectos con una visión sistémica, tecnológica y estratégica, ofreciendo soluciones innovadoras e integrales a las organizaciones, de acuerdo con las necesidades globales, actuales y emergentes, comprometidos con su entorno, desempeñándose con actitud ética, emprendedora y de liderazgo.",
      perfilIngreso : [
                      {perfil:"El aspirante a titularse como Ingeniero Informático debe contar con la capacidad de plantear problemas y su resolución, basando ésta en el razonamiento lógico y matemático. También debe contar con las aptitudes de adaptación y curiosidad por las situaciones en constante cambio, además del interés por la tecnología y sus avances."},
                      {perfil:"Interés general por el manejo y uso de la computadora, dispositivos móviles y software básico."},
                      {perfil:"Conocer los fundamentos de las ciencias físico-matemáticas en nivel medio superior."},
                      {perfil:"Capacidad de abstracción, análisis, síntesis y razonamiento lógico que le permita la aplicación de conocimientos relacionados con sistemas de información."},
                      {perfil:"Tener curiosidad, imaginación, creatividad e innovación."},
                      {perfil:"Disponer de sentido de la organización y práctico."},
      ],
      perfilEgreso : [
                      {perfil:"Aplicar conocimientos científicos y tecnológicos en la solución de problemas en el área informática con un enfoque interdisciplinario."},
                      {perfil:"Administrar las tecnologías de la información, para estructurar proyectos estratégicos."},
                      {perfil:"Formular, gestionar y evaluar el desarrollo de proyectos informáticos en las organizaciones."},
                      {perfil:"Analizar, modelar, desarrollar, implementar y administrar sistemas de información para aumentar la productividad y competitividad de las organizaciones."},
                      {perfil:"Aplicar normas, marcos de referencia, estándares de calidad y seguridad vigentes en el ámbito del desarrollo y gestión de tecnologías y sistemas de información."},
                      {perfil:"Integrar las soluciones de tecnologías de información a los procesos organizacionales para fortalecer objetivos estratégicos."},
                      {perfil:"Seleccionar y utilizar de manera óptima técnicas y herramientas computacionales actuales y emergentes."},
                      {perfil:"Realizar actividades de auditoría y consultoría relacionadas con la función informática."},
                      {perfil:"Identificar, diseñar, desarrollar los mecanismos de almacenamiento, distribución, visualización y manipulación de la información."},
                      {perfil:"Identificar y aplicar modelos pertinentes en el diseño e implementación de base de datos para la gestión de la información en las organizaciones."},
                      {perfil:"Comunicarse de manera efectiva, en su propio idioma y al menos en un idioma extranjero, para integrarse a un contexto globalizado, en su desarrollo personal y profesional."},
                      {perfil:"Crear y administrar redes de comunicación, que contemplen el diseño, selección, instalación y mantenimiento para la operación de equipos de cómputo, aprovechando los avances tecnológicos a su alcance."},
                      {perfil:"Desempeñarse profesionalmente con ética en un contexto multicultural, comprometidos con la sociedad y conservación del medioambiente."},
                      {perfil:"Observar y fomentar el cumplimiento de las disposiciones de carácter legal, relacionadas con la función informática."},
                      {perfil:"Analizar, desarrollar y programar modelos matemáticos, estadísticos y de simulación."},
                      {perfil:"Liderar y participar en grupos de trabajo profesional multi e interdisciplinario, para el desarrollo de proyectos que requieran soluciones basadas en tecnologías y sistemas de información."},
                      {perfil:"Lograr un nivel de competencia internacional, con espíritu innovador, creativo y emprendedor, para generar nuevas oportunidades y desarrollar proyectos aplicando las tecnologías de información."},
      ],
      reticula: 'http://tecmm.edu.mx/reticulasCarreras/Reticula-Ingenieria-Informatica.pdf',
      planEstudios: 'http://tecmm.edu.mx/planesEstudioCarreras/INGENIERA_INFORMTICA_IINF-2010-220.pdf',
      folleto: 'http://tecmm.edu.mx/folletosCarreras/Folleto-Ingenieria-Informatica.pdf',
      convocatoria: "",
    };
    // no hay link
    carrerasArray[15]={
      name: 'Ingenieria-Mecatronica',
      card:'https://tecmm.edu.mx/web-tsj-designs/ofertaAcademica/Carreras-21.jpg',
      portada: 'https://tecmm.edu.mx/web-tsj-designs/portadasCarreras/Carreras-Banners-08.jpg',
      youtube:'ATPLCRld3FA',
      videoInstitucional: "https://www.tecmm.edu.mx/videos/carreras/34_mecatronica.mp4",
      campus: 'CHAPALA, LAGOS DE MORENO, ZAPOTLANEJO',
      campusNoAccent: 'CHAPALA, LAGOS DE MORENO, ZAPOTLANEJO',
      objetivo: "Formar profesionistas en la ingeniería mecatrónica con capacidad analítica, crítica y creativa que le permita diseñar, proyectar, construir, innovar y administrar equipos y sistemas mecatrónicos en el sector social y productivo; así como integrar, operar y mantenerlos, con un compromiso ético y de calidad en un marco de desarrollo sustentable.",
      perfilIngreso : [
                      {perfil:"Conocimientos en Informática, Ciencias básicas y exactas ."},
                      {perfil:"Temas de actualidad en ciencia y tecnología."},
                      {perfil:"Inglés básico."},
                      {perfil:"Razonamiento lógico matemático."},
                      {perfil:"Uso de tecnología y herramientas."},
                      {perfil:"Resolución de problemas desde los principios del método científico."},
                      {perfil:"Destreza manual para resolver problemas."},
                      {perfil:"Capacidad creativa."},
                      {perfil:"Organización de materiales."},
      ],
      perfilEgreso : [
                      {perfil:"Ejercer su profesión, dentro de un marco legal, teniendo un sentido de responsabilidad social, con apego a las normas nacionales e internacionales."},
                      {perfil:"Analizar, sintetizar, diseñar, simular, construir e innovar productos, procesos, equipos y sistemas mecatrónicos, con una actitud investigadora, de acuerdo a las necesidades tecnológicas y sociales actuales y emergentes, impactando positivamente en el entorno global."},
                      {perfil:"Integrar, instalar, construir, optimizar, operar, controlar, mantener, administrar y/o automatizar sistemas mecánicos utilizando tecnologías eléctricas, electrónicas y herramientas computacionales."},
                      {perfil:"Evaluar y generar proyectos industriales y de carácter social."},
                      {perfil:"Coordinar y dirigir grupos multidisciplinarios fomentando el trabajo en equipo para la implementación de proyectos mecatrónicos, asegurando su calidad, eficiencia, productividad y rentabilidad con sentido de responsabilidad de su entorno social y cultural para un desarrollo sustentable."},
                      {perfil:"Desarrollar capacidades de liderazgo, comunicación e interrelaciones personales para transmitir ideas, facilitar conocimientos, trabajar en equipos multidisciplinarios y multiculturales con responsabilidad colectiva para la solución de problemas y desarrollo de proyectos con un sentido crítico y autocrítico."},
                      {perfil:"Ser creativo, emprendedor y comprometido con su actualización profesional continua y autónoma, para estar a la vanguardia en los cambios científicos y tecnológicos que se dan en el ejercicio de su profesión."},
                      {perfil:"Interpretar información técnica de las áreas que componen la Ingeniería Mecatrónica para la transferencia, adaptación, asimilación e innovación de tecnologías de vanguardia."},
                      {perfilf:"Diseñar, configurar y administrar redes computacionales aplicando las normas y estándares vigentes."}

      ],
      reticula: 'http://tecmm.edu.mx/reticulasCarreras/Reticula-Ingenieria-Mecatronica.pdf',
      planEstudios: 'http://tecmm.edu.mx/planesEstudioCarreras/INGENIERA_MECATRNICA_IMCT-2010-229.pdf',
      folleto: 'http://tecmm.edu.mx/folletosCarreras/Folleto-Ingenieria-Mecatronica.pdf',
      convocatoria: "",
    };

    carrerasArray[16]={
      name: 'Ingenieria-en-Sistemas-Computacionales',
      card:'https://tecmm.edu.mx/web-tsj-designs/ofertaAcademica/Carreras-20.jpg',
      portada: 'https://tecmm.edu.mx/web-tsj-designs/portadasCarreras/Carreras-Banners-15.jpg',
      youtube:'ATPLCRld3FA',
      videoInstitucional: "https://www.tecmm.edu.mx/videos/carreras/30_sist_computacionales.mp4",
      campus: 'ARANDAS, CHAPALA, COCULA, LA HUERTA, LAGOS DE MORENO, PUERTO VALLARTA, TOMATLÁN, ZAPOPAN',
      campusNoAccent: 'ARANDAS, CHAPALA, COCULA, LA HUERTA, LAGOS DE MORENO, PUERTO VALLARTA, TOMATLAN, ZAPOPAN',
      objetivo: "Formar profesionistas líderes, analíticos, críticos y creativos, con visión estratégica y amplio sentido ético, capaces de diseñar, implementar y administrar infraestructura computacional para aportar soluciones innovadoras en beneficio de la sociedad, en un contexto global, multidisciplinario y sustentable.",
      perfilIngreso : [
                      {perfil:"Capacidad para analizar y sintetizar conceptos."},
                      {perfil:"Capacidad para analizar sistemas empresariales y organizacionales."},
                      {perfil:"Capacidad de interpretar las indicaciones de manuales, instructivos y diagramas."},
                      {perfil:"Capacidad de retentiva para elaborar juicios a nivel técnico-deductivos y diagramas."},
                      {perfil:"Sentido común, intuición y creatividad."},
                      {perfil:"Capacidad de razonamiento matemático."},
                      {perfil:"Amplio sentido de organización."},
      ],
      perfilEgreso : [
                      {perfil:"Diseñar, configurar y administrar redes computacionales aplicando las normas y estándares vigentes."},
                      {perfil:"Desarrollar, implementar y administrar software de sistemas o de aplicación que cumpla con los estándares de calidad con el fin de apoyar la productividad y competitividad de las organizaciones."},
                      {perfil:"Coordinar y participar en proyectos interdisciplinarios."},
                      {perfil:"Diseñar e implementar interfaces hombre-máquina y máquina-máquina para la automatización de sistemas."},
                      {perfil:"Identificar y comprender las tecnologías de hardware para proponer, desarrollar y mantener aplicaciones eficientes."},
                      {perfil:"Diseñar, desarrollar y administrar bases de datos conforme a requerimientos definidos, normas organizacionales de manejo y seguridad de la información, utilizando tecnologías emergentes."},
                      {perfil:"Integrar soluciones computacionales con diferentes tecnologías, plataformas o dispositivos."},
                      {perfil:"Desarrollar una visión empresarial para detectar áreas de oportunidad que le permitan emprender y desarrollar proyectos aplicando las tecnologías de la información y comunicación."},
                      {perfil:"Desempeñar sus actividades profesionales considerando los aspectos legales, éticos, sociales y de desarrollo sustentable."},
                      {perfil:"Poseer habilidades metodológicas de investigación que fortalezcan el desarrollo cultural, científico y tecnológico en el ámbito de sistemas computacionales y disciplinas afines."},
                      {perfil:"Seleccionar y aplicar herramientas matemáticas para el modelado, diseño y desarrollo de tecnología computacional."},
      ],
      reticula: 'http://tecmm.edu.mx/reticulasCarreras/Reticula-Ingenieria-en-Sistemas-Computacionales.pdf',
      planEstudios: 'http://tecmm.edu.mx/planesEstudioCarreras/INGENIERA_EN_SISTEMAS_COMPUTACIONALES_ISIC-2010-224.pdf',
      folleto: 'http://tecmm.edu.mx/folletosCarreras/newFolletos/Folleto-Ingenieria-en-Sistemas-Computacionales.pdf',
      convocatoria: "",
    };

    carrerasArray[17]={
      name: 'Ingenieria-en-Sistemas-Automotrices',
      card:'https://tecmm.edu.mx/web-tsj-designs/ofertaAcademica/Carreras-19.jpg',
      portada: 'https://tecmm.edu.mx/web-tsj-designs/portadasCarreras/Carreras-Banners-23.jpg',
      youtube:'KkH60AN6dg8',
      videoInstitucional: "https://www.tecmm.edu.mx/videos/carreras/29_sist_automotrices.mp4",
      campus: 'LAGOS DE MORENO',
      campusNoAccent: 'LAGOS DE MORENO',
      objetivo: "Formar ingenieros que se desempeñen en el diseño, planeación, desarrollo y automatización de sistemas automotrices, dentro del marco legal y sustentable, mediante competencias científicas, tecnológicas y administrativas, con el fin de atender las necesidades del sector automotriz, con una actitud ética, de liderazgo y responsabilidad social.",
      perfilIngreso : [
                      {perfil:"Conocimientos En el área de ciencias exactas del nivel medio superior, elementos básicos de redacción para la elaboración de textos, elementos básicos de computación."},
                      {perfil:"Estar acreditado en un nivel A2 del idioma inglés de acuerdo al Marco Común Europeo de Referencia para las Lenguas."},
                      {perfil:"Habilidades para el autoaprendizaje, la comprensión de un idioma extranjero, el manejo pacífico de conflictos, la utilización de las tecnologías de información, la capacidad de análisis y síntesis de textos científicos, el trabajo individual y en equipo."},
                      {perfil:"Interés por el conocimiento"},
                      {perfil:"Apertura al cambio y a los nuevos conocimientos"},
                      {perfil:"Búsqueda permanente de la superación y mejora personal a partir de la identificación de sus propias fortalezas y debilidades"},
                      {perfil:"Apertura al diálogo"},
                      {perfil:"Comprensión y tolerancia hacia la diversidad étnica, de clase, género, religión, preferencias políticas y/o sexuales,"},
                      {perfil:"Respeto y aprecio por la diversidad biológica y su integración ecológica"},
                      {perfil:"Participación en las transformaciones de su contexto"},
                      {perfil:"Aprecio por la diversidad cultural del planeta y nuestro país"},
                      {perfil:"Pensamiento proactivo y asertivo"},
                      {perfil:"Pensamiento crítico e innovador"},
                      {perfil:"Disposición hacia el autoaprendizaje"},
                      {perfil:"Mostrar una actitud solidaria, de respeto, con compromiso humanista"},
                      {perfil:"Mostrar una actitud ética, profesional con responsabilidad social"},
      ],
      perfilEgreso : [
                      {perfil:"Analiza y resuelve problemas de las diferentes disciplinas de ingeniería relacionadas con los sistemas automotrices, mediante el desarrollo e implementación de las nuevas tecnologías enfocadas a las necesidades del sector automotriz, de forma responsable y cooperativa."},
                      {perfil:"Fomenta el desarrollo sustentable para contribuir al equilibrio ambiental."},
                      {perfil:"Aplica conocimientos y habilidades generales de ingeniería en las áreas de diseño, manufactura, producción, calidad y conservación de la infraestructura, para fomentar la competitividad del sector automotriz."},
                      {perfil:"Desarrolla sistemas automotrices, aplicando los procesos de manufactura desde la planeación y diseño de instalaciones hasta las operaciones."},
                      {perfil:"Identifica, diagnostica y mide las áreas de oportunidad en los sistemas automotrices, para proponer alternativas de mejora utilizando técnicas y controles estadísticos mediante el trabajo en equipo."},
                      {perfil:"Utilizar normas nacionales e internacionales pertinentes, para asegurar la calidad, productividad, seguridad y sustentabilidad del sector automotriz."},
                      {perfil:"Aplica herramientas computacionales de acuerdo a las tecnologías de vanguardia, para el diseño, simulación, operación y optimización de sistemas automotrices acordes a la demanda del sector industrial."},
                      {perfil:"Diseña e integra sistemas de redes industriales para el control, comunicación y automatización de las líneas de producción en la industria automotriz."},
                      {perfil:"Propone alternativas de mejora continua en los sistemas de producción para optimizar los recursos materiales, humanos y financieros."},
                      {perfil:"Aplica la capacidad de dirección, liderazgo y comunicación de relaciones interpersonales, para transmitir ideas, facilitar conocimientos y trabajo en equipo con responsabilidad colectiva para la solución de problemas y desarrollo de proyectos en ingeniería en sistemas automotrices."},
      ],
      reticula: 'http://tecmm.edu.mx/reticulasCarreras/Reticula-Ingenieria-en-Sistemas-Automotrices.pdf',
      planEstudios: 'http://tecmm.edu.mx/planesEstudioCarreras/INGENIERIA_EN_SISTEMAS_AUTOMOTRICES_ISAU-2013-240.pdf',
      folleto: 'http://tecmm.edu.mx/folletosCarreras/newFolletos/Folleto-Ingenieria-en-Sistemas-Automotrices.pdf',
      convocatoria: "",
    };

    carrerasArray[19]={
      name: 'Licenciatura-en-Turismo',
      card:'https://tecmm.edu.mx/web-tsj-designs/ofertaAcademica/Carreras-23.jpg',
      portada: 'https://tecmm.edu.mx/web-tsj-designs/portadasCarreras/Carreras-Banners-16.jpg',
      youtube:'h6n0vM-3bdI',
      videoInstitucional: "https://www.tecmm.edu.mx/videos/carreras/36_turismo.mp4",
      campus: 'CHAPALA, PUERTO VALLARTA, TEQUILA',
      campusNoAccent: 'CHAPALA, PUERTO VALLARTA, TEQUILA, SAN JUAN DE LOS LAGOS',
      objetivo: "Formar profesionistas capaces de emprender, gestionar e innovar proyectos turísticos sustentables con calidad, acordes con las necesidades de su entorno y las tendencias globales, con sentido ético y respeto a la integridad del patrimonio turístico.",
      perfilIngreso : [
                      {perfil:"Capacidad de abstracción, análisis y síntesis."},
                      {perfil:"Responsabilidad social y compromiso ciudadano"},
                      {perfil:"Capacidad de comunicarse en un segundo idioma "},
                      {perfil:"Capacidad crítica y autocrítica "},
                      {perfil:"Capacidad creativa "},
                      {perfil:"Capacidad para identificar, plantear y resolver problemas"},
                      {perfil:"Compromiso con la preservación del medio ambiente"},
                      {perfil:"Compromiso con su medio socio-cultural "},
                      {perfil:"Valoración y respeto por la diversidad y multiculturalidad "},
                      {perfil:"Respecto y conservación del patrimonio cultural y natural "},
                      {perfil:"Interés en el ámbito turístico"},
      ],
      perfilEgreso : [
                      {perfil:"Promueve el desarrollo sustentable en el turismo fomentando el respeto a la cultura y la naturaleza con un compromiso ético."},
                      {perfil:"Elabora y evalúa planes, programas y proyectos turísticos integrando a las comunidades para lograr un desarrollo sustentable."},
                      {perfil:"Reconoce el fenómeno turístico identificando los indicadores de orden social, para coadyuvar al aumento de la calidad de la oferta turística."},
                      {perfil:"Preserva la cultura nacional e internacional respetando la identidad de cada región."},
                      {perfil:"Emprende un plan de negocios para la creación de su propia empresa fundamentada en el turismo sustentable."},
                      {perfil:"Reconoce y valora la riqueza ecológica de la región para la prestación de servicios turísticos de naturaleza sustentables de forma segura."},
                      {perfil:"Administra recursos humanos, materiales, técnicos y financieros de empresas turísticas."},
                      {perfil:"Aplica el marco legal, para garantizar el funcionamiento del sistema turístico fortaleciendo la seguridad en su entorno."},
                      {perfil:"Aplica el uso de las tecnologías de la información y comunicación (TIC ́S) para lograr la eficiencia en el desarrollo del sistema turístico."},
                      {perfil:"Valora las nuevas tendencias para el desarrollo del sector turístico a nivel global."},
      ],
      reticula: 'https://tecmm.edu.mx/reticulasCarreras/Reticula-Licenciatura-en-Turismo.pdf',
      planEstudios: 'https://tecmm.edu.mx/planesEstudioCarreras/LICENCIATURA_EN_TURISMO_LTUR-2012-237.pdf',
      folleto: 'https://tecmm.edu.mx/folletosCarreras/newFolletos/Folleto-Licenciatura-en-Turismo.pdf',
      convocatoria: "",
    };

    carrerasArray[20]={
      name: 'Licenciatura-en-Administracion',
      card:'https://tecmm.edu.mx/web-tsj-designs/ofertaAcademica/Carreras-24.jpg',
      portada: 'https://tecmm.edu.mx/web-tsj-designs/portadasCarreras/Carreras-Banners-21.jpg',
      youtube:'Hgv_R9b-TGs',
      videoInstitucional: "https://www.tecmm.edu.mx/videos/carreras/35_lic_administracion.mp4",
      campus: 'LA HUERTA, TEQUILA',
      campusNoAccent: 'LA HUERTA, TEQUILA',
      objetivo: "Formar profesionales de la administración capaces de actuar como agentes de cambio, a través del diseño, innovación y dirección en organizaciones, sensibles a las demandas sociales y oportunidades del entorno, con capacidad de intervención en ámbitos globales y con un firme propósito de observar las normas y los valores universales.",
      perfilIngreso : [
                      {perfil:"Pensamiento complejo, orientado a dar soluciones"},
                      {perfil:"Sinergia, capacidad de realizar trabajo en equipo"},
                      {perfil:"Inteligencia emocional, razonar objetivamente bajo presión"},
                      {perfil:"Liderazgo, él como factor de cohesión"},
                      {perfil:"Responsabilidad social, poseer un marco de referencia ética evidente"},
                      {perfil:"Habilidad en las ciencias económico-administrativas."},
                      {perfil:"Comunicación oral y escrita, evidentes"},
      ],
      perfilEgreso : [
                      {perfil:"Integrar los procesos gerenciales, de administración, de innovación y las estrategias de dirección para la competitividad y productividad de las organizaciones."},
                      {perfil:"Aplicar los conocimientos modernos de la gestión de negocios a las fases del proceso administrativo para la optimización de recursos y el manejo de los cambios organizacionales."},
                      {perfil:"Desarrollar las habilidades directivas y de vinculación basadas en la ética y la responsabilidad social, que le permitan integrar y coordinar equipos interdisciplinarios, para favorecer el crecimiento de la organización y su entorno global."},
                      {perfil:"Crear y desarrollar proyectos sustentables aplicando herramientas administrativas y métodos de investigación de vanguardia, con un enfoque estratégico, multicultural y humanista."},
                      {perfil:"Conducir la organización hacia la consecución de sus objetivos mediante un esfuerzo coordinado y espíritu emprendedor."},
                      {perfil:"Crear organizaciones que contribuyan a la transformación económica y social, identificando las oportunidades de negocios en un contexto global."},
                      {perfil:"Conocer y aplicar el marco legal vigente nacional e internacional de las organizaciones."},
                      {perfil:"Analizar e interpretar información financiera y económica para la toma de decisiones en las organizaciones."},
                      {perfil:"Ser un agente de cambio con la habilidad de potenciar el capital humano para la solución de los problemas y la toma de decisiones que las organizaciones afrontan."},
                      {perfil:"Implementar y administrar sistemas de gestión de calidad para orientarlos a la mejora continua, con la finalidad de lograr la productividad de la organización, desarrollando una cultura de calidad total."},
                      {perfil:"Aplicar las tecnologías de la información y comunicación para optimizar el trabajo y desarrollo de la organización."},
                      {perfil:"Actualizar conocimientos permanentemente para responder a los cambios globales."},
                      {perfil:"Diseñar sistemas de organización considerando alternativas estratégicas que generen cadenas productivas en beneficio de la sociedad."},
                      {perfil:"Tener visión multidisciplinaria para generar propuestas y desarrollar acciones de manera inmediata ante escenarios de contingencia."},

      ],
      reticula: 'http://tecmm.edu.mx/reticulasCarreras/Reticula-Licenciatura-en-Administracion.pdf',
      planEstudios: 'http://tecmm.edu.mx/planesEstudioCarreras/LICENCIATURA_EN_ADMINISTRACIN_LADM-2010-234.pdf',
      folleto: 'http://tecmm.edu.mx/folletosCarreras/newFolletos/Folleto-Licenciatura-en-Administracion.pdf',
      convocatoria: "",
    };

    carrerasArray[21]={
      name: 'Ingenieria-en-Gestion-Empresarial-en-Linea',
      card:"https://tecmm.edu.mx/web-tsj-designs/ofertaAcademica/Carreras-01.jpg",
      portada: 'http://tecmm.edu.mx/imagesReact/images/ofertaAcademicaNueva/Ing-Gestion-Empresarial-Linea.jpg',
      youtube:'PoT9UkO8TcI',
      videoInstitucional: "J6izdocayPA",
      campus: 'Arandas, Chapala, Cocula, El Grullo, La Huerta, Lagos de Moreno, Mascota, Tala, Tamazula, Tequila, Puerto Vallarta, Zapopan, Zapotlanejo',
      campusNoAccent: 'Arandas, Chapala, Cocula, El Grullo, La Huerta, Lagos de Moreno, Mascota, Tala, Tamazula, Tequila, Puerto Vallarta, Zapopan, Zapotlanejo',
      objetivo: 'Formar íntegramente profesionales que contribuyan a lagestión de empresas e innovación de procesos; así como al diseño, implementación y desarrollo de sistemas estratégicos de negocios, optimizando recursos en un entorno global, con ética y responsabilidad social.',
      perfilIngreso : [
                      {perfil:"Capacidad de observación, análisis y síntesis."},
                      {perfil:"Habilidad verbal, facilidad de expresión, sentido de organización."},
                      {perfil:"Habilidad para comprender problemas sociales."},
                      {perfil:"Objetividad y don de mando."},
                      {perfil:"Habilidad de persuasión."},
                      {perfil:"Habilidad numérica."},
                      {perfil:"Habilidad para relacionarse con las personas, sociabilidad."},
      ],
      perfilEgreso: [
                      {perfil:"Desarrollar y aplicar habilidades directivas y la ingeniería en el diseño, creación, gestión, desarrollo, fortalecimiento e innovación de las organizaciones, con una orientación sistémica y sustentable para la toma de decisiones en forma efectiva."},
                      {perfil:"Diseñar e innovar estructuras administrativas y procesos, con base en las necesidades de las organizaciones para competir eficientemente en mercados globales."},
                      {perfil:"Gestionar eficientemente los recursos de la organización con visión compartida, con el fin de suministrar bienes y servicios de calida.d"},
                      {perfil:"Aplicar métodos cuantitativos y cualitativos para el análisis e interpretación de datos y modelado de sistemas, en los procesos organizacionales para la mejora continua, atendiendo estándares de calidad nunciial."},
                      {perfil:"Diseñar, evaluar y emprender nuevos negocios y proyectos empresariales, que promuevan el desarrollo sustentable y la responsabilidad social, en un mercado competitivo "},
                      {perfil:"Diseñar e implementar estrategias de mercadotecnia basadas en información recopilada de fuentes primarias y secundarias del consumidor o usuario de algún producto, de acuerdo a oportunidades y amenazas del mercado."},
                      {perfil:"Establecer prograrnas para el fortalecimiento de la seguridad e higiene en las organizaciones."},
                      {perfil:"Gestionar sistemas integrales de calidad, ejerciendo un liderazgo efectivo y un compromiso ético, aplicando las herramientas básicas de la ingeniería."},
                      {perfil:"Interpretar y aplicar normas legales que incidan en la creación y desarrollo de las organizaciones."},
                      {perfil:"Integrar, dirigir y desarrollar equipos de trabajo para la mejora continua y el crecimiento integral de las organizaciones."},
                      {perfil:"Analizar e interpretar la información financiera para detectar oportunidades de mejora e inversión en un mundo global, que incidan en la rentabilidad del negocio."},
                      {perfil:"Utilizar las nuevas tecnologías de información en la organización, para optimizar los procesos de comunicación y eficientar la toma de decisiones. "},
                      {perfil:"Propiciar el desarrollo del capital humano, para la realización de los objetivos organizacionales, dentro de un marco ético y un contexto multicultural."},
                      {perfil:"Aplicar métodos de investigación para desarrollar e innovar sisternas, procesos y productos en las diferentes dimensiones de la organización."},
                      {perfil:"Analizar e interpretar la economía global para facilitar la toma de decisiones en la organización."},
      ],
      reticula: 'http://tecmm.edu.mx/reticulasCarreras/Reticula-Ingenieria-en-Gestion-Empresarial.pdf',
      planEstudios: 'http://tecmm.edu.mx/planesEstudioCarreras/INGENIERA__EN_GESTIN_EMPRESARIAL_IGEM-2009-201.pdf',
      folleto:'http://tecmm.edu.mx/folletosCarreras/newFolletos/Folleto-Ingenieria-en-Gestion-Empresarial.pdf',
      convocatoria: "",
    };

    carrerasArray[22]={
      name: 'Maestria-en-Administracion',
      card:'https://tecmm.edu.mx/web-tsj-designs/ofertaAcademica/Carreras-03.jpg',
      portada: 'https://tecmm.edu.mx/web-tsj-designs/portadasCarreras/Carreras-Banners-17.jpg',
      youtube:'U7SZz2s2qLY',
      videoInstitucional: "p2zos8KuCxY",
      campus: 'PUERTO VALLARTA',
      campusNoAccent: 'PUERTO VALLARTA',
      objetivo: "La Maestría en Administración, tiene como objetivo formar recursos humanos con una alta capacidad creativa e innovadora, con la destreza para el manejo de conocimientos, técnicas y habilidades que les permitan hacer frente a la problemática empresarial de forma racional, eficaz y eficientemente, aplicando los recursos que les permitan incrementar la innovación, la productividad y la tecnología, generando soluciones que mejoran la competitividad en las organizaciones y generar cambios en el entorno social, industrial y económico de Puerto Vallarta y del país.",

      perfilEgreso : [
                      {perfil:"Al término de sus estudios, el maestrante habrá fortalecido sus conocimientos, actitudes y habilidades directivas, que contribuyan al desarrollo económico y social de su ámbito de influencia, capaz de promover oportunidades de negocios, diseñar y operar nuevos modelos administrativos, establecer estrategias para impulsar la competitividad de las organizaciones y fortalecerlas de forma creativa e innovadora."},
                      {perfil:"El egresado de la maestría en administración será capaz de:"},
                      {perfil:"Analizar, crear y desarrollar proyectos, diseñar estrategias y prepararse para la toma de decisiones en las organizaciones."},
                      {perfil:"Ser directivos que tomen decisiones y/o que resuelvan problemas relacionados con los aspectos de la alta dirección y los negocios en un ámbito nacional e internacional."},
                      {perfil:"Generar nuevos conocimientos y soluciones para elevar la competitividad institucional a través de la innovación, productividad y desarrollo tecnológico desde una perspectiva nacional e internacional."},
                      {perfil:"Desarrollar nuevas alternativas de crecimiento y permanencia en el sector empresarial económicamente productivo desde una perspectiva de alta dirección."},
                      {perfil:"Crear y desarrollar planes de acción estratégicos para fortalecer la actividad económica- institucional en un micro y macro entorno."},
      ],
      reticula: null,
      folleto: 'http://tecmm.edu.mx/folletosCarreras/Folleto-Maestria-Administracion.pdf',
      convocatoria: "http://tecmm.edu.mx/recursos/convocatoriaMaestrias/CONVOCATORIA-MTRIA-EN-ADMINISTRACION.pdf",

    };

    carrerasArray[23]={
      name: 'Maestria-en-Sistemas-Computacionales',
      card:'https://tecmm.edu.mx/web-tsj-designs/ofertaAcademica/Carreras-02.jpg',
      portada: 'https://tecmm.edu.mx/web-tsj-designs/portadasCarreras/Carreras-Banners-01.jpg',
      youtube:'rdWPqiy3RRc',
      videoInstitucional: "https://www.tecmm.edu.mx/videos/carreras/30_sist_computacionales.mp4",
      campus: 'ZAPOPAN',
      campusNoAccent: 'ZAPOPAN',
      objetivo: "Formar líderes de excelencia con sólidos conocimientos en sistemas computacionales de alto nivel competitivo que analicen, diseñen y desarrollen software, en las áreas de multimedia y cómputo ubicuo.",
      perfilEgreso : [
                      {perfil:"El egresado de la maestría será un profesionista competente capaz de proponer, diseñar, implementar y desarrollar software de cómputo ubicuo o multimedia, teniendo la habilidad para participar en equipos multidisciplinarios y en la identificación de problemas o áreas de oportunidad, aportando propuestas de solución aplicando las metodologías y tecnologías de información emergentes que cumplan con estándares internacionales. Tendrá la habilidad para mejorar la toma de decisiones, que contribuya a resolver eficazmente los problemas de las organizaciones privadas y públicas, en el uso de tecnologías de vanguardia."},
                      {perfil:"Analizar, desarrollar y programar modelos matemáticos, estadísticos y de simulación."},
                      {perfil:"Reconocer y guiarse por los aspectos sociales, profesionales y éticos en su entorno."},
                      {perfil:"Dirigir y coordinar equipos de trabajo multi e interdisciplinarios."},
                      {perfil:"Coordinar y realizar investigaciones que fortalezcan el desarrollo cultural, científico y tecnológico."},
                      {perfil:"Desarrollar y administrar sistemas de información, redes de computadoras y aplicaciones distribuidas."},
                      {perfil:"Poseer una visión empresarial y detectar áreas de oportunidad para emprender y desarrollar proyectos aplicando las tecnologías de información y de las comunicaciones."},
                      {perfil:"Seleccionar y administrar los recursos humanos y computacionales para unidades de servicios de cómputo."},
                      {perfil:"Estar comprometido con el desarrollo sustentable, respetando el entorno social y cultural donde se desarrollan las organizaciones."},
                      {perfil:"Desarrollar y administrar software de aplicación y de base."},
                      {perfil:"Aplicar nuevas tecnologías a la solución de problemas de su entorno laboral."},
      ],
      reticula: null,
      folleto: 'http://tecmm.edu.mx/folletosCarreras/Folleto-Maestria-Sistemas-Computacionales.pdf',
      convocatoria: "http://tecmm.edu.mx/recursos/convocatoriaMaestrias/CONVOCATORIA-MTRIA-EN-SISTEMAS.pdf",
    };

    carrerasArray[24]={
      name: 'Ingenieria-en-Gestion-Empresarial-en-Linea',
      card:'http://tecmm.edu.mx/imagesReact/images/ofertaAcademicaNueva/Cards-Carreras-Nuevos/Card-Ing-Gestion-Empresarial-Linea.jpg',
      portada: 'https://tecmm.edu.mx/web-tsj-designs/portadasCarreras/Carreras-Banners-02.jpg',
      youtube:'PoT9UkO8TcI',
      videoInstitucional: "J6izdocayPA",
      campus: 'ARANDAS, CHAPALA, COCULA, EL GRULLO, LA HUERTA, LAGOS DE MORENO, MASCOTA, TALA, TAMAZULA, TEQUILA, PUERTO VALLARTA, ZAPOPAN, ZAPOTLANEJO',
      campusNoAccent: 'ARANDAS, CHAPALA, COCULA, EL GRULLO, LA HUERTA, LAGOS DE MORENO, MASCOTA, TALA, TAMAZULA, TEQUILA, PUERTO VALLARTA, ZAPOPAN, ZAPOTLANEJO',
      objetivo: 'Formar íntegramente profesionales que contribuyan a la gestión de empresas e innovación de procesos; así como al diseño, implementación y desarrollo de sistemas estratégicos de negocios, optimizando recursos en un entorno global, con ética y responsabilidad social.',
      perfilEgreso: [
                      {perfil:"Desarrollar y aplicar habilidades directivas y la ingeniería en el diseño, creación, gestión, desarrollo, fortalecimiento e innovación de las organizaciones, con una orientación sistémica y sustentable para la toma de decisiones en forma efectiva."},
                      {perfil:"Diseñar e innovar estructuras administrativas y procesos, con base en las necesidades de las organizaciones para competir eficientemente en mercados globales."},
                      {perfil:"Gestionar eficientemente los recursos de la organización con visión compartida, con el fin de suministrar bienes y servicios de calidad."},
                      {perfil:"Aplicar métodos cuantitativos y cualitativos para el análisis e interpretación de datos y modelado de sistemas, en los procesos organizacionales para la mejora continua, atendiendo estándares de calidad mundial."},
                      {perfil:"Diseñar, evaluar y emprender nuevos negocios y proyectos empresariales, que promuevan el desarrollo sustentable y la responsabilidad social, en un mercado competitivo "},
                      {perfil:"Diseñar e implementar estrategias de mercadotecnia basadas en información recopilada de fuentes primarias y secundarias del consumidor o usuario de algún producto, de acuerdo a oportunidades y amenazas del mercado."},
                      {perfil:"Establecer programas para el fortalecimiento de la seguridad e higiene en las organizaciones."},
                      {perfil:"Gestionar sistemas integrales de calidad, ejerciendo un liderazgo efectivo y un compromiso ético, aplicando las herramientas básicas de la ingeniería."},
                      {perfil:"Interpretar y aplicar normas legales que incidan en la creación y desarrollo de las organizaciones."},
                      {perfil:"Integrar, dirigir y desarrollar equipos de trabajo para la mejora continua y el crecimiento integral de las organizaciones."},
                      {perfil:"Analizar e interpretar la información financiera para detectar oportunidades de mejora e inversión en un mundo global, que incidan en la rentabilidad del negocio."},
                      {perfil:"Utilizar las nuevas tecnologías de información en la organización, para optimizar los procesos de comunicación y eficientar la toma de decisiones. "},
                      {perfil:"Propiciar el desarrollo del capital humano, para la realización de los objetivos organizacionales, dentro de un marco ético y un contexto multicultural."},
                      {perfil:"Aplicar métodos de investigación para desarrollar e innovar sistemas, procesos y productos en las diferentes dimensiones de la organización."},
                      {perfil:"Analizar e interpretar la economía global para facilitar la toma de decisiones en la organización."},
      ],
      reticula: 'http://tecmm.edu.mx/reticulasCarreras/Reticula-Ingenieria-en-Gestion-Empresarial.pdf',
      folleto:'http://tecmm.edu.mx/reticulasCarreras/Folleto-Ingenieria-en-Gestion-Empresarial-enLinea.pdf',
      convocatoria: "",
    };

    carrerasArray[25]={
      name: 'prueba',
      card:"https://tecmm.edu.mx/web-tsj-designs/ofertaAcademica/Carreras-01.jpg",
      portada: 'http://tecmm.edu.mx/imagesReact/images/ofertaAcademicaNueva/Ing-Gestion-Empresarial-Linea.jpg',
      youtube:'PoT9UkO8TcI',
      videoInstitucional: "J6izdocayPA",
      campus: 'Arandas, Chapala, Cocula, El Grullo, La Huerta, Lagos de Moreno, Mascota, Tala, Tamazula, Tequila, Puerto Vallarta, Zapopan, Zapotlanejo',
      campusNoAccent: 'Arandas, Chapala, Cocula, El Grullo, La Huerta, Lagos de Moreno, Mascota, Tala, Tamazula, Tequila, Puerto Vallarta, Zapopan, Zapotlanejo',
      objetivo: 'Formar íntegramente profesionales que contribuyan a lagestión de empresas e innovación de procesos; así como al diseño, implementación y desarrollo de sistemas estratégicos de negocios, optimizando recursos en un entorno global, con ética y responsabilidad social.',
      perfilIngreso : [
                      {perfil:"Capacidad de observación, análisis y síntesis."},
                      {perfil:"Habilidad verbal, facilidad de expresión, sentido de organización."},
                      {perfil:"Habilidad para comprender problemas sociales."},
                      {perfil:"Objetividad y don de mando."},
                      {perfil:"Habilidad de persuasión."},
                      {perfil:"Habilidad numérica."},
                      {perfil:"Habilidad para relacionarse con las personas, sociabilidad."},
      ],
      perfilEgreso: [
                      {perfil:"Desarrollar y aplicar habilidades directivas y la ingeniería en el diseño, creación, gestión, desarrollo, fortalecimiento e innovación de las organizaciones, con una orientación sistémica y sustentable para la toma de decisiones en forma efectiva."},
                      {perfil:"Diseñar e innovar estructuras administrativas y procesos, con base en las necesidades de las organizaciones para competir eficientemente en mercados globales."},
                      {perfil:"Gestionar eficientemente los recursos de la organización con visión compartida, con el fin de suministrar bienes y servicios de calida.d"},
                      {perfil:"Aplicar métodos cuantitativos y cualitativos para el análisis e interpretación de datos y modelado de sistemas, en los procesos organizacionales para la mejora continua, atendiendo estándares de calidad nunciial."},
                      {perfil:"Diseñar, evaluar y emprender nuevos negocios y proyectos empresariales, que promuevan el desarrollo sustentable y la responsabilidad social, en un mercado competitivo "},
                      {perfil:"Diseñar e implementar estrategias de mercadotecnia basadas en información recopilada de fuentes primarias y secundarias del consumidor o usuario de algún producto, de acuerdo a oportunidades y amenazas del mercado."},
                      {perfil:"Establecer prograrnas para el fortalecimiento de la seguridad e higiene en las organizaciones."},
                      {perfil:"Gestionar sistemas integrales de calidad, ejerciendo un liderazgo efectivo y un compromiso ético, aplicando las herramientas básicas de la ingeniería."},
                      {perfil:"Interpretar y aplicar normas legales que incidan en la creación y desarrollo de las organizaciones."},
                      {perfil:"Integrar, dirigir y desarrollar equipos de trabajo para la mejora continua y el crecimiento integral de las organizaciones."},
                      {perfil:"Analizar e interpretar la información financiera para detectar oportunidades de mejora e inversión en un mundo global, que incidan en la rentabilidad del negocio."},
                      {perfil:"Utilizar las nuevas tecnologías de información en la organización, para optimizar los procesos de comunicación y eficientar la toma de decisiones. "},
                      {perfil:"Propiciar el desarrollo del capital humano, para la realización de los objetivos organizacionales, dentro de un marco ético y un contexto multicultural."},
                      {perfil:"Aplicar métodos de investigación para desarrollar e innovar sisternas, procesos y productos en las diferentes dimensiones de la organización."},
                      {perfil:"Analizar e interpretar la economía global para facilitar la toma de decisiones en la organización."},
      ],
      reticula: 'http://tecmm.edu.mx/reticulasCarreras/Reticula-Ingenieria-en-Gestion-Empresarial.pdf',
      planEstudios: 'http://tecmm.edu.mx/planesEstudioCarreras/INGENIERA__EN_GESTIN_EMPRESARIAL_IGEM-2009-201.pdf',
      folleto:'http://tecmm.edu.mx/folletosCarreras/newFolletos/Folleto-Ingenieria-en-Gestion-Empresarial.pdf',
      convocatoria: "",
    };

    return carrerasArray;

 }

  